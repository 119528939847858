import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AdminSettingsComponent } from '../sharedModule/admin-settings/admin-settings.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsGuard implements CanDeactivate<AdminSettingsComponent> {
  constructor(private translate: TranslateService) {}

  async canDeactivate(component: AdminSettingsComponent): Promise<boolean> {
    if (component.isFormChanged) {
      const result = await Swal.fire({
        title: this.translate.instant('new_entries.unsaved_changes'),
        text: this.translate.instant('new_entries.unsaved_changes_description'),
        icon: 'warning',
        iconColor: '#004080',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('new_entries.save_changes'),
        cancelButtonText: this.translate.instant('new_entries.discard_changes'),
        confirmButtonColor: '#004080',
        focusConfirm: true,
        allowOutsideClick: false,
        customClass: {
          cancelButton: 'btn btn-secondary',
          confirmButton: 'btn btn-primary btn-outline',
          icon: 'swal2-icon-show',
          container: 'swal2-top-most',
          popup: 'swal2-top-most'
        }
      });

      // If user clicks "Save Changes"
      if (result.isConfirmed) {
        const saved = await component.submitSettings(true); // Pass true to skip confirmation dialog
        return saved; // Only navigate if save was successful
      }
      // User clicked "Discard Changes"
      return true;
    }
    return true;
  }
}
