import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
// import hljs from 'highlight.js';
import { BehaviorSubject } from 'rxjs';
import { SubmissionsService } from 'src/app/services/submissions.service';
@Component({
  selector: 'app-code-preview',
  templateUrl: './code-preview.component.html',
  styleUrls: ['./code-preview.component.scss']
})
export class CodePreviewComponent implements OnChanges {
  @Input() content: string = '';
  @Input() language: string = '';

  displayContent: string = '';
  highlightedCode: string = '';
  lines: number[] = [];

  // TODO: Add highlight code after all the issues are resolved. A.R. 11/05/2024

  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']?.currentValue || changes['language']?.currentValue) {
      // this.highlightCode();
      this.generateLineNumbers();
    }
  }

  private generateLineNumbers() {
    const lineCount = this.content.split('\n').length;
    this.lines = Array.from({ length: lineCount }, (_, i) => i + 1);
  }

  // private highlightCode() {
  //   if (this.content) {
  //     this.highlightedCode = hljs.highlightAuto(this.content).value;
  //   }
  // }
}
