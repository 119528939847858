import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/authGuard';
// import { NavbarComponent } from './sharedModule/navbar/navbar.component';
import { NotFoundComponent } from './sharedModule/not-found/not-found.component';
import { NotAuthorizedComponent } from './sharedModule/notauthorized/not-authorized.component';
import { ReportComponent } from './sharedModule/report/report.component';
import { ReportComponent as ReportComponentv2 } from './sharedModule/report-v2/report.component';
import { AssignmentConfigurationComponent } from './assignment-configuration/assignment-configuration.component';
import { ApiDebugComponent } from './api-debug/api-debug.component';
const routes: Routes = [
   {
      path: '',
      loadChildren: () =>
         import('./authModule/authmodule.module').then(
            (m) => m.AuthmoduleModule
         ),
   },
   {
      path: 'api-debug',
      component: ApiDebugComponent
   },
   { path: 'notauthorized', component: NotAuthorizedComponent },
   {
      path: 'assignment-configuration/:userId/:assignmentId/:userData',
      component: AssignmentConfigurationComponent,
   },
   {
      path: 'individual',
      data: { roles: ['2'] },
      canActivate: [AuthGuard],
      loadChildren: () =>
         import('./individual-components/individual-components.module').then(
            (m) => m.IndividualComponentsModule
         ),
   },
   {
      path: 'submission/:id/report',
      component: ReportComponent,
   },
   {
      path: 'administrator',
      data: { roles: ['3', '11'] },
      canActivate: [AuthGuard],
      loadChildren: () =>
         import(
            './administrator-components/administrator-components.module'
         ).then((m) => m.AdministratorComponentsModule),
   },
   {
      path: 'professor',
      data: { roles: ['4', '10'] },
      canActivate: [AuthGuard],
      loadChildren: () =>
         import('./professor-components/professor-components.module').then(
            (m) => m.ProfessorComponentsModule
         ),
   },
   {
      path: 'student',
      data: { roles: ['5', '9'] },
      canActivate: [AuthGuard],
      loadChildren: () =>
         import('./student-components/student-components.module').then(
            (m) => m.StudentComponentsModule
         ),
   },
   {
      path: 'vleresues',
      data: { roles: ['6'] },
      canActivate: [AuthGuard],
      loadChildren: () =>
         import(
            './individual-administrator-components/individual-administrator-components.module'
         ).then((m) => m.IndividualAdministratorComponentsModule),
   },
   {
      path: 'super-admin',
      data: { roles: ['7'] },
      canActivate: [AuthGuard],
      loadChildren: () =>
         import('./super-admin/super-admin.module').then(
            (m) => m.SuperAdminComponentsModule
         ),
   },
   {
    path: 'submission/:id/report/v2',
    component: ReportComponentv2,
 },

   { path: 'notFound', component: NotFoundComponent },
   { path: '**', redirectTo: 'notFound' },
];
@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
