// *** Angular
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

// *** Packages
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

// *** Actions
import * as professorActions from '../state/professor/action/professor.actions';

// *** Selectors
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { getInstitutionProfessorsStateDetails } from '../state/professor/selector/professor.selectors';

// *** Services
import { CourseService } from 'src/app/services/course.service';
import { DepartmentService } from 'src/app/services/department.service';
import { FacultyService } from 'src/app/services/faculty.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from 'src/app/services/excel.service';
import { UploadfileService } from 'src/app/individual-components/individual-uploads/upload-file/Service/uploadfile.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';
import { BulkRegisterService } from 'src/app/services/bulk-register.service';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { ClickOutsideDirective } from '../../shared/directives/click-outside.directive';



// Update the interface to include field-specific errors
interface PreviewRow {
    rowNumber: number;
    'First Name': string;
    'Last Name': string;
    'Email': string;
    'Unique Identifier': string;
    'Subject ID': string;
    courses: any[];
    isValid: boolean;
    status: 'valid' | 'invalid';
    fieldErrors?: { [key: string]: string };
}

interface RegistrationType {
  isProfessor: boolean;
  isSubject: boolean;
  path: string;
}

interface SubjectRegistrationRow {
  rowNumber: number;
  'Subject Name': string;
  'Subject ID': string;
  'Subject Type': 'Mandatory' | 'Elective';
  'Educator': string[];
  'Educator_Name': string[];
  'Subject Summary': string;
  isValid: boolean;
  status: 'valid' | 'invalid';
  fieldErrors?: { [key: string]: string };
  educators?: any[];
}

// Update the Educator interface to match the API response
interface Educator {
    id: string;
    name: string;
    email: string;
    indexId: string | null;
}

// Simplified header mappings - just the standard forms we want to map to
const STANDARD_SUBJECT_HEADERS = {
    'Subject Name': ['subjectname'],
    'Subject ID': ['subjectid'],
    'Subject Type': ['subjecttype'],
    'Educator': ['educator'],
    'Subject Summary': ['subjectsummary']
};

const STANDARD_USER_HEADERS = {
    'First Name': ['firstname'],
    'Last Name': ['lastname'],
    'Email': ['email'],
    'Unique Identifier': ['uniqueidentifier'],
    'Subject ID': ['subjectid']
};

@Component({
  selector: 'app-bulk-register',
  templateUrl: './bulk-register.component.html',
  styleUrls: ['./bulk-register.component.scss']
})
export class BulkRegisterComponent implements OnInit {
 /**
    * Variable used to know if user is using excel form or manual form
    */
 excel = false;
 /**
  * Variable used to store faculties of institution
  */
 faculties;
 /**
  * Variable used to store departments of selected faculty
  */
 departments = [];

 /**
  * Variable used to store selected department courses
  */
 courses = [];

 /**
  * Form group used to get user input
  */
 professorRegister: FormGroup;

 /**
  * Variable stores configuration of ngx-select-dropdown
  */
 config;
 /**
  * Variables used to store information of user retrived from store
  */
 user$;
 user;
 /**
  * Variable used to store value of words left for institution
  */
 institutionWordsLeft;
 /**
  * Variable used to store a time for function to trigger.(x time after key is up call y function.)
  */
 timer;
 /**
  * Variable used to store a boolean value for validating email.
  */
 emailExists: boolean = false;
 getInstitutionProfessorsStateDetails$: any;

 /**
  * Variable used to store selected Faculty for bulk upload method
  */
 selectedFaculty: string;
 /**
  * Variable used to store selected Department for bulk upload method
  */
 selectedDepartment: string;
 fileToUpload: File;
 showErrors: boolean = false;
 errorFromExcel = [];
 currentUser$: any;
 currentUserDetailsSubscriber$: any;

 /**
  * Variable to store Excel data for preview
  */
 excelData: any[] = [];
 previewData: any[] = [];
 showPreview: boolean = false;
 fixedErrors: Set<number> = new Set();
 fileUploaded = false;
 currentPage: number = 1;
 pageSize: number = 20;
 pageSizeOptions: number[] = [10, 20, 30, 50, 100];
 totalPages: number = Math.ceil(this.previewData.length / this.pageSize);

 /**
  * Add new property for filtering
  */
 showOnlyErrors: boolean = false;

 /**
  * Map to store Subject IDs and their corresponding course objects
  */
 courseCodeMap: Map<string, any> = new Map();

 /**
  * Variable to store the name of the uploaded file
  */
 uploadedFileName: string = '';

 /**
  * Variables for upload progress tracking
  */
 isUploading: boolean = false;
 uploadProgress: number = 0;
 processedCount: number = 0;

 /**
  * Map to store duplicate emails and their first occurrence line number
  */
 private duplicateEmails: Map<string, number> = new Map();

 /**
  * Variable to determine if we're registering professors or students
  */
 registrationType: RegistrationType = {
    isProfessor: false,
    isSubject: false,
    path: ''
  };

 /**
  * Current step in the registration process
  * 1: Upload File
  * 2: Assign Faculty and Department
  * 3: Manage Data
  */
 currentStep: number = 1;

 /**
  * Add new property to store educators
  */
 educators: Educator[] = [];

 /**
  * Add the subject types array
  */
 subjectTypes = [
    { id: 'Mandatory', name: 'Mandatory' },
    { id: 'Elective', name: 'Elective' }
];

  // Update these properties
  private openSubjectTypeDropdowns: { [key: number]: boolean } = {};

   // Add these properties
   isFacultyOpen = false;
   isDepartmentOpen = false;
   selectedFacultyName: string = '';
   selectedDepartmentName: string = '';

   private dropdownStates = new Map<number, boolean>();
   isPaginationDropdownOpen = false;

   // Add beforeunload handler
  @HostListener('window:beforeunload', ['$event'])
  // Add new properties
  uploadInProgress: boolean = false;
  minimizedProgress: boolean = false;

    // Add this property
    downloadTemplateFileName: string;

    // Add these properties to the component class
    educatorSearchText: { [key: number]: string } = {};
    filteredEducators: { [key: number]: Educator[] } = {};
    private openEducatorDropdowns: { [key: number]: boolean } = {};

    // Add these properties to the component class
    courseSearchText: { [key: number]: string } = {};
    filteredCourses: { [key: number]: any[] } = {};
    private openCourseDropdowns: { [key: number]: boolean } = {};

    // Add new property
    private dropdownPositions = new Map<number, 'up' | 'down'>();

    // Add private property to store paginated data
    private _paginatedData: any[] = [];

 /**
  *
  * @param fb
  * @param facultyService
  * @param departmentService
  * @param courseService
  * @param store
  * @param userService
  * @param spinnerService
  * @param dialogRef
  * @param data
  * @param excelService
  */
 constructor(
    private fb: FormBuilder,
    private facultyService: FacultyService,
    private departmentService: DepartmentService,
    private courseService: CourseService,
    private store: Store,
    private userService: UserService,
    private spinnerService: NgxSpinnerService,
    @Optional() public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private excelService: ExcelService,
    private toastrService: ToastrService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private bulkRegisterService: BulkRegisterService,
    private dialog: MatDialog,
    private router: Router,
 ) {
    const currentPath = this.router.url;
    this.registrationType = {
        isProfessor: currentPath.includes('professor-register'),
        isSubject: currentPath.includes('course-register'),
        path: currentPath
    };

    this.downloadTemplateFileName = this.registrationType.isSubject ?
        'subject-register-form.xlsx' :
        this.registrationType.isProfessor ?
            'professor-register-form.xlsx' :
            'student-register-form.xlsx';
 }
 ngOnDestroy(): void {
    this.currentUserDetailsSubscriber$.unsubscribe();
    this.getInstitutionProfessorsStateDetails$.unsubscribe();
 }

 ngOnInit(): void {
    this.currentUser$ = this.store.select(getCurrentUser);
    this.currentUserDetailsSubscriber$ = this.store
       .select(getCurrentUser)
       .subscribe((data: User) => {
           this.user = data;
           this.institutionWordsLeft = this.user.Institution.wordsLeft;
       });
       this.translate.get(['new_entries.select', 'general.search', 'new_entries.no_results_found']).subscribe(translations => {
         this.config = {
            displayKey: 'title',
            search: true,
            height: 'auto',
            placeholder: translations['new_entries.select'],
            moreText: 'more',
            noResultsFound: translations['new_entries.no_results_found'],
            searchPlaceholder: translations['general.search'],
            searchOnKey: 'title',
            clearOnSelection: false,
            inputDirection: 'ltr',
            enableSelectAll: true,
            selectAllLabel: 'Select all',
            multiple: true
         };
       })
    this.facultyService
       .getFacultiesOfInstitution(0)
       .pipe(first())
       .subscribe(
          (data) => {
             this.faculties = data.faculties;
          },
          (error) => {
             console.log(error);
          }
       );
    this.professorRegister = this.fb.group({
       professorName: ['', [Validators.required, Validators.minLength(3)]],
       professorFaculty: ['', [Validators.required]],
       professorDepartment: ['', [Validators.required]],
       professorCourse: [''],
       professorEmail: ['', [Validators.required, Validators.email]],
       professorWordsToUse: [''],
    });

    this.getInstitutionProfessorsStateDetails$ = this.store
       .select(getInstitutionProfessorsStateDetails)
       .subscribe((data) => {
          if (data.professorRegistered !== null) {
             if (this.dialogRef) {
                this.dialogRef.close();
             } else {
                this.professorRegister.reset();
                this.store.dispatch(
                   professorActions.setProfessorRegisterToNull()
                );
             }
          }
       });

    // Subscribe to failed registration state
    this.bulkRegisterService.failedState$.subscribe(state => {
      if (state) {
        setTimeout(() => {  // Add timeout to ensure component is ready
          this.handleFailedRegistrationState(state);
          this.bulkRegisterService.clearFailedRegistrationState();
        });
      }
    });
 }

 private handleFailedRegistrationState(state: any) {
    if (state.failedData?.length) {
      // Initialize component for bulk upload
      this.toggleForm('bulk');

      // Set the data
      this.previewData = state.failedData;
      this.errorFromExcel = state.failedData.map(row => ({
        rowNumber: row.rowNumber,
        errors: Object.values(row.fieldErrors),
        isFixed: false
      }));

      // Set faculty and trigger department load
      if (state.faculty) {
        this.selectedFaculty = state.faculty;
        this.facultyChanged();
      }

      // Set department and trigger related data load
      if (state.department) {
        this.selectedDepartment = state.department;
        this.departmentChanged();
      }

      // Set necessary flags to show the table
      this.fileUploaded = true;
      this.showPreview = true;
      this.showErrors = true;
      this.excel = true;

      // Update pagination and move to the data management step
      this.currentPage = 1;
      this.updatePagination();
      this.currentStep = 3;

      // Force view update
      this.cd.detectChanges();
    }
  }

  // Update the dialog opening code in uploadToServer method:
  private showFailedRegistrationsDialog(allFailedRegistrations: any[], validUsers: any[]) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Registration Completed with Errors',
        message: `${allFailedRegistrations.length} of the ${validUsers.length} ${
          this.registrationType.isProfessor ? 'educators' :
          this.registrationType.isSubject ? 'subjects' : 'students'
        } could not be registered. Please review and correct the invalid entries.`,
        confirmText: 'Review Failures',
        showCancel: false,
        type: 'warning'
      },
      panelClass: 'confirmation-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Store failed registration state
        this.bulkRegisterService.setFailedRegistrationState({
          failedData: allFailedRegistrations,
          faculty: this.selectedFaculty,
          department: this.selectedDepartment,
          registrationType: this.registrationType.isProfessor ? 'professor' :
                          this.registrationType.isSubject ? 'subject' : 'student'
        });

        // Navigate to the appropriate bulk register route
        this.bulkRegisterService.navigateToFailedRegistrations(
          this.registrationType.isProfessor ? 'professor' :
          this.registrationType.isSubject ? 'subject' : 'student'
        );
      }
    });
  }

  /**
  * Method is used to toggle between excel upload form and manual register form.
  */
 toggleForm(type: 'manual' | 'bulk') {
    this.excel = type === 'bulk';
    this.resetForm(); // Clear any existing data when switching modes
    this.fileUploaded = false;
 }
 /**
  * Method is used to create a professorRegister.
  */
 async submit() {
    const result = await Swal.fire({
       title: 'You are about to add educators, please confirm your action by clicking "Confirm".',
       // icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#b5adad',
       confirmButtonText: this.translate.instant('app.confirm'),
       cancelButtonText: this.translate.instant('report.cancel'),
    });
    if (result.isConfirmed) {
       this.store.dispatch(
          professorActions.professorRegister({
             professorName: this.professorRegister.value.professorName,
             professorFaculty: this.professorRegister.value.professorFaculty,
             professorDepartment:
                this.professorRegister.value.professorDepartment,
             professorSubjects: this.professorRegister.value.professorCourse,
             professorEmail: this.professorRegister.value.professorEmail,
             professorWords: this.professorRegister.value.professorWordsToUse,
          })
       );
    }
 }

 /**
  * Method use to get form controls.
  */
 get form() {
    return this.professorRegister.controls;
 }
 /**
  * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
  */
 facultyChanged() {
    this.professorRegister.controls.professorDepartment.setValue('');

    this.departmentService
       .getDepartmentsOfFaculty(
          this.professorRegister.value.professorFaculty ||
             this.selectedFaculty
       )
       .pipe(first())
       .subscribe(
          (data) => {
             this.departments = data.departments;
          },
          (error) => {
             console.log('error', error);
          }
       );
 }


 /**
  *  Method is used to get department id from dropdown and then to retrieve courses of that department.
  */
 departmentChanged() {
    if (this.registrationType.isSubject) {
        this.userService
            .institutionProfessors(-1, this.selectedFaculty, this.selectedDepartment)
            .pipe(first())
            .subscribe(
                (data) => {
                    this.educators = data.professors || [];
                    this.previewData.forEach(row => {
                        // Reset educators array for this row
                        row.educators = [];
                        row['Educator_Name'] = [];

                        // Handle multiple educator codes
                        const educatorCodes = Array.isArray(row['Educator']) ?
                            row['Educator'] :
                            [row['Educator']];

                        educatorCodes.forEach(code => {
                            if (code) {
                                const matchingEducator = this.educators.find(educator =>
                                    educator.indexId?.toLowerCase() === code.toString().toLowerCase()
                                );

                                if (matchingEducator) {
                                    row.educators.push(matchingEducator);
                                    row['Educator_Name'].push(matchingEducator.name);
                                }
                            }
                        });
                    });

                    this.cd.detectChanges();
                },
                (error) => {
                    console.error('Error fetching educators:', error);
                }
            );
    } else if (this.registrationType.isProfessor) {
        this.courseService
            .courses(undefined, -1, this.selectedFaculty, this.selectedDepartment)
            .pipe(first())
            .subscribe(
                (data) => {
                    this.courses = data.courses;
                    this.courseCodeMap.clear();
                    this.courses.forEach(course => {
                        if (course.code) {
                            // Store both original and lowercase versions
                            this.courseCodeMap.set(course.code.toLowerCase(), course);
                            this.courseCodeMap.set(course.code, course); // Keep original for display
                        }
                    });

                    // Re-validate existing data with updated course map
                    this.previewData.forEach(row => {
                        if (row['Subject ID']) {
                            const courseCodes = row['Subject ID'].toString()
                                .split(',')
                                .map(code => code.trim());

                            const matchedCourses = courseCodes
                                .map(code => Array.from(this.courseCodeMap.entries())
                                    .find(([mapCode]) => mapCode.toLowerCase() === code.toLowerCase()))
                                .filter(entry => entry !== undefined)
                                .map(entry => entry[1]);

                            if (matchedCourses.length > 0) {
                                row.courses = matchedCourses;
                            }
                        }
                    });

                    this.cd.detectChanges();
                }
            );
    }
 }

 /**
  * Method used to download excel form for bulk uploads
  */

 downloadTemplate() {
    this.excelService
        .generateGetPresignedUrl(this.downloadTemplateFileName)
        .pipe(first())
        .subscribe(
            (data) => {
                // Determine the filename based on registration type
                const filename = this.registrationType.isSubject ?
                    'Subject Register Form.xlsx' :
                    this.registrationType.isProfessor ?
                        'Educator Register Form.xlsx' :
                        'Student Register Form.xlsx';

                saveAs(data.urlToDownload, filename);
                Swal.fire(this.translate.instant('app.document_saved'), '', 'success');
            },
            (error) => {
                console.log('error', error);
            }
        );
 }

 /**
  * Method used to trigger file upload.
  */
 upload() {
    if (!this.isDragging) {
      $('.dropzone').trigger('click');
    }
 }

 /**
  * Validates Excel data and returns array of errors
  */
 private validateExcelData(data: any[]): void {
    this.errorFromExcel = [];
    this.previewData = [];

    if (this.registrationType.isSubject) {
        this.validateSubjectExcelData(data);
    } else {
        this.validateUserExcelData(data);
    }

    this.totalPages = Math.ceil(this.previewData.length / this.pageSize);
    this.currentPage = 1;
    this.showErrors = this.errorFromExcel.length > 0;
    this.showPreview = true;
    this.cd.detectChanges();
}

private validateSubjectExcelData(data: any[]): void {
    const subjectIdMap = new Map<string, number>();

    data.forEach((row, index) => {
        const rowNumber = index + 1;
        const errors: { [key: string]: string } = {};

        // Get values directly without transformation
        const subjectName = row['Subject Name'] || '';
        const subjectId = row['Subject ID'] || '';

        // Remove duplicate subject IDs if they exist
        if (subjectId) {
            const subjectIds = subjectId.toString()
                .split(',')
                .map(id => id.trim())
                .filter((id, index, self) => self.indexOf(id) === index); // Remove duplicates

            // Update the row with deduplicated subject IDs
            row['Subject ID'] = subjectIds.join(',');
        }

        // Rest of the existing validation code remains unchanged
        const rawSubjectType = row['Subject Type']?.toString().toLowerCase() || '';
        const subjectType = rawSubjectType === 'mandatory' ? 'Mandatory' : 'Elective';

        // Normalize educator data and remove duplicates
        let educatorIds: string[] = [];
        if (row['Educator']) {
            // Handle different formats of educator data
            if (Array.isArray(row['Educator'])) {
                educatorIds = row['Educator']
                    .map(e => String(e).trim())
                    .filter((id, index, self) => self.indexOf(id) === index); // Remove duplicates
            } else {
                // Split by comma if it's a comma-separated string, otherwise treat as single value
                educatorIds = row['Educator'].toString().includes(',')
                    ? row['Educator'].toString()
                        .split(',')
                        .map(e => e.trim())
                        .filter((id, index, self) => self.indexOf(id) === index) // Remove duplicates
                    : [row['Educator'].toString().trim()];
            }

            // Update the row with deduplicated educator IDs
            row['Educator'] = educatorIds;
        }

        const summary = row['Subject Summary'] || '';

        // Validate Subject Name
        if (!subjectName) {
            errors['Subject Name'] = 'Subject name is required';
        }

        // Validate Subject ID
        if (!subjectId) {
            errors['Subject ID'] = 'Subject ID is required';
        } else {
            const normalizedId = String(subjectId).trim();
            const existingRow = subjectIdMap.get(normalizedId);
            if (existingRow !== undefined) {
                errors['Subject ID'] = `Duplicate Subject ID: already exists in row ${existingRow}`;
            } else {
                subjectIdMap.set(normalizedId, rowNumber);
            }
        }

        // Match educators with the available list
        const matchedEducators = educatorIds
            .map(id => this.educators.find(educator =>
                educator.indexId?.toString().toLowerCase() === id.toLowerCase()
            ))
            .filter(educator => educator !== undefined);

        // Create the validated row object
        const validatedRow: SubjectRegistrationRow = {
            rowNumber,
            'Subject Name': subjectName,
            'Subject ID': subjectId,
            'Subject Type': subjectType,
            'Educator': educatorIds,
            'Educator_Name': matchedEducators.map(e => e.name),
            'Subject Summary': summary,
            isValid: Object.keys(errors).length === 0,
            status: Object.keys(errors).length === 0 ? 'valid' : 'invalid',
            fieldErrors: errors,
            educators: matchedEducators
        };

        this.previewData.push(validatedRow);

        if (!validatedRow.isValid) {
            this.errorFromExcel.push({
                rowNumber,
                errors: Object.values(errors),
                isFixed: false
            });
        }
    });

    this.updatePagination();
}

private validateUserExcelData(data: any[]): void {
    const emailMap = new Map<string, number>();
    const identifierMap = new Map<string, number>();

    data.forEach((row, index) => {
        const rowNumber = index + 1;
        const errors: { [key: string]: string } = {};

        // Get values directly without transformation
        const firstName = row['First Name'] || '';
        const lastName = row['Last Name'] || '';
        const email = row['Email'] || '';
        const identifier = row['Unique Identifier']?.toString().trim() || '';

        // Remove duplicate Subject IDs if they exist
        if (row['Subject ID']) {
            const courseCodes = row['Subject ID'].toString()
                .split(',')
                .map(code => code.trim())
                .filter((code, index, self) => self.indexOf(code) === index); // Remove duplicates

            // Update the row with deduplicated Subject IDs
            row['Subject ID'] = courseCodes.join(',');
        }

        // Rest of the existing validation code remains unchanged
        if (!firstName) {
            errors['First Name'] = 'First name is required';
        } else if (!/^[a-zA-Z\s-']+$/.test(firstName)) {
            errors['First Name'] = 'First name should only contain letters';
        }

        if (!lastName) {
            errors['Last Name'] = 'Last name is required';
        } else if (!/^[a-zA-Z\s-']+$/.test(lastName)) {
            errors['Last Name'] = 'Last name should only contain letters';
        }

        // Keep existing email validation
        if (!email) {
            errors['Email'] = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            errors['Email'] = 'Invalid email format';
        } else {
            const normalizedEmail = email.toLowerCase().trim();
            const existingRow = emailMap.get(normalizedEmail);
            if (existingRow !== undefined) {
                errors['Email'] = `Duplicate email: already exists in row ${existingRow}`;
            } else {
                emailMap.set(normalizedEmail, rowNumber);
            }
        }

        // Add unique identifier validation
        if (identifier) {
            const existingIdentifierRow = identifierMap.get(identifier);
            if (existingIdentifierRow !== undefined) {
                errors['Unique Identifier'] = `Duplicate identifier: already exists in row ${existingIdentifierRow}`;
            } else {
                identifierMap.set(identifier, rowNumber);
            }
        }

        // Handle Subject IDs case-insensitively
        if (row['Subject ID']) {
            const courseCodes = row['Subject ID'].toString()
                .split(',')
                .map(code => code.trim());

            const matchedCourses = courseCodes
                .map(code => Array.from(this.courseCodeMap.entries())
                    .find(([mapCode]) => mapCode.toLowerCase() === code.toLowerCase()))
                .filter(entry => entry !== undefined)
                .map(entry => entry[1]);

            if (matchedCourses.length > 0) {
                row.courses = matchedCourses;
            }
        }

        // Create the validated row object with all required properties
        const validatedRow: PreviewRow = {
            rowNumber,
            'First Name': firstName,
            'Last Name': lastName,
            'Email': email,
            'Unique Identifier': identifier,
            'Subject ID': row['Subject ID'] || '',
            courses: row.courses || [],
            isValid: Object.keys(errors).length === 0,
            status: Object.keys(errors).length === 0 ? 'valid' : 'invalid',
            fieldErrors: errors
        };

        this.previewData.push(validatedRow);

        if (!validatedRow.isValid) {
            this.errorFromExcel.push({
                rowNumber,
                errors: Object.values(errors),
                isFixed: false
            });
        }
    });

    this.updatePagination();
}

 /**
  * Mark error as fixed
  */
 markAsFixed(rowNumber: number) {
    const errorIndex = this.errorFromExcel.findIndex(err => err.rowNumber === rowNumber);
    if (errorIndex !== -1) {
       this.errorFromExcel[errorIndex].isFixed = true;
       this.fixedErrors.add(rowNumber);

       // Update preview data status
       const previewIndex = this.previewData.findIndex(row => row.rowNumber === rowNumber);
       if (previewIndex !== -1) {
          this.previewData[previewIndex].isValid = true;
       }
    }
 }

 /**
  * Check if all errors are fixed
  */
 areAllErrorsFixed(): boolean {
    return this.errorFromExcel.every(error => error.isFixed);
 }

 /**
  * Handle file selection
  */
 onFileSelected(event: any) {
    // Check if event and files exist
    if (!event?.target?.files) {
        this.toastrService.error('No file selected');
        return;
    }

    const file = event.target.files[0];
    if (!file) {
        this.toastrService.error('No file selected');
        return;
    }

    // Check file type
    const allowedTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'application/vnd.ms-excel' // .xls
    ];

    if (!allowedTypes.includes(file.type)) {
        this.toastrService.error('Please upload an Excel file');
        return;
    }

    this.spinnerService.show();
    this.resetForm();

    this.uploadedFileName = file.name;
    const reader = new FileReader();
    this.cd.detectChanges();

    reader.onload = (e: any) => {
        try {
            const binary = e.target.result;
            const workbook = XLSX.read(binary, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Get all headers from the sheet, even if some cells are empty
            const range = XLSX.utils.decode_range(sheet['!ref']);
            const headers = [];
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cell = sheet[XLSX.utils.encode_cell({r: 0, c: C})];
                headers.push(cell ? cell.v : '');
            }

            const data = XLSX.utils.sheet_to_json(sheet);

            if (data.length === 0) {
                this.spinnerService.hide();
                this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        title: 'Empty File',
                        message: 'The Excel file is empty. Please ensure your file contains data and try again.',
                        confirmText: 'OK',
                        showCancel: false,
                        type: 'warning',
                        imageUrl: '../../../assets/images/warning-triangle-fillled.svg',
                        imageWidth: '48px',
                        imageHeight: '48px'
                    },
                    panelClass: 'confirmation-dialog-container'
                });
                return;
            }

            // Function to normalize header names for comparison
            const normalizeHeaderName = (header: string) =>
                (header || '').toString()
                    .toLowerCase()
                    .replace(/[\s_-]/g, '')
                    .replace(/[^a-z0-9]/g, '');

            const requiredHeaders = this.registrationType.isSubject ?
                ['Subject Name', 'Subject ID', 'Subject Type'] :
                ['First Name', 'Last Name', 'Email'];

            const missingHeaders = requiredHeaders.filter(required => {
                const normalizedRequired = normalizeHeaderName(required);
                return !headers.some(h => normalizeHeaderName(h) === normalizedRequired);
            });

            if (missingHeaders.length > 0) {
                this.spinnerService.hide();
                const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                    data: {
                        title: 'Missing Required Fields',
                        message: `The following required fields are missing from your Excel file: ${missingHeaders.join(', ')}. Please ensure all required fields are present and try again.`,
                        confirmText: 'OK',
                        showCancel: false,
                        type: 'warning',
                        imageUrl: '../../../assets/images/warning-triangle-fillled.svg',
                        imageWidth: '48px',
                        imageHeight: '48px'
                    },
                    panelClass: 'confirmation-dialog-container'
                });

                dialogRef.afterClosed().subscribe(() => {
                    const fileInput = document.querySelector('.dropzone') as HTMLInputElement;
                    if (fileInput) {
                        fileInput.value = '';
                    }
                });
                return;
            }

            // Continue with data normalization and validation
            const normalizedData = this.normalizeHeaders(data);

            if (this.registrationType.isSubject) {
                this.validateSubjectExcelData(normalizedData);
            } else {
                this.validateUserExcelData(normalizedData);
            }

            this.fileUploaded = true;
            this.nextStep();

            this.spinnerService.hide();

        } catch (error) {
            console.error('Excel processing error:', error);
            this.spinnerService.hide();
            this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Error Processing File',
                    message: 'An error occurred while processing your Excel file. Please ensure the file is in the correct format and try again.',
                    confirmText: 'OK',
                    showCancel: false,
                    type: 'error',
                    imageUrl: '../../../assets/images/warning-circled-filled.svg',
                    imageWidth: '48px',
                    imageHeight: '48px'
                },
                panelClass: 'confirmation-dialog-container'
            });
        }
    };

    reader.onerror = () => {
        this.spinnerService.hide(); // Hide spinner before showing error
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: 'Error Reading File',
                message: 'An error occurred while reading your file. Please try again.',
                confirmText: 'OK',
                showCancel: false,
                type: 'error',
                imageUrl: '../../../assets/images/warning-circled-filled.svg',
                imageWidth: '48px',
                imageHeight: '48px'
            },
            panelClass: 'confirmation-dialog-container'
        });
        this.resetForm();
    };

    try {
        reader.readAsBinaryString(file);
    } catch (error) {
        this.spinnerService.hide(); // Hide spinner before showing error
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: 'Error Reading File',
                message: 'An error occurred while reading your file. Please try again.',
                confirmText: 'OK',
                showCancel: false,
                type: 'error',
                imageUrl: '../../../assets/images/warning-circled-filled.svg',
                imageWidth: '48px',
                imageHeight: '48px'
            },
            panelClass: 'confirmation-dialog-container'
        });
        this.resetForm();
    }
 }

 /**
  * Reset form and clear all data
  */
 resetForm() {
    this.excelData = [];
    this.previewData = [];
    this.errorFromExcel = [];
    this.showErrors = false;
    this.showPreview = false;
    this.fixedErrors.clear();
    this.fileUploaded = false;

    const fileInput = document.querySelector('.dropzone') as HTMLInputElement;
    if (fileInput) {
        fileInput.value = '';
    }
 }

 /**
  * Method to upload data in chunks
  */
 async uploadToServer() {
    if (this.registrationType.isSubject) {
        await this.uploadSubjectsToServer();
    } else {
        // Existing user registration logic
        const validUsers = this.previewData.filter(row => row.status === 'valid');
        const totalRows = this.previewData.length;

        // Check if all rows have issues
        if (validUsers.length === 0 && totalRows > 0) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Unable to Proceed with Registration',
                    message: 'All entries either contain issues or already exist in the system. Please correct the issues accordingly or select another file to proceed with registration.',
                    confirmText: 'Start Over',
                    cancelText: 'Continue Editing',
                    type: 'warning',
                    imageUrl: '../../../assets/images/warning-triangle-fillled.svg',
                    imageWidth: '48px',
                    imageHeight: '48px',
                    color: '#A50D0D',
                    confirmButtonColor: '#A50D0D'
                },
                panelClass: 'confirmation-dialog-container'
            });

            const result = await dialogRef.afterClosed().toPromise();
            if (result) {
                // User chose to start over
                this.cancel();
            }
            return;
        }

        // No rows at all
        if (totalRows === 0) {
            this.toastrService.error('No users to register');
            return;
        }

        // Proceed with normal registration flow for valid users
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: this.registrationType.isProfessor ?
                    'Confirm Educator Registration' :
                    'Confirm Student Registration',
                message: `You are about to register ${validUsers.length} ${
                    this.registrationType.isProfessor ? 'educators' : 'students'
                }. Do you want to proceed?`,
                confirmText: 'Register',
                cancelText: 'Cancel',
                type: 'info'
            },
            panelClass: 'confirmation-dialog-container'
        });

        const result = await dialogRef.afterClosed().toPromise();
        if (!result) {
            return;
        }

        try {
            this.uploadInProgress = true;
            let allFailedRegistrations = [];

            this.bulkRegisterService.startRegistration(validUsers.length);

            const chunkSize = 27;
            let processed = 0;

            for (let i = 0; i < validUsers.length; i += chunkSize) {
                const chunk = validUsers.slice(i, i + chunkSize);

                const batchData = chunk.map(row => ({
                    firstName: row['First Name'],
                    lastName: row['Last Name'],
                    email: row['Email'],
                    uniqueIdentifier: row['Unique Identifier'] || null,
                    faculty: this.selectedFaculty,
                    department: this.selectedDepartment,
                    words: 0,
                    ...(this.registrationType.isProfessor ? { subjects: row.courses } : {})
                }));

                try {
                    const response = await this.userService.registerUsersBatch(
                        batchData,
                        this.registrationType.isProfessor ? 'professor' : 'student'
                    ).toPromise();

                    if (response.failedRegistrations?.length > 0) {
                        const transformedFailures = response.failedRegistrations.map(failure => ({
                            rowNumber: this.findOriginalRowNumber(failure.row),
                            'First Name': failure.row.firstName,
                            'Last Name': failure.row.lastName,
                            'Email': failure.row.email,
                            'Unique Identifier': failure.row.uniqueIdentifier || '',
                            courses: failure.row.subjects || [],
                            isValid: false,
                            status: 'invalid',
                            fieldErrors: this.transformBackendError(failure.error)
                        }));

                        allFailedRegistrations = [...allFailedRegistrations, ...transformedFailures];
                    }
                } catch (error) {
                    console.error('Batch registration error:', error);
                    chunk.forEach(row => {
                        allFailedRegistrations.push({
                            rowNumber: row.rowNumber,
                            ...row,
                            isValid: false,
                            status: 'invalid',
                            fieldErrors: {
                                system: 'Failed to process registration'
                            }
                        });
                    });
                }

                processed += chunk.length;
                this.bulkRegisterService.updateProgress(processed);
            }

            this.bulkRegisterService.completeRegistration();

            if (allFailedRegistrations.length > 0) {
                this.showFailedRegistrationsDialog(allFailedRegistrations, validUsers);
            } else {
                this.showSuccessModal(validUsers.length);
            }

        } catch (error) {
            console.error('Error during bulk registration:', error);
            this.toastrService.error(this.translate.instant('notifications.registration_failed'));
        } finally {
            this.uploadInProgress = false;
        }
    }
}

private transformBackendError(error: any): { [key: string]: string } {
    const fieldErrors: { [key: string]: string } = {};

    switch (error.type) {
        case 'database':
            if (error.message === 'Email already exists') {
                fieldErrors['Email'] = `Email already exists in the system`;
            } else if (error.message === 'Unique identifier already exists in the institution') {
                fieldErrors['Unique Identifier'] = `Identifier already exists in the institution`;
            } else if (error.message === 'User created but course association failed') {
                fieldErrors['Courses'] = 'Failed to associate courses with user';
            }
            break;

        case 'validation':
            if (error.details) {
                if (error.details.firstName) fieldErrors['First Name'] = 'First name is required';
                if (error.details.lastName) fieldErrors['Last Name'] = 'Last name is required';
                if (error.details.email) fieldErrors['Email'] = 'Email is required';
            }
            break;

        case 'system':
            fieldErrors['system'] = error.message || 'System error occurred';
            break;
    }

    return fieldErrors;
}

private findOriginalRowNumber(failedRow: any): number {
    return this.previewData.findIndex(row =>
        row['Email'] === failedRow.email &&
        row['First Name'] === failedRow.firstName &&
        row['Last Name'] === failedRow.lastName
    ) + 1;
}

showAlert() {
    Swal.fire(
      this.translate.instant('notifications.cant_upload_file'),
      this.translate.instant('notifications.please_select_institution_and_department_then_upload_the_file'),
       'warning'
    );
}

changeStyle(index) {
    let element = document.getElementById(index);
    element.style.textDecoration = 'line-through';
}

previousPage() {
    if (this.currentPage > 1) {
       this.currentPage--;
       this.cd.detectChanges();
    }
}

nextPage() {
    const maxPage = Math.ceil(this.previewData.length / this.pageSize);
    if (this.currentPage < maxPage) {
       this.currentPage++;
       this.cd.detectChanges();
    }
}

// First, restore the main validateRow method to handle both types
validateRow(row: PreviewRow | SubjectRegistrationRow, field?: string): void {
    if (this.registrationType.isSubject) {
        this.validateSubjectRow(row as SubjectRegistrationRow, field);
    } else {
        this.validateUserRow(row as PreviewRow, field);
    }

    // After validation, check if there are any remaining errors
    const hasRemainingErrors = this.previewData.some(row => !row.isValid);

    // If showing only errors and no errors remain, switch back to showing all rows
    if (this.showOnlyErrors && !hasRemainingErrors) {
        this.showOnlyErrors = false;
        this.errorFromExcel = [];
        this.updatePagination();
        this.cd.detectChanges();
    }
}

// Add back the subject-specific validation method
private validateSubjectRow(row: SubjectRegistrationRow, field?: string): void {
    const fieldErrors = { ...row.fieldErrors } || {};

    if (field) {
        switch (field) {
            case 'Subject Name':
                if (!row['Subject Name']) {
                    fieldErrors['Subject Name'] = 'Subject name is required';
                } else {
                    delete fieldErrors['Subject Name'];
                }
                break;
            case 'Subject ID':
                if (!row['Subject ID']) {
                    fieldErrors['Subject ID'] = 'Subject ID is required';
                } else {
                    // Check for duplicates in other rows
                    const duplicateRow = this.previewData.find(r =>
                        r !== row &&
                        r['Subject ID'] === row['Subject ID']
                    );
                    if (duplicateRow) {
                        fieldErrors['Subject ID'] =
                            `Duplicate Subject ID: already exists in row ${duplicateRow.rowNumber}`;
                    } else {
                        delete fieldErrors['Subject ID'];
                    }
                }
                break;
            case 'Subject Type':
                if (row['Subject Type']) {
                    const normalizedType = row['Subject Type'].toString().toLowerCase();
                    row['Subject Type'] = normalizedType === 'mandatory' ? 'Mandatory' : 'Elective';
                } else {
                    row['Subject Type'] = 'Elective';
                }
                delete fieldErrors['Subject Type'];
                break;
            case 'Educator':
                // No validation needed for educators, just update the row
                break;
        }
    } else {
        // Full validation
        if (!row['Subject Name']) {
            fieldErrors['Subject Name'] = 'Subject name is required';
        }

        if (!row['Subject ID']) {
            fieldErrors['Subject ID'] = 'Subject ID is required';
        } else {
            const duplicateRow = this.previewData.find(r =>
                r !== row &&
                r['Subject ID'] === row['Subject ID']
            );
            if (duplicateRow) {
                fieldErrors['Subject ID'] =
                    `Duplicate Subject ID: already exists in row ${duplicateRow.rowNumber}`;
            }
        }

        // Normalize Subject Type
        if (!row['Subject Type']) {
            row['Subject Type'] = 'Elective';
        } else {
            const normalizedType = row['Subject Type'].toString().toLowerCase();
            row['Subject Type'] = normalizedType === 'mandatory' ? 'Mandatory' : 'Elective';
        }
    }

    // Update row status
    row.fieldErrors = fieldErrors;
    const hasErrors = Object.keys(fieldErrors).length > 0;
    row.isValid = !hasErrors;
    row.status = hasErrors ? 'invalid' : 'valid';

    this.updateErrorTracking(row);

    if (!row.isValid) {
        if (!this.errorFromExcel.includes(row)) {
            this.errorFromExcel.push(row);
        }
    } else {
        const index = this.errorFromExcel.indexOf(row);
        if (index > -1) {
            this.errorFromExcel.splice(index, 1);
        }
    }
}

private validateUserRow(row: PreviewRow, field?: string): void {
    const fieldErrors = { ...row.fieldErrors } || {};

    if (field) {
        switch (field) {
            case 'First Name':
                if (!row['First Name']) {
                    fieldErrors['First Name'] = 'First name is required';
                } else if (!/^[a-zA-Z\s-']+$/.test(row['First Name'])) {
                    fieldErrors['First Name'] = 'First name should only contain letters';
                } else {
                    delete fieldErrors['First Name'];
                }
                break;
            case 'Last Name':
                if (!row['Last Name']) {
                    fieldErrors['Last Name'] = 'Last name is required';
                } else if (!/^[a-zA-Z\s-']+$/.test(row['Last Name'])) {
                    fieldErrors['Last Name'] = 'Last name should only contain letters';
                } else {
                    delete fieldErrors['Last Name'];
                }
                break;
            case 'Email':
                if (!row['Email']) {
                    fieldErrors['Email'] = 'Email is required';
                } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(row['Email'])) {
                    fieldErrors['Email'] = 'Invalid email format';
                } else {
                    const normalizedEmail = row['Email'].toLowerCase().trim();
                    const duplicateRow = this.previewData.find(r =>
                        r !== row &&
                        r['Email']?.toLowerCase().trim() === normalizedEmail
                    );
                    if (duplicateRow) {
                        fieldErrors['Email'] = `Duplicate email: already exists in row ${duplicateRow.rowNumber}`;
                    } else {
                        delete fieldErrors['Email'];
                    }
                }
                break;
            case 'Unique Identifier':
                const identifier = row['Unique Identifier']?.toString().trim() || '';
                if (identifier) {
                    // Check for duplicates in other rows
                    const duplicateRow = this.previewData.find(r =>
                        r !== row &&
                        r['Unique Identifier']?.toString().trim() === identifier
                    );

                    if (duplicateRow) {
                        fieldErrors['Unique Identifier'] =
                            `Duplicate identifier: already exists in row ${duplicateRow.rowNumber}`;
                    } else {
                        delete fieldErrors['Unique Identifier'];
                    }
                } else {
                    // If identifier is empty, remove any existing error
                    delete fieldErrors['Unique Identifier'];
                }
                break;
            // ... other cases remain the same
        }
    } else {
        // Full validation
        // Keep existing validations for other fields
        if (!row['First Name']) {
            fieldErrors['First Name'] = 'First name is required';
        } else if (!/^[a-zA-Z\s-']+$/.test(row['First Name'])) {
            fieldErrors['First Name'] = 'First name should only contain letters';
        }

        if (!row['Last Name']) {
            fieldErrors['Last Name'] = 'Last name is required';
        } else if (!/^[a-zA-Z\s-']+$/.test(row['Last Name'])) {
            fieldErrors['Last Name'] = 'Last name should only contain letters';
        }

        if (!row['Email']) {
            fieldErrors['Email'] = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(row['Email'])) {
            fieldErrors['Email'] = 'Invalid email format';
        } else {
            const normalizedEmail = row['Email'].toLowerCase().trim();
            const duplicateRow = this.previewData.find(r =>
                r !== row &&
                r['Email']?.toLowerCase().trim() === normalizedEmail
            );
            if (duplicateRow) {
                fieldErrors['Email'] = `Duplicate email: already exists in row ${duplicateRow.rowNumber}`;
            }
        }

        // Add unique identifier validation for full validation
        const identifier = row['Unique Identifier']?.toString().trim() || '';
        if (identifier) {
            const duplicateRow = this.previewData.find(r =>
                r !== row &&
                r['Unique Identifier']?.toString().trim() === identifier
            );

            if (duplicateRow) {
                fieldErrors['Unique Identifier'] =
                    `Duplicate identifier: already exists in row ${duplicateRow.rowNumber}`;
            }
        }
    }

    // Update row status
    row.fieldErrors = fieldErrors;
    const hasErrors = Object.keys(fieldErrors).length > 0;
    row.isValid = !hasErrors;
    row.status = hasErrors ? 'invalid' : 'valid';

    // Update error tracking
    this.updateErrorTracking(row);
}

  isValidName(name: string | null | undefined): boolean {
    if (!name) return false;
    const nameStr = String(name);
    const nameRegex = /^[a-zA-Z\s'-]+$/;
    return nameRegex.test(nameStr.trim());
  }

  isValidEmail(email: string | null | undefined): boolean {
    if (!email) return false;
    const emailStr = String(email); // Convert to string
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(emailStr.trim());
  }

  /**
    * Method to handle page size change
    */
  onPageSizeChange(size: number) {
    this.pageSize = Number(size); // Convert to number to be safe
    this.currentPage = 1;
    this.totalPages = Math.ceil(this.previewData.length / this.pageSize);
    this.isPaginationDropdownOpen = false;
    this.cd.detectChanges();
  }

  /**
   * Method to get filtered data based on error status
   */
  get filteredPreviewData(): any[] {
    if (this.showOnlyErrors) {
        return this.previewData.filter(row => !row.isValid);
    }
    return this.previewData;
  }

  /**
   * Toggle error filter
   */
  toggleErrorFilter() {
    this.showOnlyErrors = !this.showOnlyErrors;
    this.currentPage = 1;
    this.updatePagination();
    this.cd.detectChanges();
  }


  /**
   * Method to update courses for a specific row
   */
  updateRowCourses(row: any, selectedCourses: any[]) {
    const index = this.previewData.findIndex(r => r.rowNumber === row.rowNumber);
    if (index !== -1) {
       this.previewData[index].courses = selectedCourses;
    }
  }

  /**
   * Method to safely handle course selection changes
   */
  onCourseSelectionChange(row: any, event: any) {
    const index = this.previewData.findIndex(r => r.rowNumber === row.rowNumber);
    if (index !== -1) {
       if (!this.previewData[index].courses) {
          this.previewData[index].courses = [];
       }

       if (Array.isArray(event)) {
          this.previewData[index].courses = [...event];
       } else if (event) {
          const courseExists = this.previewData[index].courses.some(
             course => course.id === event.id
          );

          if (!courseExists) {
             this.previewData[index].courses.push(event);
          } else {
             this.previewData[index].courses = this.previewData[index].courses.filter(
                course => course.id !== event.id
             );
          }
       }

       // Force change detection
       this.cd.detectChanges();
    }
  }

  removeAllErrorRows() {
    if (!this.showOnlyErrors) {
        return; // Only allow this action when viewing error rows
    }

    // Remove all invalid rows from previewData
    this.previewData = this.previewData.filter(row => row.isValid);

    // Clear error tracking arrays
    this.errorFromExcel = [];
    this.fixedErrors.clear();

    // Update pagination
    this.totalPages = Math.ceil(this.previewData.length / this.pageSize);
    if (this.currentPage > this.totalPages) {
        this.currentPage = Math.max(1, this.totalPages);
    }

    // Switch back to showing all rows since there are no more errors
    this.showOnlyErrors = false;

    // Force change detection
    this.cd.detectChanges();
  }

  /**
   * Navigate to a specific step if allowed
   * @param step The step number to navigate to
   */
  goToStep(step: number): void {
    if (this.canNavigateToStep(step)) {
      this.currentStep = step;
    }
  }

  /**
   * Check if navigation to a step is allowed
   * @param step The step number to check
   * @returns boolean indicating if navigation is allowed
   */
  private canNavigateToStep(step: number): boolean {
    // Only allow navigation to completed steps
    return step < this.currentStep;
  }

  /**
   * Move to the next step if conditions are met
   */
  nextStep(): void {
    if (this.canProceedToNextStep()) {
      this.currentStep++;
    }
  }

  /**
   * Move to the previous step
   */
  previousStep(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  /**
   * Check if proceeding to next step is allowed
   * @returns boolean indicating if proceeding is allowed
   */
  canProceedToNextStep(): boolean {
    switch (this.currentStep) {
      case 1:
        return this.fileUploaded;
      case 2:
        return !!this.selectedFaculty && !!this.selectedDepartment &&
               this.selectedFaculty !== '' && this.selectedDepartment !== '';
      default:
        return true;
    }
  }

  cancel() {
    // Same functionality as removeFile
    this.removeFile();
  }

  removeFile() {
    this.resetForm();
    this.currentStep = 1;
    this.selectedFaculty = '';
    this.selectedDepartment = '';
    this.uploadedFileName = '';
  }

  // Add these methods
  toggleFacultyDropdown() {
    this.isFacultyOpen = !this.isFacultyOpen;
    if (this.isFacultyOpen) {
      this.isDepartmentOpen = false;
    }
  }

  toggleDepartmentDropdown() {
    if (!this.selectedFaculty) return;
    this.isDepartmentOpen = !this.isDepartmentOpen;
    if (this.isDepartmentOpen) {
      this.isFacultyOpen = false;
    }
  }

  closeFacultyDropdown() {
    this.isFacultyOpen = false;
  }

  closeDepartmentDropdown() {
    this.isDepartmentOpen = false;
  }

  selectFaculty(faculty: any) {
    this.selectedFaculty = faculty.id;
    this.selectedFacultyName = faculty.name;
    this.selectedDepartment = '';
    this.selectedDepartmentName = '';
    this.facultyChanged();
    this.isFacultyOpen = false;
  }

  selectDepartment(department: any) {
    this.selectedDepartment = department.id;
    this.selectedDepartmentName = department.name;
    this.departmentChanged();
    this.isDepartmentOpen = false;
  }

  getStatusIcon(status: 'valid' | 'invalid'): string {
    return status === 'valid'
      ? '../../../assets/images/icons/check-circled-filled-success.svg'
      : '../../../assets/images/icons/check-circled-filled-error.svg';
  }

  getStatusClass(status: 'valid' | 'invalid'): string {
    return status === 'valid' ? 'status-valid' : 'status-invalid';
  }

  toggleSubjectDropdown(event: Event, row: any) {
    event.stopPropagation();
    // Close any other open dropdowns
    this.dropdownStates.forEach((_, key) => {
      if (key !== row.rowNumber) {
        this.dropdownStates.set(key, false);
      }
    });
    // Toggle current dropdown
    const currentState = this.dropdownStates.get(row.rowNumber) || false;
    this.dropdownStates.set(row.rowNumber, !currentState);
  }

  isDropdownOpen(row: any): boolean {
    return this.dropdownStates.get(row.rowNumber) || false;
  }

  /**
   * Closes all dropdowns and clears their associated search states
   */
  closeAllDropdowns() {
    // Clear dropdown states for all types
    this.dropdownStates.clear();
    this.openCourseDropdowns = {};
    this.openEducatorDropdowns = {};
    this.openSubjectTypeDropdowns = {};

    // Clear search states
    this.courseSearchText = {};
    this.educatorSearchText = {};

    // Clear filtered results
    this.filteredCourses = {};
    this.filteredEducators = {};

    // Clear dropdown positions
    this.dropdownPositions.clear();

    // Close faculty and department dropdowns
    this.isFacultyOpen = false;
    this.isDepartmentOpen = false;
    this.isPaginationDropdownOpen = false;
  }

  selectSubject(row: any, subject: any) {
    if (!row.courses) {
      row.courses = [];
    }
    const index = row.courses.findIndex(c => c.id === subject.id);
    if (index === -1) {
      row.courses.push(subject);
    } else {
      row.courses.splice(index, 1);
    }
  }

  isSubjectSelected(row: any, subject: any): boolean {
    return row.courses?.some(c => c.id === subject.id) ?? false;
  }


  togglePaginationDropdown(event: Event) {
    event.stopPropagation();
    this.isPaginationDropdownOpen = !this.isPaginationDropdownOpen;
  }


  // Add this method to check for duplicate email errors
  isDuplicateEmailError(error: string): boolean {
    return error.toLowerCase().includes('duplicate email');
  }

  // Add a method to get error icon based on type
  getErrorIcon(error: string): string {
    if (this.isDuplicateEmailError(error) || error.includes('Duplicate Unique Identifier')) {
        return '../../../assets/images/warning-triangle-fillled.svg';
    }
    return '../../../assets/images/warning-circled-filled.svg';
  }

  private updatePagination() {
    const filteredData = this.showOnlyErrors ?
        this.previewData.filter(row => !row.isValid) :
        this.previewData;

    this.totalPages = Math.ceil(filteredData.length / this.pageSize);
    if (this.currentPage > this.totalPages) {
        this.currentPage = Math.max(1, this.totalPages);
    }
  }

  handleBeforeUnload(event: BeforeUnloadEvent) {
    if (this.uploadInProgress) {
        event.returnValue = 'Registration is in progress. Are you sure you want to leave?';
        return event.returnValue;
    }
    return true;
  }

  // Add method to toggle progress view
  toggleProgressView() {
    this.minimizedProgress = !this.minimizedProgress;
  }

  // Update the confirmDeleteRow method with proper type checking
  async confirmDeleteRow(index: number) {
    const rowToDelete = this.paginatedData[index];
    if (!rowToDelete) {
        console.error('Row not found at index:', index);
        return;
    }

    let title: string;
    let message: string;

    if (this.registrationType.isSubject) {
        const subjectName = rowToDelete['Subject Name'] || 'Untitled';
        title = 'Delete Subject';
        message = `You are about to remove subject "${subjectName}" from the registration process. Once removed, it cannot be re-added to the current session.`;
    } else {
        const firstName = rowToDelete['First Name'] || '';
        const lastName = rowToDelete['Last Name'] || '';
        const userName = `${firstName} ${lastName}`.trim() || 'Selected user';

        title = this.registrationType.isProfessor ? 'Delete Educator' : 'Delete Student';
        message = `You are about to remove ${userName} from the registration process. Once removed, the user cannot be re-added to the current session.`;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
            title,
            message,
            confirmText: this.registrationType.isSubject ? 'Remove Subject' : 'Remove User',
            cancelText: 'Cancel',
            type: 'warning',
            imageUrl: '../../../assets/images/warning-triangle-fillled.svg',
            imageWidth: '48px',
            imageHeight: '48px'
        },
        panelClass: 'confirmation-dialog-container'
    });

    try {
        const result = await dialogRef.afterClosed().toPromise();
        if (result) {
            this.deleteRow(index);
        }
    } catch (error) {
        console.error('Error in confirmation dialog:', error);
    }
  }

  // Update the deleteRow method with proper error handling
  private deleteRow(index: number) {
    const rowToDelete = this.paginatedData[index];
    if (!rowToDelete) {
        console.error('Row not found at index:', index);
        return;
    }

    // Remove from previewData
    const previewIndex = this.previewData.findIndex(row => row.rowNumber === rowToDelete.rowNumber);
    if (previewIndex !== -1) {
        this.previewData.splice(previewIndex, 1);
    } else {
        console.warn('Row not found in previewData:', rowToDelete.rowNumber);
    }

    // Remove from errorFromExcel if present
    const errorIndex = this.errorFromExcel.findIndex(error => error.rowNumber === rowToDelete.rowNumber);
    if (errorIndex !== -1) {
        this.errorFromExcel.splice(errorIndex, 1);
    }

    // Update pagination
    this.updatePagination();
    this.cd.detectChanges();
  }

  async confirmDeleteAllErrorRows() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
            title: this.registrationType.isSubject ?
                'Remove subjects with issues' :
                'Remove users with issues',
            message: this.registrationType.isSubject ?
                'You are about to remove all subjects containing issues. Once removed, these subjects cannot be re-added to the current session.' :
                'You are about to remove all users containing issues. Once removed, these users cannot be re-added to the current session.',
            confirmText: 'Confirm Removal',
            cancelText: 'Cancel'
        },
        panelClass: 'confirmation-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            this.removeAllErrorRows();
        }
    });
  }

  private showSuccessModal(registeredCount: number) {
    let title: string;
    let message: string;

    if (this.registrationType.isSubject) {
        title = 'Subjects Successfully Registered';
        message = `${registeredCount} subjects have been successfully registered to your institution.`;
    } else {
        title = this.registrationType.isProfessor ?
            'Successful Bulk Educator Registration' :
            'Successful Bulk Student Registration';
        message = `User list from ${this.uploadedFileName || 'Institution_Educator.xlsx'} has been successfully registered. ${registeredCount} ${this.registrationType.isProfessor ? 'educators' : 'students'} have been added to your institution.`;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
            title,
            message,
            confirmText: 'Close',
            showCancel: false,
            type: 'success',
            imageUrl: '../../../assets/images/icons/success_icon.svg',
            imageWidth: '100px',
            imageHeight: '100px'
        },
        panelClass: 'confirmation-dialog-container',
        disableClose: true
    });

    dialogRef.afterClosed().subscribe(() => {
        this.cancel();
    });
  }

  // Add helper method to get field error
  getFieldError(row: PreviewRow, field: string): string | null {
    return row.fieldErrors?.[field] || null;
  }

  // Add this method to get all field errors for a row
  getFieldErrors(row: PreviewRow | SubjectRegistrationRow): string[] {
    if (!row.fieldErrors) return [];
    return Object.values(row.fieldErrors);
  }

  // Add these helper methods
  getGroupedErrors(row: PreviewRow): { errors: string[], warnings: string[] } {
    const result = {
        errors: [],
        warnings: []
    };

    if (!row.fieldErrors) return result;

    // Check for required fields first
    const requiredFields = ['First Name', 'Last Name', 'Email'].filter(field => {
        const value = row[field];
        // Handle different types of values
        if (value === null || value === undefined) return true;
        if (typeof value === 'string') return value.trim() === '';
        return !String(value).trim();
    });

    if (requiredFields.length > 0) {
        result.errors.push('First name, last name, and email are required fields');
    } else {
        // Process other validation errors
        Object.entries(row.fieldErrors).forEach(([field, error]) => {
            if (this.isDuplicateEmailError(error)) {
                result.warnings.push(error);
            } else if (!requiredFields.includes(field)) { // Don't add individual errors for required fields
                if (field === 'First Name' || field === 'Last Name') {
                    result.errors.push(`${field} should only contain letters`);
                } else if (field === 'Email' && error.includes('Invalid')) {
                    result.errors.push('Invalid email format');
                } else {
                    result.errors.push(error);
                }
            }
        });
    }

    return result;
  }

  // Update isDuplicateError to include unique identifier
  isDuplicateError(error: string): boolean {
    return error.toLowerCase().includes('duplicate email') ||
           error.toLowerCase().includes('duplicate unique identifier');
  }

  // Add this helper function at the class level
  private isEmpty(value: any): boolean {
    if (value === null || value === undefined) return true;
    if (typeof value === 'string') return value.trim() === '';
    return !String(value).trim();
  }

  // Update the methods for subject type dropdown
  toggleSubjectTypeDropdown(event: Event, row: SubjectRegistrationRow) {
    event.stopPropagation();
    const rowNumber = row.rowNumber;

    // Calculate position before toggling
    const position = this.calculateDropdownPosition(event, rowNumber);
    this.dropdownPositions.set(rowNumber, position);

    this.openSubjectTypeDropdowns[rowNumber] = !this.openSubjectTypeDropdowns[rowNumber];
  }

  isSubjectTypeOpen(row: SubjectRegistrationRow): boolean {
    return this.openSubjectTypeDropdowns[row.rowNumber] || false;
  }

  closeAllSubjectTypeDropdowns() {
    this.openSubjectTypeDropdowns = {};
  }

  selectSubjectType(type: any, row: SubjectRegistrationRow) {
    row['Subject Type'] = type.id as 'Mandatory' | 'Elective';
    this.validateRow(row, 'Subject Type');
    this.openSubjectTypeDropdowns[row.rowNumber] = false;
  }

  // Add this method to the component class
  private updateErrorTracking(row: PreviewRow | SubjectRegistrationRow): void {
    const errorIndex = this.errorFromExcel.findIndex(e => e.rowNumber === row.rowNumber);
    const hasErrors = Object.keys(row.fieldErrors || {}).length > 0;

    if (hasErrors) {
        const errorMessages = Object.values(row.fieldErrors || {});
        if (errorIndex === -1) {
            // Add new error entry
            this.errorFromExcel.push({
                rowNumber: row.rowNumber,
                errors: errorMessages,
                isFixed: false
            });
        } else {
            // Update existing error entry
            this.errorFromExcel[errorIndex].errors = errorMessages;
        }
    } else if (errorIndex !== -1) {
        // Remove error entry if no more errors
        this.errorFromExcel.splice(errorIndex, 1);
    }

    // Check if there are any remaining errors
    const hasRemainingErrors = this.previewData.some(r => !r.isValid);

    // If showing only errors and no errors remain, switch back to showing all rows
    if (this.showOnlyErrors && !hasRemainingErrors) {
        this.showOnlyErrors = false;
        this.currentPage = 1; // Reset to first page when switching views
    }

    // Update pagination if showing only errors
    this.updatePagination();
    this.cd.detectChanges();
  }

  // Add/update these methods
  toggleEducatorDropdown(event: Event, row: SubjectRegistrationRow) {
    event.stopPropagation();
    const rowNumber = row.rowNumber;

    // Calculate position before toggling
    const position = this.calculateDropdownPosition(event, rowNumber);
    this.dropdownPositions.set(rowNumber, position);

    // Initialize search and filtered educators for this row
    if (!this.educatorSearchText[rowNumber]) {
      this.educatorSearchText[rowNumber] = '';
      this.filteredEducators[rowNumber] = [...this.educators];
    }

    this.openEducatorDropdowns[rowNumber] = !this.openEducatorDropdowns[rowNumber];

    // Close other dropdowns
    Object.keys(this.openEducatorDropdowns).forEach(key => {
      if (Number(key) !== rowNumber) {
        this.openEducatorDropdowns[Number(key)] = false;
      }
    });
  }

  // Add method to handle educator search
  onEducatorSearch(event: Event, row: SubjectRegistrationRow) {
    event.stopPropagation();
    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    this.educatorSearchText[row.rowNumber] = searchText;

    this.filteredEducators[row.rowNumber] = this.educators.filter(educator =>
        educator.email.toLowerCase().includes(searchText) ||
        educator.indexId?.toLowerCase().includes(searchText) ||
        educator.name.toLowerCase().includes(searchText)
    );
  }

  // Update closeAllEducatorDropdowns to also clear search
  closeAllEducatorDropdowns() {
    this.openEducatorDropdowns = {};
    this.educatorSearchText = {};
    this.filteredEducators = {};
  }

  /**
   * Method to handle subject registration
   */
  private async uploadSubjectsToServer() {
    try {
        const validSubjects = this.previewData
            .filter((row: SubjectRegistrationRow) => row.status === 'valid')
            .map(row => ({
                'Subject Name': row['Subject Name'],
                'Subject ID': row['Subject ID'],
                'Subject Type': row['Subject Type'],
                'Subject Summary': row['Subject Summary'],
                department: this.selectedDepartment,
                educators: row.educators || []
            }));

        const totalRows = this.previewData.length;

        // Add check for all rows having issues
        if (validSubjects.length === 0 && totalRows > 0) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: 'Unable to Proceed with Registration',
                    message: 'All entries either contain issues or already exist in the system. Please correct the issues accordingly or select another file to proceed with registration.',
                    confirmText: 'Start Over',
                    cancelText: 'Continue Editing',
                    type: 'warning',
                    imageUrl: '../../../assets/images/warning-triangle-fillled.svg',
                    imageWidth: '48px',
                    imageHeight: '48px',
                    color: '#A50D0D',
                    confirmButtonColor: '#A50D0D'
                },
                panelClass: 'confirmation-dialog-container'
            });

            const result = await dialogRef.afterClosed().toPromise();
            if (result) {
                // User chose to start over
                this.cancel();
            }
            return;
        }

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: 'Confirm Subject Registration',
                message: `You are about to register ${validSubjects.length} subjects. Do you want to proceed?`,
                confirmText: 'Register',
                cancelText: 'Cancel',
                type: 'info'
            },
            panelClass: 'confirmation-dialog-container'
        });

        const result = await dialogRef.afterClosed().toPromise();
        if (!result) {
            return;
        }

        this.uploadInProgress = true;
        let allFailedRegistrations = [];

        // Initialize progress tracking
        this.bulkRegisterService.startRegistration(validSubjects.length);
        let processed = 0;

        // Process subjects in chunks of 7
        const chunkSize = 27;
        for (let i = 0; i < validSubjects.length; i += chunkSize) {
            const chunk = validSubjects.slice(i, i + chunkSize);

            try {
                const response = await this.userService.registerSubjectsBatch({
                    courses: chunk
                }).toPromise();

                if (response.failedRegistrations?.length > 0) {
                    const transformedFailures = response.failedRegistrations.map(failure => ({
                        rowNumber: this.findOriginalSubjectRowNumber(failure.row),
                        'Subject Name': failure.row['Subject Name'],
                        'Subject ID': failure.row['Subject ID'],
                        'Subject Type': failure.row['Subject Type'],
                        'Subject Summary': failure.row['Subject Summary'],
                        educators: failure.row.educators || [],
                        isValid: false,
                        status: 'invalid',
                        fieldErrors: this.transformSubjectBackendError(failure.error)
                    }));

                    allFailedRegistrations = [...allFailedRegistrations, ...transformedFailures];
                }
            } catch (error) {
                console.error('Batch registration error:', error);
                chunk.forEach(subject => {
                    allFailedRegistrations.push({
                        rowNumber: this.findOriginalSubjectRowNumber(subject),
                        ...subject,
                        isValid: false,
                        status: 'invalid',
                        fieldErrors: {
                            system: 'Failed to process registration'
                        }
                    });
                });
            }

            // Update progress
            processed += chunk.length;
            this.bulkRegisterService.updateProgress(processed);
        }

        this.bulkRegisterService.completeRegistration();

        if (allFailedRegistrations.length > 0) {
            this.showFailedRegistrationsDialog(allFailedRegistrations, validSubjects);
        } else {
            this.showSuccessModal(validSubjects.length);
        }

    } catch (error) {
        console.error('Error during subject registration:', error);
        this.toastrService.error(this.translate.instant('notifications.registration_failed'));
    } finally {
        this.uploadInProgress = false;
    }
  }

  // Add this helper method to find original row number for subjects
  private findOriginalSubjectRowNumber(subject: any): number {
    return this.previewData.findIndex(row =>
        row['Subject ID'] === subject['Subject ID'] &&
        row['Subject Name'] === subject['Subject Name']
    ) + 1;
  }

  // Add this method for the dropdown
  isEducatorDropdownOpen(row: SubjectRegistrationRow): boolean {
    return this.openEducatorDropdowns[row.rowNumber] || false;
  }

  // Add method to check if educator is selected
  isEducatorSelected(row: SubjectRegistrationRow, educator: Educator): boolean {
    if (!Array.isArray(row.educators)) {
        return false;
    }
    return row.educators.some(e => e.id === educator.id);
  }

  // Add method to handle educator selection
  selectEducator(row: SubjectRegistrationRow, educator: Educator, event: Event) {
    event.stopPropagation();

    if (!Array.isArray(row.educators)) {
        row.educators = [];
    }

    const index = row.educators.findIndex(e => e.id === educator.id);
    if (index === -1) {
        // Add educator if not already selected
        row.educators.push(educator);
        row['Educator'] = row.educators.map(e => e.indexId);
        row['Educator_Name'] = row.educators.map(e => e.name);
    } else {
        // Remove educator if already selected
        row.educators.splice(index, 1);
        row['Educator'] = row.educators.map(e => e.indexId);
        row['Educator_Name'] = row.educators.map(e => e.name);
    }

    this.validateRow(row, 'Educator');
  }

  // Add this method to transform backend errors for subjects
  private transformSubjectBackendError(error: any): { [key: string]: string } {
    const fieldErrors: { [key: string]: string } = {};

    switch (error.type) {
        case 'validation':
            if (error.details) {
                if (error.details.name) fieldErrors['Subject Name'] = 'Subject name is required';
                if (error.details.id) fieldErrors['Subject ID'] = 'Subject ID is required';
            }
            if (error.message === 'Subject ID already exists') {
                fieldErrors['Subject ID'] = 'Subject ID already exists in the system';
            }
            break;

        case 'database':
            fieldErrors['system'] = 'Database error occurred';
            break;

        case 'system':
            fieldErrors['system'] = error.message || 'System error occurred';
            break;
    }

    return fieldErrors;
  }

  // Add method to handle course search
  onCourseSearch(event: Event, row: PreviewRow) {
    event.stopPropagation();
    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    this.courseSearchText[row.rowNumber] = searchText;

    this.filteredCourses[row.rowNumber] = this.courses.filter(course =>
        course.code?.toLowerCase().includes(searchText) ||
        course.title?.toLowerCase().includes(searchText)
    );
  }

  // Update toggleSubjectDropdown to handle course dropdowns
  toggleCourseDropdown(event: Event, row: PreviewRow) {
    event.stopPropagation();
    const rowNumber = row.rowNumber;

    // Calculate position before toggling
    const position = this.calculateDropdownPosition(event, rowNumber);
    this.dropdownPositions.set(rowNumber, position);

    // Initialize search and filtered courses for this row
    if (!this.courseSearchText[rowNumber]) {
      this.courseSearchText[rowNumber] = '';
      this.filteredCourses[rowNumber] = [...this.courses];
    }

    this.openCourseDropdowns[rowNumber] = !this.openCourseDropdowns[rowNumber];

    // Close other dropdowns
    Object.keys(this.openCourseDropdowns).forEach(key => {
      if (Number(key) !== rowNumber) {
        this.openCourseDropdowns[Number(key)] = false;
      }
    });
  }

  // Add method to check if course dropdown is open
  isCourseDropdownOpen(row: PreviewRow): boolean {
    return this.openCourseDropdowns[row.rowNumber] || false;
  }

  // Update selectCourse method
  selectCourse(row: PreviewRow, course: any, event: Event) {
    event.stopPropagation();

    if (!Array.isArray(row.courses)) {
        row.courses = [];
    }

    const index = row.courses.findIndex(c => c.id === course.id);
    if (index === -1) {
        // Add course if not already selected
        row.courses.push(course);
    } else {
        // Remove course if already selected
        row.courses.splice(index, 1);
    }

    // Update the Subject ID string
    const courseCodes = row.courses.map(c => c.code).join(', ');
    row['Subject ID'] = courseCodes;

    // Preserve existing email errors instead of running full validation
    const emailErrors = row.fieldErrors?.['Email'];
    this.validateRow(row);
    if (emailErrors) {
        row.fieldErrors = {
            ...row.fieldErrors,
            'Email': emailErrors
        };
        row.isValid = false;
        row.status = 'invalid';
    }
  }

  // Add method to check if course is selected
  isCourseSelected(row: PreviewRow, course: any): boolean {
    if (!Array.isArray(row.courses)) {
        return false;
    }
    return row.courses.some(c =>
        c.id === course.id ||
        (c.code && course.code && c.code.toLowerCase() === course.code.toLowerCase())
    );
  }

  // Add new properties for drag and drop
  private dragCounter = 0;
  isDragging = false;

  // Add drag and drop event handlers
  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragCounter++;
    if (this.dragCounter === 1) {
      this.isDragging = true;
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.isDragging = false;
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragging = false;
    this.dragCounter = 0;

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      const file = files[0];
      // Check if it's an Excel file
      if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel') {
        // Create a fake event object to reuse existing file handling logic
        const fakeEvent = {
          target: {
            files: [file]
          }
        };
        this.onFileSelected(fakeEvent);
      } else {
        this.toastrService.error('Please upload an Excel file');
      }
    }
  }

  // Add helper method to normalize headers
  private normalizeHeaders(data: any[]): any[] {
    if (data.length === 0) return data;

    const normalizedData = data.map(row => {
        const normalizedRow = {};
        Object.entries(row).forEach(([key, value]) => {
            // Normalize the key: remove spaces, special chars, and convert to lowercase
            const normalizedKey = key.toString()
                .toLowerCase()
                .replace(/[^a-z0-9]/g, '');  // Remove all non-alphanumeric chars

            // Get the standard headers based on registration type
            const standardHeaders = this.registrationType.isSubject ?
                STANDARD_SUBJECT_HEADERS :
                STANDARD_USER_HEADERS;

            // Find the matching standard header
            const standardHeader = Object.entries(standardHeaders)
                .find(([_, variants]) => variants.includes(normalizedKey))?.[0];

            // Use the standard header if found, otherwise use original key
            const properHeader = standardHeader || key;

            // Handle special cases (like Educator arrays)
            if (properHeader === 'Educator' && !value) {
                normalizedRow[properHeader] = [];
                normalizedRow['Educator_Name'] = [];
            } else if (properHeader === 'Subject Type' && (!value || value.toString().trim() === '')) {
                // Set default value for empty Subject Type
                normalizedRow[properHeader] = 'Elective';
            } else {
                normalizedRow[properHeader] = value;
            }
        });

        return normalizedRow;
    });

    return normalizedData;
  }

  // Add this method to remove educator from a row
  removeEducator(row: SubjectRegistrationRow, educatorToRemove: any, event?: Event) {
    // Stop event propagation if event is provided
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    if (!educatorToRemove) return;

    // Remove from educators array
    row.educators = row.educators.filter(e => e.id !== educatorToRemove.id);

    // Update Educator and Educator_Name arrays
    row['Educator'] = row.educators.map(e => e.indexId);
    row['Educator_Name'] = row.educators.map(e => e.name);

    // Validate row after removal
    this.validateRow(row, 'Educator');
  }

  removeCourse(row: PreviewRow, courseToRemove: any, event?: Event) {
    // Stop event propagation if event is provided
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }

    // Remove the course from the row's courses array
    row.courses = row.courses.filter(course => course.id !== courseToRemove.id);

    // Validate row after removal
    this.validateRow(row, 'Subject ID');
  }

  // Add this helper method for courses, similar to educators
  getDisplayEducators(educators: string[]): { shown: string[], remaining: number } {
    if (!educators || educators.length <= 3) {
      return { shown: educators || [], remaining: 0 };
    }

    return {
      shown: educators.slice(0, 3),
      remaining: educators.length - 3
    };
  }

  getDisplayCourses(courses: any[]): { shown: any[], remaining: number } {
    if (!courses || courses.length <= 3) {
      return { shown: courses || [], remaining: 0 };
    }

    return {
      shown: courses.slice(0, 3),
      remaining: courses.length - 3
    };
  }

  // Add this helper method to find educator by name
  findEducatorByName(row: SubjectRegistrationRow, educatorName: string): any {
    return row.educators?.find(e => e.name === educatorName);
  }

  // Add this method to calculate dropdown position
  private calculateDropdownPosition(event: Event, rowNumber: number): 'up' | 'down' {
    const trigger = event.currentTarget as HTMLElement;
    const triggerRect = trigger.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const spaceBelow = viewportHeight - triggerRect.bottom;
    const spaceAbove = triggerRect.top;
    const dropdownHeight = 400; // Approximate height of dropdown

    // If there's more space above than below and space below is less than dropdown height
    if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
      return 'up';
    }
    return 'down';
  }

  // Add this method to get dropdown position
  getDropdownPosition(rowNumber: number): 'up' | 'down' {
    return this.dropdownPositions.get(rowNumber) || 'down';
  }

  // Add this getter to the component class
  get registrationTypeKey(): string {
    if (this.registrationType.isSubject) return 'subject';
    if (this.registrationType.isProfessor) return 'educator';
    return 'student';
  }

  // Update the paginatedData getter with error handling
  get paginatedData(): any[] {
    try {
        const filteredData = this.showOnlyErrors ?
            this.previewData.filter(row => !row.isValid) :
            this.previewData;

        if (!Array.isArray(filteredData)) {
            console.error('filteredData is not an array:', filteredData);
            return [];
        }

        const start = (this.currentPage - 1) * this.pageSize;
        const end = start + this.pageSize;
        return filteredData.slice(start, end);
    } catch (error) {
        console.error('Error in paginatedData getter:', error);
        return [];
    }
  }

  getMoreInfoLink(): string {
    if (this.registrationType.isSubject) {
      return 'https://support.inspera.com/hc/en-us/articles/23845596453789-Bulk-Upload-Functionality#01JGXB8K4FCGYT7MEKSC711WJH';
    } else if (this.registrationType.isProfessor) {
      return 'https://support.inspera.com/hc/en-us/articles/23845596453789-Bulk-Upload-Functionality#01JGXA30ZM2JE01PSEC2SP9JWS';
    } else {
      return 'https://support.inspera.com/hc/en-us/articles/23845596453789-Bulk-Upload-Functionality#h_01JFSM5JN58GSRQTNCTTZAFBZW';
    }
  }
}
