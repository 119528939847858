import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import * as authActions from '../../authModule/state/actions/authentication.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-expand-view',
  templateUrl: './expand-view.component.html',
  styleUrls: ['./expand-view.component.scss']
})
export class ExpandViewComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(data=>{
      if(data.userId && data.token && data.id){
         localStorage.setItem('ACCESS_TOKEN', JSON.stringify(data.token));
         this.userService.userInfo(data.userId, data.token).subscribe(response=>{
            authActions.loginSuccess({ user: response.user })
            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('role', JSON.stringify(response.user.roleId));
            localStorage.setItem('websiteLanguage', response.user.language);
            const profile = response.user.roleId === 9 ? 'student' : response.user.roleId === 10 ? 'professor' : 'administrator';
          //  window.location.replace(`${environment.currentUrl}${profile}/submission/${data.id}/report/v2/?expanded=true`);
          let url = `${environment.externalReport}/submission/${data.id}/report/v2/${data.token}`;
          window.open(url, '_blank');
         })
      }
    })
  }

}
