// *** Angular
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

// *** Actions
import * as subjectsActions from '../../subjects/state/action/units-statistics-subject.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';

// *** Selectors
import { getSubjectsStatisticsDetails } from '../../subjects/state/selector/units-statistics-subject.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { LanguageService } from 'src/app/services/language.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CsvExportService } from 'src/app/services/csv-export.service';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import { PdfExportService } from 'src/app/services/pdf-export.service';
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';
@Component({
   selector: 'app-subjects-statistics',
   templateUrl: './subjects-statistics.component.html',
   styleUrls: ['./subjects-statistics.component.scss'],
})
export class SubjectsStatisticsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId to filter submissions of assignments within specific department.
    */
   departmentId = '-1';
   /**
    * Variabel used to store statistics of professors
    */
   professors;
   /**
    * Variable is used to store professor id to filter subjects.
    */
   professor = '-1';
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable used to store subjects of institution
    */
   subjects;
   /**
    * Variable used to store professor id that used to expand specific row on HTML
    */
   subjectId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Representing title model
    */
   subjectName = '';
   /**
    * Variable used to store student name that used to filter subjects
    */
   studentName = '';
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variabel used to store total number of subjects without pagination
    */
   totalSubjects;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   getFiltersStateDetails$: any;
   getInstitutionProfessorsStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getSubjectsStatisticsDetails$: any;
   languageName: string;
   @ViewChild('contentToCapture') contentToCapture: ElementRef;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private router: Router,
      private languageService: LanguageService,
      private csvExportService: CsvExportService,
      private excelExportService: ExcelExportService,
      private pdfExportService: PdfExportService,
      private spinner: NgxSpinnerService,
      private administratorStatisticsService: AdministratorStatisticsService
   ) { }
   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getSubjectsStatisticsDetails$.unsubscribe();
   }

   getFiltersState = true;
   ngOnInit(): void {
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.subjectsFilters.faculty != null) {
               this.facultyId = data.subjectsFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (departmentsDetails) => {
                        this.departments = departmentsDetails.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.subjectsFilters.professor != null) {
               this.professor = data.subjectsFilters.professor;
            }
            if (data.subjectsFilters.department != null) {
               this.departmentId = data.subjectsFilters.department;
            }
            if (data.subjectsFilters.student != null) {
               this.studentName = data.subjectsFilters.student;
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            professor: this.professor == '-1' ? undefined : this.professor,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
         })
      );

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data.professors !== null && this.professors == undefined) {
               this.professors = data.professors;
            }
         });

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.getSubjectsStatisticsDetails$ = this.store
         .select(getSubjectsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.subjects = data.subjectsStatistics;
               this.totalSubjects = data.subjectsNumber;

               //    if (this.subjects && this.subjects.length > 0) {
               //     const firstSubject = this.subjects[0];
               //     const languageCode = firstSubject.language[0];
               //     this.languageName = this.languageService.findLanguageName(languageCode);
               //   }
            }
         });
   }

   setLanguageName(lang) {
      if (lang == '-') {
         return '-'
      }
      return this.languageService.findLanguageName(lang);
   }
   /**
    * Function is used to filter by title
    */

   titleFilter() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.store.dispatch(
            subjectsActions.loadUnitsStatisticsSubjects({
               page: this.page,
               title: this.subjectName,
               studentName: this.studentName,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               professor: this.professor == '-1' ? undefined : this.professor,
            })
         );
      }, time);
   }
   /**
    * Function is used to filter by selected faculty
    */
   selectedFaculty() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.departmentId = '-1';
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter by selected department
    */
   selectedDepartment() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }
   /**
    * Function is used to filter by selected professor
    */
   filterByProfessor() {
      this.getFiltersState = false;
      if (this.professor == 'All') {
         this.professor = '-1';
      }
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   /**
    * Function is used to exapnd information
    * @param professorId
    */
   expand(subjectId) {
      this.subjectId = subjectId;
      if (this.expandInfo !== subjectId) {
         this.expandInfo = subjectId;
      } else {
         this.expandInfo = -1;
      }
   }
   /**
    * Function is used when clicked on number of assignments within subject and will redirect to assignments with filter activated to clicked subject.
    * @param professorId
    */
   filterAssignment(subjectName) {
      this.store.dispatch(
         filtersActions.assignmentFilters({
            subject: subjectName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/academic-units/assignments'
      );
   }
   /**
    * Function is used when clicked on number of professors within subject and will redirect to professors with filter activated to clicked subject.
    * @param professorId
    */
   filterProfessors(subjectName) {
      this.store.dispatch(
         filtersActions.professorsFilters({
            subject: subjectName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/users/professors'
      );
   }

   /**
    * Function is used when clicked on number of submissions within subject and will redirect to assignments submissions with filter activated to clicked subject.
    * @param professorId
    */
   filterSubmissions(subjectId) {
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: subjectId,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/assignment-submissions'
      );
   }

   /**
    * Function is used when clicked on number of student within subject and will redirect to students with filter activated to clicked subject.
    * @param professorId
    */
   filterStudents(subjectName) {
      this.store.dispatch(
         filtersActions.studentsFilters({
            subject: subjectName,
         })
      );
      this.router.navigateByUrl('/administrator/statistics/users/students');
   }

   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.professor = '-1';
      this.subjectName = '';
      this.studentName = '';
      this.store.dispatch(
         filtersActions.subjectsFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            student: this.studentName,
         })
      );
      this.page = 1;
      this.store.dispatch(
         subjectsActions.loadUnitsStatisticsSubjects({
            page: this.page,
            title: this.subjectName,
            studentName: this.studentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   prepareExportData(data) {
      let rows = [
         ["Subjects"],
         [""],
         [
            "Subject name",
            "Number of educators",
            "Number of students",
            "Number of Assignments",
            "Number of Submissions",
            "Avg. of document language",
            "Avg. of translated language",
            "Most used language",
            "High similarity submissions",
            "Medium similarity submissions",
            "Low similarity submissions",
            "Ratios",
            "Educator-to-Student",
            "Educator-to-Submissions",
            "Student-to-Submissions",
         ],
      ]

      data.subjectsDetails.forEach(sub => {
         rows.push([
            sub.name,
            sub.professors,
            sub.students,
            sub.assignments,
            sub.submissions._count,
            typeof sub.submissions._avg.originalPercentage === 'number' ? sub.submissions._avg.originalPercentage.toFixed(2) : '-',
            typeof sub.submissions._avg.translatedPercentage === 'number' ? sub.submissions._avg.translatedPercentage.toFixed(2) : '-',
            this.setLanguageName(sub.language ? sub.language[0] : '-'),
            sub.highSimilaritySubmissions,
            sub.mediumSimilaritySubmissions,
            sub.lowSimilaritySubmissions,
            '',
            `${sub.professors}:${sub.students}`,
            `${sub.professors}:${sub.submissions._count}`,
            `${sub.students}:${sub.submissions._count}`,
         ]);
      });

      return rows
   }

   exportToCSV() {
      this.spinner.show();
      this.administratorStatisticsService.academicUnitsSubjectsStatistics(
         -1,
         this.subjectName,
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId == '-1' ? undefined : this.departmentId,
         this.professor == '-1' ? undefined : this.professor,
         this.studentName,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.csvExportService.exportToCSV(rows, 'Academic Units (Total subjects) - Subjects Report');
         this.spinner.hide();
      })
   }

   exportToExcel() {
      this.spinner.show();
      this.administratorStatisticsService.academicUnitsSubjectsStatistics(
         -1,
         this.subjectName,
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId == '-1' ? undefined : this.departmentId,
         this.professor == '-1' ? undefined : this.professor,
         this.studentName,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.excelExportService.exportToExcel(rows, 'Academic Units (Total subjects) - Subjects Report');
         this.spinner.hide();
      })
   }

   exportToPDF() {
      this.spinner.show()
      if (this.contentToCapture) {
         this.pdfExportService.captureAndExportPDF(this.contentToCapture.nativeElement, 'Academic Units (Total subjects) - Subjects Report.pdf');
         this.spinner.hide()
      } else {
         console.error('Content to capture is not available yet.');
         this.spinner.hide()
      }
   }
}
