import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
// import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { ReportService } from '../../../services/report.service';
import { ActivatedRoute } from '@angular/router';
// import { SpinnerService } from '../../../services/spinner.service';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogContent
} from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { SubmissionsService } from 'src/app/services/submissions.service';
import swal from 'sweetalert2';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';
import * as reportActions from '../../state/actions/report.actions';

@Component({
  selector: 'app-manipulation-actions',
  templateUrl: './manipulations.component.html',
  styleUrls: ['./manipulations.component.scss'],
})
export class ManipulationActions implements OnInit {
  result: any;
  formattedText!: string;
  submissionId;

   /**
    * Constructor Component
    * @param store
    * @param dialogRef
    * @param data
    */
   constructor(
    public dialogRef: MatDialogRef<ManipulationActions>,
    public dialog: MatDialog,
    private languageService: LanguageService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private submissionsService: SubmissionsService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    /**
     * Used to pass data in component through dialog
     */
    @Inject(MAT_DIALOG_DATA) public data: any
 ) {
  this.result = data.sentence;
  this.submissionId = data.submissionId
 }

  ngOnInit() {
    this.formattedText = this.formatTextWithWhiteCharacters(this.result.text, this.result.characters);
  }

  formatTextWithWhiteCharacters(text: string, characters: any[]): string {
    // Create an array from the text for easier manipulation
    let textArray = text.split('');
    let nonSpaceIndex = 0;

    for (let i = 0; i < textArray.length; i++) {
      if (textArray[i] !== ' ') {
        characters.forEach(char => {
          if (char.isWhite && char.index === nonSpaceIndex && textArray[i] === char.character) {
            if (this.result.whitecharactersExcluded) {
              textArray[i] = `<span class="whiteChar excluded" style="background: rgb(93, 174, 255) !important; color: #000 !important">${textArray[i]}</span>`;
            } else {
              textArray[i] = `<span class="whiteChar" style="background: rgb(93, 174, 255) !important; color: #000 !important">${textArray[i]}</span>`;
            }
          }
        });
        nonSpaceIndex++;
      }
    }

    // Join the array back into a string
    return textArray.join('');
  }

  toggleSentence(id) {
    swal.fire({
      title: this.translate.instant('notifications.are_you_sure'),
      text: this.result.whitecharactersExcluded ? 'You are about to include this sentence' : 'You are about to exclude this sentence',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.submissionsService.toggleWhiteCharacterSentences(this.submissionId, id, !this.result.whitecharactersExcluded)
        .pipe()
        .subscribe((data: any) => {
          console.log(data, 'data');
          if (data) {
            this.store.dispatch(
              reportActions.getSubmissionPlag({
                presignedUrl: data.presignedUrlJson,
              })
            )
          }
          this.spinner.hide();
          this.dialogRef.close();
          this.cdr.detectChanges();
        });
      } else if (result.isDismissed) {
        swal.fire(this.result.whitecharactersExcluded ? 'Sentence is not included' : 'Sentence is not excluded', '', 'info');
      }
    });
  }

  cancel() {
    this.dialog.closeAll();
  }
}
