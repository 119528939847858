<ng-container *ngIf="this.uploaded == 0">
   <div class="upload">
      <main>
         <!-- <p>{{ 'app.upload.click_on_the_select_file_button_and_choose_document' | translate }}.</p> -->
         <div (click)="upload()" style="cursor: pointer">
            <img src="../../../../assets/report_v2/upload_v2.svg" alt="" />
            <h6><a class="clickable blue underline">{{ 'new_entries.click_to_upload' | translate }}</a> {{ 'login.or' | translate }} {{ 'app.upload.drag_files' | translate }}</h6>
         </div>
         <!-- <a (click)="upload()" class="submit">{{
            'general.upload' | translate
         }}</a> -->
      </main>
   </div>
   <input
      class="dropzone"
      type="file"
      name=""
      id=""
      (change)="onFileSelected($event)"
      style="display: none"
   />
   <!-- <div class="upload">
    <main>
      <div>

        <ngx-file-drop class="dropzone" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
          (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div (click)="openFileSelector()" class="contentOnFileDrop">
              <img src="../../../../assets/report_v2/upload_v2.svg" alt="" />
              <h6><a class="clickable blue underline">{{ 'new_entries.click_to_upload' | translate }}</a> {{ 'login.or' | translate }} {{ 'app.upload.drag_files' | translate }}</h6>
            </div>
          </ng-template>
        </ngx-file-drop>

      </div>
    </main>
  </div> -->
</ng-container>
<ng-container *ngIf="this.uploaded == 1">
   <div class="file-uploads">
      <main>
         <div>
            <form [formGroup]="uploadForm">
               <h5 class="bold">
                  {{ 'individual.upload.upload_form' | translate }}
               </h5>
               <h5>{{ 'individual.upload.document_title' | translate }}</h5>
               <input type="text" formControlName="documentTitle" />
               <div
                  *ngIf="
                      isValid(form.documentTitle)
                  "
                  class="invalid"
               >
                  <div *ngIf="form.documentTitle.errors.required">
                     {{ 'app.upload.document_title' | translate }} {{ 'general.end_date' | translate }}*
                  </div>
                  <div *ngIf="form.documentTitle.errors.minlength">
                     <div>{{ 'individual.upload.document_title_must_be_at_least_5' | translate }}*</div>
                  </div>
                  <div *ngIf="form.documentTitle.errors.maxlength">
                     <div>
                        Document title can't be more than 190 characters*
                     </div>
                  </div>
               </div>

               <h5>{{ 'general.name_and_surname' | translate }}</h5>
               <input  type="text" formControlName="fullName" />
               <div
                  *ngIf=" isValid(form.fullName)"
                  class="invalid"
               >
                  <div *ngIf="form.fullName.errors.required">
                     {{ 'general.full_name' | translate }} {{ 'aria-labels.is_required' | translate }}*
                  </div>
               </div>
               <h5>{{ 'individual.upload.date_of_upload' | translate }}</h5>
               <input formControlName="dateOfUpload" disabled type="text" />
               <h5>{{ 'individual.upload.document_size' | translate }}</h5>
               <input formControlName="documentSize" disabled type="text" />

               <h5>{{ isCodeFile ? 'Line Count' : ('individual.upload.word_count' | translate) }}</h5>
               <ng-container *ngIf="!isCodeFile">
                 <input formControlName="documentWordCount" disabled type="text" />
                 <div *ngIf="!this.allowToSubmitDocument" class="invalid">
                    <div>
                       {{ 'individual.upload.word_limit_error_message' | translate:{ maxWords: this.submission_word_limit } }}*
                    </div>
                 </div>
               </ng-container>
               <ng-container *ngIf="isCodeFile">
                <input type="text" [value]="lineCount" disabled>
               </ng-container>

               <h5 *ngIf="user.roleId == 2">{{ 'general.credits_to_be_charged' | translate }}:</h5>
               <input *ngIf="user.roleId == 2" formControlName="documentCredits" disabled type="text" />
               <h5
                  *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && thesisId                  "
               >
                  {{ 'general.thesis' | translate }}
               </h5>
               <h5 *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && assignmentId">
                  {{ 'general.assignment' | translate }}
               </h5>
               <h5
                  *ngIf="
                  this.user.roleId !== 5 && this.user.roleId !== 9"
               >
                  {{ 'individual.upload.document_language' | translate }}
               </h5>
               <div *ngIf="this.user.roleId !== 5 && this.user.roleId !== 9 && !this.isCodeFile" style="position: relative">
                  <ng-container *ngIf="this.disableField === true">
                     <select
                        (change)="selectLanguage($event.target.value)"
                        formControlName="documentLanguage"
                        disabled
                        class="border"
                        name=""
                        id=""
                     >
                        <option value="-1" selected>{{ 'general.select_language' | translate }}</option>
                        <option
                           *ngFor="let language of languages"
                           value="{{ language.value }}"
                        >
                           {{ language.text }}
                        </option>
                        <span
                           ><img src="../../assets/images/Path 26.png" alt=""
                        /></span>
                     </select>
                  </ng-container>
                  <ng-container *ngIf="this.disableField === false">
                     <select
                        (change)="selectLanguage($event.target.value)"
                        formControlName="documentLanguage"
                        class="border"
                        name=""
                        id=""
                     >
                        <option value="-1" selected>{{ 'general.select_language' | translate }}</option>
                        <option
                           *ngFor="let language of languages"
                           value="{{ language.value }}"
                        >
                           {{ language.text }}
                        </option>
                        <span
                           ><img src="../../assets/images/Path 26.png" alt=""
                        /></span>
                     </select>
                  </ng-container>
                  <span
                     ><img src="../../assets/images/Path 26.png" alt=""
                  /></span>
               </div>
               <div
                  *ngIf="isValid(form.documentLanguage) && !this.isCodeFile"
                  class="invalid"
                  for
               >
                  <div
                     *ngIf="
                        form.documentLanguage.errors.required ||
                        form.documentLanguage.value == 'undefined'
                     "
                  >
                     {{ 'report.document_language' | translate }} {{ 'aria-labels.is_required' | translate }}*
                  </div>
               </div>

               <h5
                  *ngIf="user.roleId !== 5 && user.roleId !== 9"
               >
                  {{ 'individual.upload.translate_document' | translate }}
               </h5>
               <input *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && assignmentId" [value]="assignmentName" disabled type="text">
               <input *ngIf="(this.user.roleId == 5 || this.user.roleId == 9) && thesisId" [value]="thesisName" disabled type="text">

               <div *ngIf="this.user.roleId !== 5 && this.user.roleId !== 9" style="position: relative">
                  <ng-container *ngIf="this.disableField === true">
                     <select
                        (change)="selectTranslatedLanguage($event.target.value)"
                        formControlName="documentTranslatedLanguage"
                        class="border"
                        disabled
                        name=""
                        id=""
                     >
                        <option value="-1" selected>{{ 'general.none' | translate }}</option>
                        <option
                           *ngFor="let language of languages"
                           value="{{ language.value }}"
                        >
                           {{ language.text }}
                        </option>
                        <span
                           ><img src="../../assets/images/Path 26.png" alt=""
                        /></span>
                     </select>
                  </ng-container>
                  <ng-container *ngIf="this.disableField === false">
                     <select
                        (change)="selectTranslatedLanguage($event.target.value)"
                        formControlName="documentTranslatedLanguage"
                        class="border"
                        name=""
                        id=""
                     >
                        <option value="-1" selected>{{ 'general.none' | translate }}</option>
                        <option
                           *ngFor="let language of languages"
                           value="{{ language.value }}"
                        >
                           {{ language.text }}
                        </option>
                        <span
                           ><img src="../../assets/images/Path 26.png" alt=""
                        /></span>
                     </select>
                  </ng-container>
                  <span
                     ><img src="../../assets/images/Path 26.png" alt=""
                  /></span>
               </div>
               <h5 *ngIf="this.user.roleId !== 5 && this.user.roleId !== 9">{{ 'general.enable_ai_prediction' | translate }}:</h5>
               <div *ngIf="this.user.roleId !== 5 && this.user.roleId !== 9">
                  <label class="switch">
                     <input
                        formControlName="aiDetection"
                        type="checkbox"
                     />

                     <span class="slider round"></span>
                  </label>
               </div>

               <!-- <div>
                  <h3>{{ 'new_entries.contextual_similarity' | translate }}</h3>
                  <div>
                     <label class="switch">
                        <input formControlName="contextualSimilarity" type="checkbox" />
                        <span class="slider round"></span>
                     </label>
                  </div>
               </div> -->

               <!-- <div>
                  <h3>{{ 'new_entries.enable_thresholds' | translate }}</h3>
                  <div>
                     <label class="switch">
                        <input formControlName="enableThresholds" type="checkbox" />
                        <span class="slider round"></span>
                     </label>

                     <div *ngIf="uploadForm.controls.enableThresholds.value">
                        <div style="display: flex; gap: 5px">
                           <div class="threshold-input">
                              <label>{{ 'new_entries.exact_match' | translate }}</label>
                              <input formControlName="exactMatchThreshold" type="number" min="0" max="100" />
                           </div>
                           <div class="threshold-input">
                              <label>{{ 'new_entries.altered_text' | translate }}</label>
                              <input formControlName="alteredTextThreshold" type="number" min="0" max="100" />
                           </div>
                           <div class="threshold-input">
                              <label>{{ 'new_entries.contextual_similarity' | translate }}</label>
                              <input formControlName="contextualSimilaritiesThreshold" type="number" min="0" max="100" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div> -->

                  <!-- <div>
                  <h3>{{ 'new_entries.include_urls' | translate }}</h3>
                  <div class="textarea-container">
                     <mat-chip-list #chipList>
                        <mat-chip *ngFor="let url of includeUrlsArray; let i = index" [selectable]="true" [removable]="true"
                           (removed)="removeUrl(i, 'include')" class="trim-chip">
                           {{url}}
                           <mat-icon matChipRemove (click)="removeUrl(i, 'include')">X</mat-icon>
                        </mat-chip>
                        <input [placeholder]="('new_entries.new_url' | translate) + '...'" [matChipInputFor]="chipList"
                              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                              (keydown.enter)="addUrlOnEnter($event, 'include'); $event.preventDefault();" class="chip-input" />
                     </mat-chip-list>
                  </div>
               </div> -->

               <!-- <div>
                  <h3>{{ 'new_entries.exclude_urls' | translate }}</h3>
                  <div class="textarea-container">
                     <mat-chip-list #chipList>
                        <mat-chip *ngFor="let url of excludeUrlsArray; let i = index" [selectable]="true" [removable]="true"
                           (removed)="removeUrl(i, 'exclude')" class="trim-chip">
                           {{url}}
                           <mat-icon matChipRemove (click)="removeUrl(i, 'exclude')">X</mat-icon>
                        </mat-chip>
                        <input [placeholder]="('new_entries.new_url' | translate) + '...'" [matChipInputFor]="chipList"
                              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                              (keydown.enter)="addUrlOnEnter($event, 'exclude'); $event.preventDefault();" class="chip-input" />
                     </mat-chip-list>
                  </div>
               </div> -->


               <div
                  class="advanced_options"
                  *ngIf="this.user.institutionId === null || user.roleId == 4 || user.roleId == 10"
               >
                  <h4 (click)="advancedOptions()">
                     Advanced options<img
                        [ngClass]="{ expand: advancedOptionsIndex == true }"
                        src="../../assets/images/Path 26.png"
                        alt=""
                     />
                  </h4>

                  <div class="flex add_to_archive">
                     <input
                        type="checkbox"
                        id="add_to_archive"
                        value="{{ archive }}"
                        name="add_to_archive"
                        [(ngModel)]="archive"
                        (click)="archiveDocument()"
                        [ngModelOptions]="{ standalone: true }"
                     />
                     <label for="add_to_archive"></label>
                     <label for="">
                        {{ 'new_entries.add_document_to_archive' | translate }}<i
                           class="fas fa-info-circle"
                           matTooltipPosition="after"
                           matTooltipClass="tooltipCustomCss"
                           matTooltip="If you add this document to archive,all uploaded
                            documents will search for plagiarism from this
                            document"
                        ></i>
                     </label>
                  </div>
                  <div *ngIf="advancedOptionsIndex == true">
                     <div>
                        <label for="">
                           <h4>{{ 'general.choose_third_party_libraries' | translate }}</h4>
                           <i
                              style="margin-left: 1%"
                              matTooltipPosition="after"
                              class="fas fa-info-circle"
                              matTooltipClass="tooltipCustomCss"
                              matTooltip="Cross-checking your document with third-party libraries and archives may delay checking your document for up to several hours. Please be aware!"
                           ></i>
                        </label>
                        <div class="">
                           <div
                              *ngFor="
                                 let library of thirdPartyLib;
                                 let i = index
                              "
                           >
                              <input
                                 type="checkbox"
                                 id="library{{ i }}"
                                 [value]="library.description"
                                 [checked]="library.isChecked"
                                 name="library{{ i }}"
                                 (change)="onCheckboxChange($event)"
                              />
                              <label for="library{{ i }}"
                                 >{{ library.description }}
                                 <i
                                    style="margin-left: 1%"
                                    class="fas fa-info-circle"
                                    matTooltip="{{ library.information }}"
                                    matTooltipPosition="after"
                                    matTooltipClass="tooltipCustomCss"
                                 ></i
                              ></label>
                           </div>
                        </div>
                     </div>
                     <div>
                        <h4>{{ 'general.choose_citation' | translate }}</h4>
                        <div style="position: relative">
                           <select
                              (change)="selectCitationStyle($event)"
                              [(ngModel)]="citationStyle"
                              class="border"
                              name=""
                              id=""
                              [ngModelOptions]="{ standalone: true }"
                           >
                              <option value="" selected>
                                 {{ 'new_entries.select_citation_style' | translate }}
                              </option>
                              <optgroup [label]="'new_entries.popular_formats' | translate">
                                 <option value="AMA">{{ 'general.ama' | translate }}</option>
                                 <option value="APA(6th edition)">
                                    {{ 'other.apa_6th_edition' | translate }}
                                 </option>
                                 <option value="APA(7th edition)">
                                    {{ 'other.apa_7th_edition' | translate }}
                                 </option>
                                 <option value="Harvard">{{ 'general.harvard' | translate }}</option>
                                 <option value="IEEE">{{ 'general.ieee' | translate }}</option>
                              </optgroup>
                              <optgroup [label]="'new_entries.others' | translate">
                                 <option value="OSCOLA">{{ 'general.oscola' | translate }}</option>
                                 <option value="MHRA(3rd edition)">
                                    {{ 'other.mhra_third_edition' | translate }}
                                 </option>
                                 <option value="Vancouver">{{ 'general.vancouver' | translate }}</option>
                              </optgroup>
                           </select>
                           <span class="chevron-icon"
                              ><img
                                 src="../../assets/images/Path 26.png"
                                 alt=""
                           /></span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="center">
                  <button
                     [disabled]="!this.allowToSubmitDocument && !this.isCodeFile"
                     class="submit"
                     (click)="createSubmission()"
                     [ngClass]="{ disabled: !this.allowToSubmitDocument && !this.isCodeFile }"
                  >
                     {{ 'general.submit' | translate }}
                  </button>

                  <!-- <button
                     *ngIf="uploadForm.invalid"
                     [ngClass]="{ disable: uploadForm.invalid }"
                     (click)="showFormMessages()"
                     class="submit"
                  >
                     {{ 'general.submit' | translate }}
                  </button> -->

                  <!-- <ng-container *ngIf="allowToSubmitDocument == false">
                      <button
                         *ngIf="!uploadForm.invalid"
                         [ngClass]="{ disable: uploadForm.invalid }"
                         class="submit"
                         (click)="showAlertMessage()"
                      >
                         {{ 'general.submit' | translate }}
                      </button>

                      <button
                         *ngIf="uploadForm.invalid"
                         [ngClass]="{ disable: uploadForm.invalid }"
                         (click)="showFormMessages()"
                         class="submit"
                      >
                         {{ 'general.submit' | translate }}
                      </button>
                   </ng-container> -->
               </div>
            </form>
         </div>
         <app-code-preview [content]="currentFileContent" [language]="codeLanguage" *ngIf="isCodeFile && currentFileContent"></app-code-preview>
         <div class="file" *ngIf="!isCodeFile">
            <pdf-viewer
               id="viewer"
               [src]="this.previewSrc"
               [fit-to-page]="true"
               style="
                  display: block;
                  overflow: auto;
                  height: 650px;
                  scroll-behavior: smooth;
                  border-radius: 10px;
               "
            ></pdf-viewer>

            <div
               style="
                  background: #eee;
                  padding-bottom: 1%;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
               "
            >
               <h6>{{ 'individual.upload.document_preview' | translate }}</h6>
               <p>{{ 'individual.upload.check_your_document' | translate }}</p>
            </div>
         </div>
      </main>
   </div>
</ng-container>
