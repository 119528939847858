import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Keycloak from 'keycloak-js';
import { BehaviorSubject, Observable } from 'rxjs';
import * as authActions from './../authModule/state/actions/authentication.actions';

@Injectable({
  providedIn: 'root'
})
export class KeycloakAuthService implements OnDestroy {
  private keycloakAuth: Keycloak.KeycloakInstance;
  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userProfile: BehaviorSubject<Keycloak.KeycloakProfile | null> = new BehaviorSubject<Keycloak.KeycloakProfile | null>(null);
  private readonly redirectUri = window.location.origin + '/auth-callback';
  constructor(private store: Store) {
    this.keycloakAuth = Keycloak({
      url: 'https://keycloak-originality.inspera.com',
      realm: 'master',
      clientId: 'originality-dev'
    });
  }

  async initializeKeycloak(idpHint?: string): Promise<boolean> {
    console.log('Initializing Keycloak');
    try {
      const authenticated = await this.keycloakAuth.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        silentCheckSsoFallback: false,
        checkLoginIframe: false,
        pkceMethod: 'S256',
        useNonce: false,
        enableLogging: true,
        flow: 'standard',
        responseMode: 'fragment'
      });
      console.log('Keycloak initialized:', authenticated);
      if (authenticated) {
        await this.handleAuthentication();
      } else {
        this.login(idpHint);
      }

      return authenticated;
    } catch (error) {
      console.error('Failed to initialize Keycloak', error);
      return false;
    }
  }

  async handleAuthCallback(): Promise<boolean> {
    try {
      const authenticated = await this.keycloakAuth.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        silentCheckSsoFallback: false,
        checkLoginIframe: false,
        pkceMethod: 'S256',
        useNonce: false,
        enableLogging: true,
        flow: 'standard',
        responseMode: 'fragment'
      });

      if (authenticated) {
        await this.handleAuthentication();
        return true;
      } else {
        console.error('Authentication failed');
        return false;
      }
    } catch (error) {
      console.error('Error handling auth callback:', error);
      return false;
    }
  }

  private async handleAuthentication(): Promise<void> {
    this.isAuthenticated.next(true);
    await this.loadUserProfile();
    const userProfile = this.userProfile.getValue();
    const email = userProfile?.email || '';
    let loginOptions = JSON.parse(localStorage.getItem('login-options'))
    if(!loginOptions){
      loginOptions = {}
    }
    loginOptions.fullName = userProfile?.firstName + ' ' + userProfile?.lastName;
    if (email) {
      await this.loginToBackend(email, loginOptions);
    }
  }

  login(idpHint?: string, redirectUri?: string): void {
    let loginUrl = this.keycloakAuth.createLoginUrl({
      redirectUri: redirectUri || this.redirectUri,
    });

    if (idpHint) {
      loginUrl += `&kc_idp_hint=${encodeURIComponent(idpHint)}`;
    }

    window.location.href = loginUrl;
  }

  private async loginToBackend(email: string, idpInstitution: {institutionName: string, role: string, fullName: string}): Promise<void> {
    try {
      this.store.dispatch(authActions.login({ email, password: '', isIdpLogin: true, institutionName: idpInstitution.institutionName, role: idpInstitution.role, fullName: idpInstitution.fullName}));
    } catch (error) {
      console.error('Backend login error:', error);
      throw error;
    }
  }

  logout(redirectUri: string): void {
    const idToken = this.keycloakAuth.idToken;
    const encodedRedirectUri = encodeURIComponent(redirectUri);
    const logoutUrl = `${this.keycloakAuth.authServerUrl}/realms/${this.keycloakAuth.realm}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodedRedirectUri}`;
    window.location.href = logoutUrl;
  }

  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  getUserProfile(): Observable<Keycloak.KeycloakProfile | null> {
    return this.userProfile.asObservable();
  }

  private async loadUserProfile(): Promise<void> {
    try {
      if (!this.keycloakAuth.authenticated) {
        throw new Error('Cannot load user profile - not authenticated');
      }

      const profile = await this.keycloakAuth.loadUserProfile();
      console.log('Loaded user profile:', profile);
      this.userProfile.next(profile);
    } catch (error) {
      console.error('Error loading user profile:', error);
      this.userProfile.next(null);
      throw error;
    }
  }

  ngOnDestroy(): void {

  }

  getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this.keycloakAuth;
  }
}