import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
   selector: 'app-code-similarity-check',
   templateUrl: './code-similarity-check.component.html',
   styleUrls: ['./code-similarity-check.component.scss']
})
export class CodeSimilarityCheckComponent {
  type: string;
  constructor(private dialogRef: MatDialogRef<CodeSimilarityCheckComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.type = data.type;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  returnYes() {
    this.dialogRef.close(true);
  }

  returnNo() {
    this.dialogRef.close(false);
  }
}
