<div class="container" *ngIf="data">
  <div class="header">
    <h2> {{(this.data.isExcluded && this.isOriginal ||  this.translatedObj?.isExcluded && this.isTranslated? 'new_entries.excluded_sentence_information' : 'report.sentence_information') | translate}}</h2>
    <button class="text-button" aria-label="Text Action Button" (click)="clearSentenceInformation()">X</button>
  </div>
  <div class="content">
    <div *ngIf="data.sentence || data.text" class="sentence-section">
      <h3>{{ 'report.sentence' | translate }}</h3>
      <p>{{ data.text }}</p>
      <div  *ngIf="(this.analysisType == 'none' && this.isOriginal) || (this.analysisTy<pe !== 'none' && this.isOriginal) || (this.analysisType !== 'none' && !this.isOriginal)" style="padding-top: 4%; padding-bottom: 2%;">
        <h3 *ngIf="isTranslated">{{ 'new_entries.translated_sentence' | translate }}</h3>
        <p *ngIf="isTranslated">{{ this.translatedObj?.text }}</p>
      </div>
      <div class="buttons" *ngIf="analysisType === 'none'">
        <button *ngIf="data.isOriginal" class="btn language-btn" (click)="activeTab('original')" [ngClass]="{'activeOriginal': isOriginal}">
          <img src="./../../../../../assets/report_v2/original_language_icon.svg" alt="Original Language Icon">
          <p>{{ 'app.original_language' | translate }}</p>
        </button>
        <button *ngIf="data.isTranslated" class="btn language-btn" (click)="activeTab('translated')" [ngClass]="{'activeTranslate': isTranslated}">
          <img src="./../../../../../assets/report_v2/translated_language_icon.svg" alt="Translated Language Icon">
          <p>{{ 'app.upload.translated_language' | translate }}</p>
        </button>
        <button *ngIf="data.isAi" class="btn language-btn" (click)="activeTab('ai')" [ngClass]="{'activeAi': isAi}">
          <img src="./../../../../../assets/report_v2/translated_language_icon.svg" alt="Translated Language Icon">
          <p>{{ 'general.ai_authorship' | translate }}</p>
        </button>
        <button *ngIf="data.isManipulated.isManipulated" class="btn language-btn" (click)="activeTab('manipulation')" [ngClass]="{'activeManipulation': isManipulation}">
          <img src="./../../../../../assets/report_v2/manipulations_icon.svg" alt="Manipulations Icon">
          {{ 'new_entries.manipulations' | translate }}
        </button>
      </div>
    </div>
    <div class="similarity-section context-style" *ngIf="!isManipulation && !isAi && !isChangeSource">


    <ng-container *ngIf="(this.analysisType == 'none' && this.isOriginal) || (this.analysisType !== 'none' && this.isOriginal) || (this.analysisType !== 'none' && !this.isOriginal)">
      <div class="source">
        <h3  class="f16">{{ 'new_entries.matched_source' | translate }} <button *ngIf="(isOriginal && this.data.secondarySources && this.canEdit)" class="change_source_btn" (click)="changeSource()"><img src="../../../../../assets/report_v2/refresh-double.svg">{{ 'report.change' | translate }}</button></h3>
        <a *ngIf="source" [href]="source" target="_blank">{{ source }}</a>
        <a *ngIf="!source"  target="_blank">{{ 'new_entries.private_document_source' | translate }}</a>
        <h3 class="f16">{{ 'new_entries.matched_sentence' | translate }}</h3>
        <p class="matched-text" [innerHTML]="sentence"></p>
      </div>

      <h3>
        {{ 'new_entries.classification' | translate }}:
        <span [ngStyle]="{
                'background-color': getBgColor(data),
                'color': 'black',
                'padding': '3px',
                'font-weight': '500',
                'border-radius': '4px'
              }">{{ getClassificationText(data) }}
        </span>
      </h3>

      <h3>{{ 'new_entries.sentence_similarity' | translate }}:
        <button class="tooltip_btn" aria-live="polite" matTooltipClass="new_report_tooltip reverse" matTooltip="Indicates the percentage of similarity between two sentences.">
          <img src="../../../../../assets/report_v2/info-empty.svg" alt="Indicates the percentage of similarity between two sentences." />
        </button>
        <span>{{ this.isOriginal ? data.similarity.toFixed(2) : this.translatedObj?.source?.percentage.toFixed(2) }}%</span>
      </h3>

      <ng-container *ngIf="this.context_similarity">
        <h3 *ngIf="this.isOriginal ? data?.source?.contextSimilarity : this.translatedObj?.source.contextSimilarity">
          {{ 'new_entries.contextual_similarity' | translate }}:
          <button class="tooltip_btn" aria-live="polite" matTooltipClass="new_report_tooltip reverse"
            matTooltip="Shows the percentage of contextual similarity between two sentences.">
            <img src="../../../../../assets/report_v2/info-empty.svg"
              alt="Shows the percentage of contextual similarity between two sentences." />
          </button>
          <span>{{ this.isOriginal ? data.source.contextSimilarityPercentage :
            this.translatedObj.source.contextSimilarityPercentage }}%</span>
        </h3>
      </ng-container>
      <h3 *ngIf="this.isOriginal ? data.source?.averageDistance : this.translatedObj?.source?.averageDistance">
        {{ 'new_entries.proximity' | translate }}:
        <button class="tooltip_btn" aria-live="polite" matTooltipClass="new_report_tooltip reverse" matTooltip="Measures the average word distance between similar sentences.">
          <img src="../../../../../assets/report_v2/info-empty.svg" alt="Measures the average word distance between similar sentences." />
        </button>
        <span>{{ this.isOriginal ? data.source.averageDistance.toFixed(2) / 100 : this.translatedObj?.source?.averageDistance?.toFixed(2) / 100 }}</span>
      </h3>
    </ng-container>

    <ng-container *ngIf="(this.analysisType == 'none' && !this.isOriginal &&  this.data.translated.length !== 0)">
      <div class="translationInfo">
        <div>
          <ng-container *ngFor="let obj of data.translated">
            <ng-container *ngIf="obj.lang === toggledLang">
              <span *ngIf="!obj.isExcluded">
                {{ 'new_entries.exclude_sentence' | translate }}
              </span>
              <span *ngIf="obj.isExcluded">
                {{ 'new_entries.include_sentence' | translate }}
              </span>
            </ng-container>
          </ng-container>
        </div>
        <div *ngFor="let language of this.data?.translated">
          <div [ngClass]="{'selectedTranslatedLanguage': language.lang == toggledLang}">
            <div class="row-container" >
              <div (click)="toggleRow(language.lang)" ngClass="translated_language_header">
                <img src="../../../../../../assets/report_v2/up_down_arrow.svg" alt="up_down_arrow">
                <span>{{this.languageService.getLanguageName(language?.lang)}}</span>
              </div>
              <!-- <button *ngIf="canEdit" aria-label="Remove Source" class="remove_source" [ngClass]="{'excluded': (language.isExcluded)}" (click)="isManipulation && data.isManipulated.isHiddenText ? toggleHiddenText(data.id) : isManipulation && data.isManipulated.isCharReplacement ? excludeCharacters() : excludeSentence(language?.lang)"><img src="../../../../../../assets/report_v2/delete-circled.svg" alt=""></button> -->
            </div>
            <div class="translationSentenceInfo">
              <div *ngIf="toggledLang === language.lang" class="source translated_source">
                <div>
                  <h3 class="f16">{{ 'new_entries.translated_sentence' | translate }}</h3>
                  <p>{{language.text}}</p>
                </div>

                <h3 class="f16">{{ 'new_entries.matched_source' | translate }}</h3>
                <a *ngIf="language?.source?.url" [href]="language?.source?.url" target="_blank">{{ language.source.url }}</a>
                <a *ngIf="!language?.source?.url"  target="_blank">{{ 'new_entries.private_document_source' | translate }}</a>
                <h3 class="f16">{{ 'new_entries.matched_sentence' | translate }}</h3>
                <p class="matched-text" [innerHTML]="language?.sentence"></p>

                <div class="clasificationsStyle">
                  <h3> {{ 'new_entries.classification' | translate }}:
                    <span [ngStyle]="{
                            'background-color': getBgColor(language),
                            'color': 'black',
                            'padding': '3px',
                            'font-weight': '500 !important',
                            'border-radius': '4px'
                          }">{{ getClassificationText(language) }}
                    </span>
                  </h3>

                  <h3>{{ 'new_entries.sentence_similarity' | translate }}:
                    <button class="tooltip_btn" aria-live="polite" matTooltipClass="new_report_tooltip reverse" matTooltip="Indicates the percentage of similarity between two sentences.">
                      <img src="../../../../../assets/report_v2/info-empty.svg" alt="Indicates the percentage of similarity between two sentences." />
                    </button>
                    <span class="fontBold-blue">{{ language?.source?.percentage.toFixed(2) }}%</span>
                  </h3>

                  <ng-container *ngIf="this.context_similarity">
                    <h3 *ngIf="language?.source?.contextSimilarity">
                      {{ 'new_entries.contextual_similarity' | translate }}:
                      <button class="tooltip_btn" aria-live="polite" matTooltipClass="new_report_tooltip reverse"
                        matTooltip="Shows the percentage of contextual similarity between two sentences.">
                        <img src="../../../../../assets/report_v2/info-empty.svg"
                          alt="Shows the percentage of contextual similarity between two sentences." />
                      </button>
                      <span class="fontBold-blue">{{ language?.source?.contextSimilarityPercentage}}%</span>
                    </h3>
                  </ng-container>

                  <h3 *ngIf="this.isOriginal ? data.source?.averageDistance : language?.source?.averageDistance">
                    {{ 'new_entries.proximity' | translate }}:
                    <button class="tooltip_btn" aria-live="polite" matTooltipClass="new_report_tooltip reverse" matTooltip="Measures the average word distance between similar sentences.">
                      <img src="../../../../../assets/report_v2/info-empty.svg" alt="Measures the average word distance between similar sentences." />
                    </button>
                    <span class="fontBold-blue">{{ this.isOriginal ? data.source.averageDistance.toFixed(2) / 100 : language?.source?.averageDistance?.toFixed(2) / 100 }}</span>
                  </h3>
                </div>

                <button *ngIf="canEdit" (click)="excludeSentence(language?.lang)"  class="button-primary action_btn">{{ excludeButtonText }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>


    </ng-container>

    </div>



    <section class="remove_source" *ngIf="isChangeSource">
      <span tabindex="0" aria-label="Select a new source">{{ 'new_entries.select_new_source' | translate }}</span>
      <section class="sources">
        <section class="source" *ngFor="let source of selectedSentenceSources" tabindex="0" aria-label="Secondary Source.">
          <input (change)="onRadioChange(source.id)" type="radio" name="secondarySources" [id]="source.id">
          <label *ngIf="source.url" class="" [for]="source.id">{{ source.url }}</label>
          <label *ngIf="!source.url" class="" [for]="source.id">{{ 'new_entries.private_document_source' | translate }}</label>
        </section>
      </section>
    </section>



    <div class="similarity-section" *ngIf="isManipulation || excludedCharReplacement || excludedHiddenText">
      <section class="manipulation_tabs">
        <section class="manipulation_tab" [ngClass]="{'active': (isOnCharReplacement || this.data.isManipulated.isCharReplacement)}" *ngIf="(this.data.isManipulated.isCharReplacement || excludedCharReplacement) && !(analysisType === 'manipulation' && manipulationType === 'hiddenText')" (click)="openManipulationTab('charReplacement')">Character Replacements:</section>
        <section class="manipulation_tab" [ngClass]="{'active': (isOnHiddenText || this.data.isManipulated.isHiddenText)}" *ngIf="(this.data.isManipulated.isHiddenText || excludedHiddenText) && !(analysisType === 'manipulation' && manipulationType === 'charReplacement')" (click)="openManipulationTab('hiddenText')">{{ 'new_entries.hidden_text' | translate }}:</section>
      </section>
      <section class="manipulation_tabs_body">
        <ng-container *ngIf="isOnCharReplacement">
          <p *ngIf="sentenceManipulation" class="matched-text" [innerHTML]="sentenceManipulation"></p>
          <div *ngIf="!sentenceManipulation">
            <div class="includedChars" *ngIf="!showInsideModal">
              <h3>{{ 'new_entries.included_characters' | translate }}</h3>
              <ng-container *ngIf="data.manipulations[0]?.includedCharacters.length > 0; else noIncludedChars">
                <div class="chars" *ngFor="let char of data.manipulations[0]?.includedCharacters">
                  <p>{{ 'new_entries.character' | translate }}: <span class="bold">{{ char.character }}</span></p>
                  <p>{{ 'new_entries.appearance' | translate }}: <span class="bold">{{ char.count }}</span></p>
                </div>
              </ng-container>
              <ng-template #noIncludedChars>
                <p>{{ 'new_entries.no_excluded_characters' | translate }}</p>
              </ng-template>

              <h3>{{ 'new_entries.excluded_characters' | translate }}</h3>
              <ng-container *ngIf="data.manipulations[0]?.excludedReplacedCharacters.length > 0; else noExcludedChars">
                <div class="chars" *ngFor="let char of data.manipulations[0]?.excludedReplacedCharacters">
                  <p>{{ 'new_entries.character' | translate }}: <span class="bold">{{ char.character }}</span></p>
                  <p>{{ 'new_entries.appearance' | translate }}: <span class="bold">{{ char.count }}</span></p>
                </div>
              </ng-container>
              <ng-template #noExcludedChars>
                <p>{{ 'new_entries.no_included_characters' | translate }}</p>
              </ng-template>
            </div>
            <app-overview-disclaimer [title]="'general.overview' | translate" tabindex="0" disclaimerText="This screen displays the complete list of character replacements present in this document. Any action to include or exclude characters will apply to the entire document, meaning the selected characters will be included or excluded from every sentence throughout the document."></app-overview-disclaimer>
          </div>
          <div class="inside-modal" *ngIf="(data.isManipulated.isCharReplacement || excludedCharReplacement) && showInsideModal">
            <section class="parent" *ngIf="modalType == 'exclude'">
              <h1>{{ 'new_entries.exclude_characters' | translate }}</h1>
              <p>{{ 'new_entries.select_exclude_characters' | translate }}</p>
              <section class="modal-body">
                <div *ngIf="result.length > 0">
                  <label>
                    <input type="checkbox" (change)="toggleSelectAll($event)" />
                    <span>{{ 'new_entries.select_all' | translate }}</span>
                  </label>
                  <label>{{ 'new_entries.appearances' | translate }}</label>
                </div>
                <section class="scrollable">
                  <div *ngFor="let char of result; let i = index">
                    <label>
                      <input type="checkbox" [(ngModel)]="selectionState[i]" />
                      <span>{{ char?.character}}</span>
                    </label>
                    <label>{{ char?.count }}</label>
                  </div>
                  <p class="no_results" *ngIf="result.length == 0">{{ 'new_entries.no_excluded_characters' | translate }}!</p>
                </section>
              </section>
            </section>
            <section class="parent" *ngIf="modalType == 'include'">
              <h1>{{ 'new_entries.include_characters' | translate }}</h1>
              <p>{{ 'new_entries.select_include_characters' | translate }}</p>
              <section class="modal-body">
                <div *ngIf="excluded_result.length > 0">
                  <label>
                    <input type="checkbox" (change)="toggleSelectAll($event)" />
                    <span>{{ 'new_entries.select_all' | translate }}</span>
                  </label>
                  <label>{{ 'new_entries.appearances' | translate }}</label>
                </div>
                <section class="scrollable">
                  <div *ngFor="let char of excluded_result; let i = index">
                    <label>
                      <input type="checkbox" [(ngModel)]="excludedSelectionState[i]" />
                      <span>{{ char?.character}}</span>
                    </label>
                    <label>{{ char?.count }}</label>
                  </div>
                  <p class="no_results" *ngIf="excluded_result.length == 0">{{ 'new_entries.no_included_characters' | translate }}!</p>
                </section>
              </section>
            </section>
          </div>
        </ng-container>
        <ng-container *ngIf="isOnHiddenText">
          <!-- <div> -->
            <!-- <h3>{{ 'new_entries.hidden_text' | translate }}</h3> -->
            <p class="matched-text" [innerHTML]="formattedText"></p>
          <!-- </div> -->
        </ng-container>
      </section>
    </div>
    <div class="similarity-section" *ngIf="isAi">
      <div class="aiContent">
        <img src="../../../../../assets/report_v2/warning-circled-filled-ai.svg" alt="">
        <span>{{ 'new_entries.ai_generated_sentence_warning' | translate }}</span>
      </div>
      <section class="disclaimer open">
        <section>
          <h5>{{ 'other.disclaimer' | translate }}</h5>
          <p>{{ 'new_entries.ai_authorship_index_info' | translate }}.</p>
        </section>
      </section>
    </div>
    <section class="exclude" *ngIf="showExclude">
      <label>{{ 'new_entries.exclusion_reason_optional' | translate }}:</label>
      <section class="d-flex">
        <input type="text" class="commentInput" [(ngModel)]="commentValue" [readonly]="!isEditable" />
        <div *ngIf="isEditable && showCommentButtons && canEdit" class="d-flex ml-10">
          <button tabindex="0" aria-label="Cancel Input" (click)="commentAction('discard')" [ngClass]="[!isEditable ? 'isEditableBtn' : '']" class="edit_input_btn">
            <img src="../../../../../assets/report_v2/close_big.svg" alt="">
          </button>
          <button tabindex="0" aria-label="Save Input" (click)="commentAction('update')" [ngClass]="[!isEditable ? 'isEditableBtn' : '']" class="edit_input_btn">
            <img src="../../../../../assets/report_v2/check.svg" alt="">
          </button>
        </div>
        <button *ngIf="!this.isEditable" tabindex="0" aria-label="Edit button" (click)="this.isEditable = true; this.showCommentButtons = true" class="edit_input_btn ml-10">
          <img src="../../../../../assets/report_v2/edit.svg" alt="">
        </button>
      </section>
    </section>

    <div class="footer" *ngIf="!isManipulation && canEdit && (this.isOriginal || (!this.isOriginal && this.analysisType !== 'none'))">
      <button class="secondary-button" (click)="(excludeClickCount >=1 || includeClickCount >= 1) ? resetState() : clearSentenceInformation()">{{ ((excludeClickCount >=1 || includeClickCount >= 1) ? 'app.back' : 'general.close') | translate}}</button>
      <button class="button-primary"  *ngIf="!this.isAi && !isChangeSource && (this.isOriginal ? !data?.source?.isExcluded : !data?.translatedObj?.isExcluded)"  (click)="excludeSentence()" [attr.aria-label]="excludeButtonAriaLabel">
        {{ excludeButtonText }}
      </button>
      <button class="button-primary" *ngIf="changeSource && changeSourceClickCount >= 1" [disabled]="!selectedSource" [ngClass]="{'disabled': !selectedSource}" (click)="changeSource()">{{ 'report.change' | translate }}</button>
    </div>

    <div class="footer" *ngIf="isManipulation || excludedCharReplacement || excludedHiddenText">
      <button
        class="secondary-button"
        (click)="((data.isManipulated.isCharReplacement && isManipulation) && (excludeClickCount >=1 || includeClickCount >= 1)) ? resetState() : clearSentenceInformation()">
        {{ (((data.isManipulated.isCharReplacement && isManipulation) && (excludeClickCount >=1 || includeClickCount >= 1)) ? 'app.back' : 'general.close') | translate }}
      </button>
      <ng-container *ngIf="isOnCharReplacement && canEdit">
        <button
          class="secondary-button"
          *ngIf="!(isManipulation && data.manipulations[0]?.excludedReplacedCharacters == 0)"
          [ngClass]="{'excluded disabled': isManipulation && data.manipulations[0]?.excludedReplacedCharacters == 0}"
          (click)="includeCharacters()">
          {{ 'new_entries.include_characters' | translate }}
        </button>
        <button
          class="button-primary"
          *ngIf="!(isManipulation && data.manipulations[0]?.includedCharacters == 0 && manipulationType == 'charReplacement')"
          [ngClass]="{'excluded disabled': isManipulation && data.manipulations[0]?.includedCharacters == 0 && manipulationType == 'charReplacement'}"
          (click)="excludeCharacters()">
          {{ 'new_entries.exclude_characters' | translate }}
        </button>
      </ng-container>
      <ng-container *ngIf="isOnHiddenText && canEdit">
        <button
          class="button-primary"
          (click)="toggleHiddenText(data.id)"
          [attr.aria-label]="excludeButtonAriaLabel">
          {{ manipulationExcludeButtonText }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
