import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { StatisticsService } from 'src/app/services/statistics.service';
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { saveAs } from 'file-saver';
/**
 * Component used to show user transactions.
 */

@Component({
   selector: 'app-transaction',
   templateUrl: './transaction.component.html',
   styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit, OnDestroy {
   /**
    * Current User
    */
   currentUser: User;
   /**
    * Pagination page
    */
   pageTransaction = 1;
   /**
    * Page Size
    */
   pageSize = 5;
   /**
    * Used to store User transactions
    */
   transactionsPagination;

   user$;
   pdfData = [];
   type: number;
   /**
    * Component constructor
    * @param authService
    * @param statisticsService
    * @param spinner
    */
   constructor(
      private statisticsService: StatisticsService,
      private spinner: NgxSpinnerService,
      private store: Store,
      private submissionService: SubmissionsService
   ) {}
   ngOnDestroy(): void {
      this.user$.unsubscribe();
   }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.spinner.show();
      this.user$ = this.store
         .select(getCurrentUser)
         .pipe()
         .subscribe((data) => {
            if (data !== null) {
               this.currentUser = data;
            }
         });
      if (this.currentUser.institutionId === null) {
         this.type = 0;
      } else {
         this.type = 1;
      }
      this.statisticsService
         .transactionsPagination(
            this.currentUser.id,
            this.pageTransaction,
            this.type,
            this.pageSize
         )
         .pipe(first())
         .subscribe(
            (data) => {
               this.transactionsPagination = data;
            },
            (error) => {
               console.log('error', error);
            }
         );
      this.spinner.hide();
   }

   filterType(event) {
      this.type = parseInt(event);
      this.statisticsService
         .transactionsPagination(
            this.currentUser.id,
            this.pageTransaction,
            this.type,
            this.pageSize
         )
         .pipe(first())
         .subscribe(
            (data) => {
               this.transactionsPagination = data;
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   downloadPdfInvoice(id) {
      this.spinner.show();
      this.submissionService
         .downloadInvoice(id)
         .pipe(first())
         .subscribe(
            (data: any) => {
               this.spinner.hide();
               saveAs(data.urlToDownload, 'invoice.pdf');
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );
   }
   /**
    * Method used to change transaction pagination page
    * @param event
    */
   changeTransactionPage(event) {
      this.spinner.show();
      this.pageTransaction = event;
      this.statisticsService
         .transactionsPagination(
            this.currentUser.id,
            this.pageTransaction,
            this.type,
            this.pageSize
         )
         .pipe(first())
         .subscribe(
            (data) => {
               this.spinner.hide();
               this.transactionsPagination = data;
            },
            (error) => {
               this.spinner.hide();
               console.log('error', error);
            }
         );
   }
}
