import { User } from 'src/app/models/user';
import { createAction, props } from '@ngrx/store';
import { Assignment } from 'src/app/models/assignment';
/**
 * Actions for Authentication process.
 * Each action accepts a set of properties.
 */

export const login = createAction(
   '[Authentication] Login User',
   // Action Accepts email and password
   props<{ email: string; password: string; isIdpLogin?: boolean, institutionName?: string, role?: string, fullName?: string}>()
);

export const loginSuccess = createAction(
   '[Authentication] Login User Success',
   // Action accepts User returned from the api
   props<{ user: User }>()
);

export const ltiSuccess = createAction(
   '[Authentication] Login User Success',
   // Action accepts User returned from the api
   props<{ user: User }>()
);

export const loginFailure = createAction(
   '[Authentication] Login User Failure',
   // Action accepts error returned from the api
   props<{ error: any }>()
);

export const currentUser = createAction('[User] currentUser User');

export const currentUserSuccess = createAction(
   '[User] currentUser User Success',
   // Action accepts User returned from the api
   props<{ user: User }>()
);

export const currentUserFailure = createAction(
   '[User] currentUser User Failure',
   // Action accepts error returned from the api
   props<{ error: any }>()
);

export const googleLogin = createAction(
   '[Authentication] Google Login User',
   // Action Accepts email and name
   props<{ name: string; email: string }>()
);

export const googleLoginSuccess = createAction(
   '[Authentication] Google Login User Success',
   // Action accepts User returned from the api
   props<{ user: User }>()
);
export const googleLoginFailure = createAction(
   '[Authentication] Google Login User Failure',
   // Action accepts error returned from the api
   props<{ error: any }>()
);
export const LTILogin = createAction(
   '[Authentication] LTI Login User',
   // Action Accepts email and name
   props<{
      token: string;
      user: User;
      assignment?: any;
      courseId?: string;
      assignmentId?: string;
   }>()
);

export const LTILoginSuccess = createAction(
   '[Authentication] LTI Login User Success',
   // Action accepts User returned from the api
   props<{ user: User }>()
);
export const LTILoginFailure = createAction(
   '[Authentication] LTI Login User Failure',
   // Action accepts error returned from the api
   props<{ error: any }>()
);

export const linkedInLogin = createAction(
   '[Authentication] LinkedIn Login User',
   // Action accepts authorization Code that we get from linkedIn
   props<{ authCode: string }>()
);

export const linkedInLoginSuccess = createAction(
   '[Authentication] LinkedIn Login User Success',
   // Action accepts User returned from the api
   props<{ user: User }>()
);
export const linkedInLoginFailure = createAction(
   '[Authentication] LinkedIn Login User Failure',
   // Action accepts error returned from the api
   props<{ error: any }>()
);
export const logout = createAction('[Logout] Logout');

export const updateUserInfo = createAction(
   '[Personal Info] Update User Info',
   props<{ firstName: string; lastName: string }>()
);

export const updateUserInfoSuccess = createAction(
   '[Personal Info] Update User Info Success',
   props<{ firstName: string; lastName: string }>()
);
export const updateUserInfoFailure = createAction(
   '[Personal Info] Update User Info Failure',
   props<{ error: string }>()
);

export const updateUserState = createAction(
   '[Individual Dashboard] Update User State',
   props<{ userTotalCredits: any }>()
);
export const updateUserActivationState = createAction(
   '[Account Verification] Update User State',
   props<{ user: any }>()
);

export const signUp = createAction(
   '[Sign Up] Sign Up',
   props<{
      firstName: string;
      lastName: string;
      email: string;
      institution: string;
      marketing: boolean;
      role: String;
      country: String;
      currentSystem: String;
      company: String;
      message: String;
   }>()
);

export const signUpSuccess = createAction(
   '[Sign Up] Sign Up Success',
   props<{ user: User }>()
);

export const signUpFailure = createAction(
   '[Sign Up] Sign Up Failure',
   props<{ error: any }>()
);
