<main class="log-in" role="main" tabindex="-1">
   <div class="left-side" role="complementary">
      <img src="../../assets/login/login-bg-new.png" [attr.alt]="'login.background_illustration' | translate" tabindex="0" />
      <div>
         <h1 tabindex="0">{{ 'login.pioneering_originality_in_the_wordl_of_ai' | translate }}</h1>
         <p tabindex="0" [innerHtml]="'login.crossplag_description' | translate"></p>
      </div>
         <p class="copyright" tabindex="0">© {{ 'general.rights_reserved' | translate }} - Inspera AS</p>
   </div>

   <div tabindex="0" class="right-side" role="region" [attr.aria-label]="'login.aria.role_navigation' | translate">
      <div class="form-container">
       <div class="inspera-logo-container">
         <img
         src="../../../assets/report_v2/inspera_originality.svg"
         [attr.alt]="'login.inspera_logo' | translate"
         class="inspera-logo"
         tabindex="0"
      />
      <app-translate></app-translate>
       </div>

         <div tabindex="0" class="role-buttons" role="navigation" [attr.aria-label]="'login.aria.role_navigation' | translate">
            <ng-container *ngIf="role === 'admin'">
               <button class="text-button" [routerLink]="['/login', this.institutionName, 'educator']" [attr.aria-label]="'login.goto_educator' | translate">
                  {{ 'login.goto_educator' | translate }} <img src="../../../assets/login/User-circle-alt.svg" alt="{{ 'login.educator_icon' | translate }}">
               </button>
               <hr class="role-separator">
               <button class="text-button" [routerLink]="['/login', this.institutionName, 'student']" [attr.aria-label]="'login.goto_student' | translate">
                  {{ 'login.goto_student' | translate }} <img src="../../../assets/login/user.svg" alt="{{ 'login.student_icon' | translate }}">
               </button>
            </ng-container>
            <ng-container *ngIf="role === 'educator'">
               <button class="text-button" [routerLink]="['/login', this.institutionName, 'admin']" [attr.aria-label]="'login.goto_administrator' | translate">
                  {{ 'login.goto_administrator' | translate }} <img src="../../../assets/login/userRoleAdminAdmin.svg" alt="{{ 'login.administrator_icon' | translate }}">
               </button>
               <hr class="role-separator">
               <button class="text-button" [routerLink]="['/login', this.institutionName, 'student']" [attr.aria-label]="'login.goto_student' | translate">
                  {{ 'login.goto_student' | translate }} <img src="../../../assets/login/user.svg" alt="{{ 'login.student_icon' | translate }}">
               </button>
            </ng-container>
            <ng-container *ngIf="role === 'student'">
               <button class="text-button" [routerLink]="['/login', this.institutionName, 'admin']" [attr.aria-label]="'login.goto_administrator' | translate">
                  {{ 'login.goto_administrator' | translate }} <img src="../../../assets/login/userRoleAdminAdmin.svg" alt="{{ 'login.administrator_icon' | translate }}">
               </button>
               <hr class="role-separator">
               <button class="text-button" [routerLink]="['/login', this.institutionName, 'educator']" [attr.aria-label]="'login.goto_educator' | translate">
                  {{ 'login.goto_educator' | translate }} <img src="../../../assets/login/User-circle-alt.svg" alt="{{ 'login.educator_icon' | translate }}">
               </button>
            </ng-container>
         </div>

         <div [ngClass]="{ 'height-15': idpLogin }">
            <div class="form-header" *ngIf="idpLogin">
               <h1 tabindex="0" aria-label="{{ role | titlecase }} {{ 'login.sign_in' | translate }}">{{ role | titlecase }} {{ 'login.sign_in' | translate }}</h1>
               <p tabindex="0" aria-label="{{ 'login.sso_instruction' | translate }}">{{ 'login.sso_instruction' | translate }}</p>
            </div>
            <div class="form-header" *ngIf="!idpLogin">
               <h1 tabindex="0" aria-label="{{ 'login.welcome_message' | translate }}">{{ 'login.welcome_message' | translate }}</h1>
               <p tabindex="0" aria-label="{{ 'login.signin_instruction' | translate }}">{{ 'login.signin_instruction' | translate }}</p>
            </div>

            <div class="social-buttons-dynamic" role="group" aria-label="Social login options">
               <ng-container *ngFor="let idpMethod of idpMethods">
                  <button (click)="initializeKeycloak(idpMethod.alias)" [attr.aria-label]="'login.aria.login_with' | translate: { provider: idpMethod.displayName }">
                     <div class="buttton-alignment">
                        <img *ngIf="idpMethod.logoUrl" style="width: 20px; height: 20px;" [src]="idpMethod.logoUrl" [alt]="idpMethod.displayName + ' logo'">
                        <span>{{ idpMethod.displayName }}</span>
                     </div>
                  </button>
               </ng-container>
               <div *ngIf="socialIdps?.length > 0" class="social-buttons-position">
                  <div class="divider-container" *ngIf="idpLogin">
                     <span class="divider-text">Social login</span>
                     <div class="divider-line"></div>
                  </div>
                  <ng-container *ngFor="let socialIdp of socialIdps">
                     <button (click)="initializeKeycloak(socialIdp.alias)" [attr.aria-label]="'login.aria.login_with' | translate: { provider: socialIdp.displayName }">
                        <div class="buttton-alignment">
                           <img *ngIf="socialIdp.logoUrl" style="width: 20px; height: 20px;" [src]="socialIdp.logoUrl" [alt]="socialIdp.displayName + ' logo'">
                           <span>{{ socialIdp.displayName }}</span>
                        </div>
                     </button>
                  </ng-container>
                  <div class="divider-container" *ngIf="!idpLogin">
                     <span class="divider-text">... or with registered user</span>
                     <div class="divider-line"></div>
                  </div>
               </div>
              </div>
         </div>

         <form
            *ngIf="!idpLogin"
            [formGroup]="loginForm"
            (keydown.enter)="onKeydown($event)"
            role="form"
            aria-label="Login form"
         >
            <input
               formControlName="email"
               type="email"
               placeholder="{{ 'general.email' | translate }}"
               aria-label="Email input"
               style="font-size: 1rem; padding: 0 10px; width: 100%"
            />
            <div
               *ngIf="loginFormValues.email.touched && loginFormValues.email.invalid"
               class="alert alert-danger"
               role="alert"
               aria-live="polite"
            >
               <div class="invalid" aria-label="'validators.enter_email' | translate" *ngIf="loginFormValues.email.errors.required" tabindex="0">
                  {{ 'validators.enter_email' | translate }}
               </div>
            </div>
            <div class="password">
               <input
                  formControlName="password"
                  [type]="showPassword ? 'text' : 'password'"
                  placeholder="{{ 'general.password' | translate }}"
                  aria-label="Password input"
                  style="font-size: 1rem; padding: 0 10px; width: 100%"
               />
               <span class="input-group-text"
                     (click)="currentPassword()"
                     role="button"
                     aria-label="Toggle password visibility"
                     tabindex="0">
                  <i
                     class="fa"
                     [ngClass]="{
                        'fa-eye-slash': !showPassword,
                        'fa-eye': showPassword
                     }"
                     aria-hidden="true"
                  ></i>
               </span>
            </div>
            <div
               *ngIf="loginFormValues.password.touched && loginFormValues.password.invalid"
               class="alert alert-danger"
               role="alert"
               aria-live="polite"

            >
               <div class="invalid" aria-label="'validators.enter_passsword' | translate" *ngIf="loginFormValues.password.errors.required" tabindex="0">
                  {{ 'validators.enter_passsword' | translate }}
               </div>
            </div>
            <div class="forgot-password">
               <a routerLink="/forgot-password"
                  class="forgot-password"
                  aria-label="Forgot password link">
                  {{ 'login.forgot_password' | translate }}
               </a>
            </div>
            <div class="login-btn-container">
               <ng-container *ngIf="loginForm.valid">
                  <button
                     [disabled]="loginForm.invalid"
                     (click)="signIn()"
                     class="login-btn"
                     aria-label="Sign in button"
                  >
                     {{ 'login.sign_in' | translate }}
                  </button>
               </ng-container>
               <ng-container *ngIf="loginForm.invalid">
                  <button
                     (click)="showToasterWarning('Please re-check your info')"
                     class="login-btn"
                     aria-label="Sign in button disabled"
                  >
                     {{ 'login.sign_in' | translate }}
                  </button>
               </ng-container>
            </div>
         </form>
         <div class="activate-lti-account" *ngIf="!idpLogin" aria-label="LTI account activation section">
            <div>
               <h2 routerLink="/activate-account" tabindex="0" aria-label="{{ 'login.aria.lti_activation_section' | translate }}">
                  {{ 'login.activate_lti' | translate }}
                  <i class="fas fa-arrow-right" style="font-size: 0.8em; padding-left: 15px;" aria-hidden="true"></i>
               </h2>
            </div>
         </div>
         <footer>
            <nav class="trouble-logging-in" aria-label="Help navigation">
               <hr />
               <p tabindex="0" [attr.aria-label]="'login.contact_admin' | translate">{{ 'login.contact_admin' | translate }}</p>
            </nav>
         </footer>
      </div>
   </div>
</main>
