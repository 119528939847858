<main class="log-in" role="main">
   <div class="left-side" role="complementary">
      <img src="../../assets/login/login-bg-new.png" alt="Login background image" />
      <div>
         <h1 tabindex="0">
            {{ 'login.pioneering_originality_in_the_wordl_of_ai' | translate }}
         </h1>
         <p tabindex="0" [innerHtml]="'login.crossplag_description' | translate"></p>
      </div>
      <h6 tabindex="0">© {{ 'general.rights_reserved' | translate }} - Inspera AS</h6>
   </div>
   <div class="right-side" role="region">
      <div class="form-container">
         <div class="inspera-logo-container">
            <img src="../../assets/login/inspera-login-logo.png" class="inspera-logo" [attr.alt]="'login.inspera_logo' | translate" tabindex="0" />
            <app-translate></app-translate>
           </div>

         <h3 id="createPasswordHeader" tabindex="0">Create password</h3>

         <form [formGroup]="setPasswordForm" role="form" aria-labelledby="createPasswordHeader">
            <ng-container *ngIf="invited">
               <h5 id="nameLabel" tabindex="0">{{ 'general.name' | translate }}</h5>
               <div class="password">
                  <input 
                     type="text" 
                     formControlName="name"
                     [attr.aria-labelledby]="nameLabel"
                     tabindex="0"
                     aria-required="true"
                     [attr.aria-invalid]="form.name.touched && form.name.errors?.required"
                  />
                  <div
                     class="invalid"
                     *ngIf="form.name.touched && form.name.errors?.required"
                     role="alert"
                     tabindex="0"
                  >
                     {{ 'general.name' | translate }}
                     {{ 'aria-labels.is_required' | translate }}*
                  </div>
               </div>
               <h5 id="indexLabel" tabindex="0">{{ 'validators.index_optional' | translate }}</h5>
               <div class="password">
                  <input 
                     type="text" 
                     formControlName="index"
                     [attr.aria-labelledby]="indexLabel"
                     tabindex="0"
                  />
               </div>
            </ng-container>
            
            <h5 id="passwordLabel" tabindex="0">{{ 'general.password' | translate }}</h5>
            <div class="password">
               <input
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password"
                  tabindex="0"
                  aria-required="true"
                  [attr.aria-invalid]="form.password.invalid && form.password.touched"
                  (keyup)="checkPassword()"
               />
               <span 
                  class="input-group-text" 
                  (click)="password()"
                  role="button"
                  tabindex="0"
                  [attr.aria-label]="showPassword ? 'Hide password' : 'Show password'"
               >
                  <i
                     class="fa"
                     [ngClass]="{
                        'fa-eye-slash': !showPassword,
                        'fa-eye': showPassword
                     }"
                     aria-hidden="true"
                  ></i>
               </span>
            </div>
            <div
               *ngIf="form.password.invalid && form.password.touched"
               class="invalid"
               role="alert"
               tabindex="0"
            >
               <div *ngIf="form.password.errors?.required">
                  {{ 'validators.password_required' | translate }}*
               </div>
               <div *ngIf="form.password.errors?.pattern">
                  {{ 'validators.password_pattern' | translate }}
               </div>
            </div>

            <h5 id="confirmPasswordLabel" tabindex="0">{{ 'general.confirm_password' | translate }}</h5>
            <div class="password">
               <input
                  [type]="showConfirmPassword ? 'text' : 'password'"
                  formControlName="confirmPassword"
                  tabindex="0"
                  aria-required="true"
                  [attr.aria-invalid]="form.confirmPassword.invalid && form.confirmPassword.touched"
               />
               <span 
                  class="input-group-text" 
                  (click)="confirmPassword()"
                  role="button"
                  tabindex="0"
                  [attr.aria-label]="showConfirmPassword ? 'Hide confirm password' : 'Show confirm password'"
               >
                  <i
                     class="fa"
                     [ngClass]="{
                        'fa-eye-slash': !showConfirmPassword,
                        'fa-eye': showConfirmPassword
                     }"
                     aria-hidden="true"
                  ></i>
               </span>
            </div>
            <div
               *ngIf="form.confirmPassword.invalid && form.confirmPassword.touched"
               class="invalid"
               role="alert"
               tabindex="0"
            >
               <div *ngIf="form.confirmPassword.errors?.required">
                  {{ 'general.confirm_new_password' | translate }}
                  {{ 'aria-labels.is_required' | translate }}*
               </div>
            </div>
            <div
               class="invalid"
               *ngIf="
                  form.confirmPassword.value !== form.password.value &&
                  form.confirmPassword.touched &&
                  form.confirmPassword.value !== ''
               "
               role="alert"
               tabindex="0"
            >
               {{ 'validators.passwords_dont_match' | translate }}.
            </div>
            
            <div class="terms" role="group" aria-labelledby="termsLabel">
               <input
                  name="terms_and_conditions"
                  id="terms_and_conditions"
                  type="checkbox"
                  formControlName="agree"
                  tabindex="0"
                  aria-required="true"
               />
               <label id="termsLabel" for="terms_and_conditions" tabindex="0"></label>
               <a 
                  mat-button 
                  (click)="openModal()"
                  tabindex="0"
                  role="button"
               >
                  {{ 'general.terms_and_conditions' | translate }}
               </a>
            </div>

            <div>
               <button
                  [disabled]="
                     setPasswordForm.invalid ||
                     form.password.value !== form.confirmPassword.value ||
                     form.confirmPassword.value == '' ||
                     !form.agree.value
                  "
                  [ngClass]="{
                     disable:
                        setPasswordForm.invalid ||
                        form.password.value !== form.confirmPassword.value ||
                        form.confirmPassword.value == '' ||
                        !form.agree.value
                  }"
                  class="submit"
                  type="submit"
                  (click)="submit()"
                  tabindex="0"
                  aria-label="Confirm password creation"
               >
                  Confirm
               </button>
            </div>
         </form>
      </div>
   </div>
</main>
