<section class="modal-container">
  <header>
    <h1>
      {{ 'aria-labels.document' | translate }}
      {{ 'aria-labels.settings' | translate }}
    </h1>
    <button (click)="cancel()">
      <img src="../../../../../../assets/report_v2/close_big.svg" alt="" />
    </button>
  </header>
  <section class="document_settings">
    <p>{{ 'individual.upload.translate_document' | translate }}:</p>
    <app-translate></app-translate>
  </section>

  <header>
    <h1>{{ 'app.privacy_settings' | translate }}</h1>
  </header>
  <section class="modal-body">
    <!-- CASE 1: When the the  professor  opens the  student report -->
    <ng-container *ngIf="submissionUserId != this.loggedInUser.id">
      <section class="modal-action" *ngIf="!(isDocumentInArchive == undefined)">
        <label for="delete_doc_from_arch" [ngClass]="{ disabled: isDocumentInArchive == false }">{{ 'report.give_student_permission_to_delete_document' | translate }}</label>
        <input type="checkbox" name="delete_doc_from_arch" id="delete_doc_from_arch"
          [disabled]="isDocumentInArchive == false" [(ngModel)]="permissions.deleteInArchive" />
      </section>
      <section class="modal-action">
        <label for="delete_doc_from_repo">{{ 'report.give_student_permission_to_delete_everything' | translate }}</label>
        <input type="checkbox" name="delete_doc_from_repo" id="delete_doc_from_repo"
          [(ngModel)]="permissions.deleteEverything" (change)="deleteEverything()" />
      </section>
    </ng-container>

    <!-- CASE 2: When the  professor opens its  own  report or CASE 3: When the  student  opens  its  own report -->
    <div *ngIf="submissionUserId == this.loggedInUser.id && data.submission.archive && data.submission.assignmentsId === null">
      <span tabindex="0" [attr.aria-label]="'aria-labels.delete_document' | translate">{{ 'report.delete_document_from_archive' | translate }}</span>
      <button class="deleteBtn" (click)="deleteDocument(1)" tabindex="0">{{ 'app.delete' | translate }}</button>
    </div>
<!-- <div *ngIf="permissions?.deleteSources">
        <span tabindex="0" [attr.aria-label]="'aria-labels.delete_similarity_sources' | translate">{{ 'report.delete_similarity_sources' | translate }}</span>
        <button class="deleteBtn"  (click)="deleteDocument(0)" tabindex="0">{{ 'app.delete' | translate }}</button>
      </div> -->
    <div *ngIf="submissionUserId == this.loggedInUser.id && (this.loggedInUser.roleId !== 9 || this.permissions.deleteEverything)">
      <span tabindex="0" [attr.aria-label]="'aria-labels.delete_everything' | translate">{{ 'delete.delete_everything' | translate }}</span>
      <button class="deleteBtn" (click)="deleteDocument(2)" tabindex="0">{{ 'app.delete' | translate }}</button>
    </div>
  </section>

  <section class="modal-btns" *ngIf="submissionUserId !== this.loggedInUser.id">
    <button class="submit-btn" (click)="submit()" 
    [disabled]="!permissions.deleteInArchive && !permissions.deleteEverything" 
    [ngClass]="{'disabled': !permissions.deleteInArchive && !permissions.deleteEverything}">
      {{ 'general.submit' | translate }}
    </button>
    <button class="cancel-btn" (click)="cancel()">
      {{ 'general.cancel' | translate }}
    </button>
  </section>
</section>
