import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionsService } from '../../../services/submissions.service';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-professor-delete-modal',
  templateUrl: './professor-delete-modal.component.html',
  styleUrls: ['./professor-delete-modal.component.scss']
})
export class ProfessorDeleteModalComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private SubmissionsService: SubmissionsService,
    public dialog: MatDialog, public translate: TranslateService,
    /**
     * Used to pass data in component through dialog
     */
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    permissions = {
      deleteEverything: false,
      deleteSources: false,
      deleteInArchive: false
    }

  ngOnInit(): void {
    this.permissions = this.data.permissions;
  }

  submit(){
    if(this.permissions.deleteEverything) {
      this.permissions.deleteInArchive = true
      // this.permissions.deleteSources = true
    }
    let deleteInArchiveText;
    let deleteEverythingText;
    let deleteSourcesText;
    let title;
    if (this.permissions.deleteInArchive && !(this.data.isDocumentInArchive == undefined)) {
      title = this.translate.instant('report.delete_document_from_archive');
      deleteInArchiveText = `</br>`
    } else {
      deleteInArchiveText = ''
    }
    if (this.permissions.deleteEverything) {
      title = this.translate.instant('app.are_you_sure_you_want_to_give_student_permission_to');
      deleteEverythingText = `</br><span style="color:red; display: block; font-weight: bold;font-size: 0.8rem;margin-left:20px"></span>`
    } else {
      deleteEverythingText = ''
    }
    if(this.permissions.deleteSources) {
      deleteSourcesText = `<li style="text-align: left;margin-bottom: 20px;"><b>${this.translate.instant('delete.delete_the_sources')}</b></br>${this.translate.instant('delete.removes_any_source_that_we_found_that_has_similarities_with_this_document')}<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">${this.translate.instant('delete.if_student_deletes_it')}:</span><span style="color:red; display: block; font-weight: bold;font-size: 0.8rem;margin-left:20px">${this.translate.instant('delete.you_will_still_be_able_to_see_the_percentage_of_similarity')}</span></li>`
    }else {
      deleteSourcesText=''
    }
    if(!deleteInArchiveText && !deleteSourcesText && !deleteEverythingText) {
      title = this.translate.instant('report.student_wont_be_able_to_delete_anything')
    }
    swal.fire({
      title: title,
      html:
      deleteInArchiveText +
      deleteSourcesText +
      deleteEverythingText,
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      denyButtonText: this.translate.instant('general.no'),
   })
   .then((result) => {
      if (result.isConfirmed) {
         this.spinner.show(this.translate.instant('app.loading'));
         this.SubmissionsService.deletionPermissions(this.data.submissionId,this.permissions)
         .pipe()
         .subscribe(
            (data: any) => {
               this.spinner.hide(this.translate.instant('app.loading'));
               swal.fire('', this.translate.instant('delete.permissions_changed_successfully'), 'info');
            },
            (error) => {
               console.log('error', error);
               this.spinner.hide(this.translate.instant('app.loading'));
            }
         );
      }
   });
  }

  deleteEverything() {

    if(this.permissions.deleteEverything) {
      this.permissions.deleteInArchive = true
      // this.permissions.deleteSources = true
    }
      }

      close() {
        this.dialog.closeAll();
      }

}
