<main class="log-in" role="main" tabindex="-1">
   <div class="left-side" role="complementary">
      <img src="../../assets/login/login-bg-new.png" alt="Login background image" tabindex="0" />
      <div>
         <h1 tabindex="0">
            {{ 'login.pioneering_originality_in_the_wordl_of_ai' | translate }}
         </h1>
         <p tabindex="0" [innerHtml]="'login.crossplag_description' | translate"></p>
      </div>
      <h6 tabindex="0">© {{ 'general.rights_reserved' | translate }} - Inspera AS</h6>
   </div>
   <div  class="right-side" role="region">
      <div class="form-container">
        <div class="inspera-logo-container">
         <img src="../../assets/login/inspera-login-logo.png" class="inspera-logo" [attr.alt]="'login.inspera_logo' | translate" tabindex="0" />
         <app-translate></app-translate>
        </div>
         
         <h3 id="activateHeader" tabindex="0">{{ 'login.activate_account' | translate }}</h3>
         <span tabindex="0">{{ 'lti.standalone_version' | translate }} <a tabindex="0" href="https://support.inspera.com/hc/en-us/articles/21649758741021-Inspera-Originality-Activate-Link-LTI-Account" target="_blank" rel="noopener noreferrer">{{ 'lti.need_help' | translate }}</a></span>
         <div [formGroup]="activateUserForm" role="form" aria-labelledby="activateHeader">
            <input
               formControlName="email"
               [placeholder]="'placeholders.email' | translate"
               [attr.aria-label]="'login.aria.email_input' | translate"
               type="email"
               aria-required="true"
               [attr.aria-invalid]="FormValues.email.invalid && FormValues.email.touched"
            />
         </div>
         <div
            *ngIf="FormValues.email.touched && FormValues.email.invalid"
            class="alert alert-danger"
            role="alert"
            aria-live="polite"
         >
            <div class="invalid" *ngIf="FormValues.email.errors.required" tabindex="0">
               {{ 'validators.enter_email' | translate }}
            </div>
            <div class="invalid" *ngIf="FormValues.email.errors.email" tabindex="0">
               {{ 'validators.valid_email' | translate }}
            </div>
         </div>

         <div>
            <button
               [ngClass]="{ disable: FormValues.invalid }"
               [disabled]="FormValues.invalid"
               (click)="submit()"
               class="submit"
               type="submit"
               [attr.aria-label]="'lti.activate_account' | translate"
            >
               {{ 'lti.activate_account' | translate }}
            </button>
         </div>
      </div>
   </div>
</main>
