import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ApiLog {
  url: string;
  method: string;
  requestBody: any;
  response: any;
  timestamp: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ApiLoggerService {
  private apiLogs: BehaviorSubject<ApiLog[]>;
  apiLogs$: Observable<ApiLog[]>;
  private readonly LOG_LIMIT = 50;
  private readonly ENCRYPTION_KEY = 'your-secret-key'; // In a real app, this should be securely stored

  constructor() {
    this.apiLogs = new BehaviorSubject<ApiLog[]>([]);
    this.apiLogs$ = this.apiLogs.asObservable();
  }

  logApi(log: ApiLog) {
    const currentLogs = this.apiLogs.value;
    const updatedLogs = [log, ...currentLogs.slice(0, this.LOG_LIMIT - 1)];
    this.apiLogs.next(updatedLogs);
    
    // Encrypt and store logs
    const encryptedLogs = this.encryptLogs(updatedLogs);
    localStorage.setItem('encryptedApiLogs', encryptedLogs);
  }

  getLatestLogs(count: number = this.LOG_LIMIT): ApiLog[] {
    // Retrieve and decrypt logs
    const encryptedLogs = localStorage.getItem('encryptedApiLogs');
    if (encryptedLogs) {
      const decryptedLogs = this.decryptLogs(encryptedLogs);
      return decryptedLogs.slice(0, count);
    }
    return this.apiLogs.value.slice(0, count);
  }

  clearLogs() {
    this.apiLogs.next([]);
    localStorage.removeItem('encryptedApiLogs');
  }

  private encryptLogs(logs: ApiLog[]): string {
    const logsString = JSON.stringify(logs);
    return this.encrypt(logsString);
  }

  private decryptLogs(encryptedLogs: string): ApiLog[] {
    const decryptedString = this.decrypt(encryptedLogs);
    return JSON.parse(decryptedString);
  }

  private encrypt(text: string): string {
    let result = '';
    for (let i = 0; i < text.length; i++) {
      result += String.fromCharCode(text.charCodeAt(i) ^ this.ENCRYPTION_KEY.charCodeAt(i % this.ENCRYPTION_KEY.length));
    }
    return btoa(result); // Base64 encode the result
  }

  private decrypt(encryptedText: string): string {
    const text = atob(encryptedText); // Base64 decode
    let result = '';
    for (let i = 0; i < text.length; i++) {
      result += String.fromCharCode(text.charCodeAt(i) ^ this.ENCRYPTION_KEY.charCodeAt(i % this.ENCRYPTION_KEY.length));
    }
    return result;
  }
}