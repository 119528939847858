// *** Angular
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

// *** Actions
import * as assignmentSubmissionsActions from '../state/action/assignment-submissions.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';
import { environment } from 'src/environments/environment';
import * as statisticsActions from '../../../../../professor-components/state/statistics/action/statistics.actions'
import * as filtersActions2 from '../../../../../professor-components/state/filters/action/filter.actions';

// *** Selectors
import { getAssignmentSubmissionsDetails } from '../state/selector/assignment-submissions.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';


// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import { PdfExportService } from 'src/app/services/pdf-export.service';
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';
import { CsvExportService } from 'src/app/services/csv-export.service';
import { DatePipe } from '@angular/common';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { forkJoin, from } from 'rxjs';
import { concatMap, mergeMap, map, catchError, filter, retryWhen, delay, take, tap, toArray, timeout } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { UserService } from 'src/app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CourseService } from 'src/app/services/course.service';
import { AssignmentService } from 'src/app/services/assignment.service';
@Component({
   selector: 'app-assignment-submissions',
   templateUrl: './assignment-submissions.component.html',
   styleUrls: ['./assignment-submissions.component.scss'],
})
export class AssignmentSubmissionsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable is used to store facultyId to filter submissions of assignments within specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId to filter submissions of assignments within specific department.
    */
   departmentId = '-1';
   /**
    * Variable used to store submission name to filter submissions by name
    */
   submissionName = '';
   /**
    * Variable used to store assignment name to filter submissions by assignment name
    */
   assignmentName = '';
   /**
    * Varibale used to store student name that used to filter submissions of specific students
    */
   studentName = '';
   /**
    * Variable used to store assignment type that has been used to filter assignments
    */
   assignmentType = '-1';
   /**
    * Variable used to store professor id that used to retrieve assignments of specific professor
    */
   professorId: any;
   /**
    * Variable used to store subject name that has been used to filter assignments by a subject
    */
   subjectName = '';
   getFiltersState = true;
   /**
    * Variable used to store information that a thesis is been clicked for expand or not
    */
   expandInfo: any;
   /**
    * Variable used to store submissions
    */
   submissions;
   /**
    * Variable used to store student id that used to expand specific row on HTML
    */
   studentId;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Variable used to store number of submissions
    */
   submissionsNumber;
   /**
    * Variabels used to retrieve faculties of insitution from store.
    */
   faculties;
   faculties$;
   /**
    * Variabels used to retrieve professors of insitution from store.
    */
   professors;
   professors$;
   getFiltersStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getInstitutionProfessorsStateDetails$: any;
   getAssignmentSubmissionsDetails$: any;
   @ViewChild('contentToCapture') contentToCapture: ElementRef;
   subject = '-1';
   assignment = '-1';
   reports = [
      { name: 'Offline Report', selected: false },
      { name: this.translate.instant('general.submission_files'), selected: false },
    ]
    user;
    courses;
    assignments;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private csvExportService: CsvExportService,
      private excelExportService: ExcelExportService,
      private pdfExportService: PdfExportService,
      private spinner: NgxSpinnerService,
      private administratorStatisticsService: AdministratorStatisticsService,
      private datePipe: DatePipe,
      private http: HttpClient,
      private submissionsService: SubmissionsService,
      private userService: UserService,
      private toastrService: ToastrService,
      private translate: TranslateService,
      private courseService: CourseService,
      private assignmentService: AssignmentService
   ) { }
   ngOnDestroy(): void {
      this.getFiltersStateDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
      this.getAssignmentSubmissionsDetails$.unsubscribe();
   }

   ngOnInit(): void {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.assignmentSubmissionsFilters.faculty != null) {
               this.facultyId = data.assignmentSubmissionsFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (data) => {
                        this.departments = data.departments;
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.assignmentSubmissionsFilters.department != null) {
               this.departmentId = data.assignmentSubmissionsFilters.department;
            }
            if (data.assignmentSubmissionsFilters.subject != null) {
               this.subjectName = data.assignmentSubmissionsFilters.subject;
            }
            if (data.assignmentSubmissionsFilters.assignment != null) {
               this.assignmentName =
                  data.assignmentSubmissionsFilters.assignment;
            }
            if (data.assignmentSubmissionsFilters.student != null) {
               this.studentName = data.assignmentSubmissionsFilters.student;
            }
         });

      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.professors = data.professors;
            }
         });

      this.getAssignmentSubmissionsDetails$ = this.store
         .select(getAssignmentSubmissionsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.submissions = data.assignmentSubmissions;
               this.submissionsNumber = data.submissionsNumber;
            }
         });

         this.courseService.courses(
            '',
            -1,
            '',
            '',
            '',
            '',
            '',
            ''
         ).subscribe((data) => {
            this.courses = data.courses
         })

         this.assignmentService.getAssignments('', '', '', '', '-1').subscribe((data) => {
            this.assignments = data.getAssignments
         })
   }
   /**
    * Function is used to filter by title
    */
   filterWithTimeout() {
      console.log(this.subject)
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: (this.subject !== '-1' && this.subject !== 'All') ? this.subject : '',
            assignment: (this.assignment !== '-1' && this.assignment !== 'All') ? this.assignment : '',
            student: this.studentName !== '-1' ? this.studentName : '',
            submission: this.submissionName,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
      this.changePage(1)
         // this.store.dispatch(
         //    assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
         //       page: this.page,
         //       subjectName: this.subject,
         //       assignmentName: this.assignment,
         //       studentName: this.studentName,
         //       submissionName: this.submissionName,
         //    })
         // );
      }, time);
   }
   /**
    * Function is used to filter by selected Faculty
    */
   selectedFaculty() {
      this.getFiltersState = false;
      this.departmentId = '-1';
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: this.subjectName,
            assignment: this.assignmentName,
            student: this.studentName,
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );

      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter by selected department
    */
   selectedDepartment() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: this.subjectName,
            assignment: this.assignmentName,
            student: this.studentName,
            department: this.departmentId,
            faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }
   /**
    * Function is used to filter by assignment type
    * @param type
    */
   filterByType(type) {
      this.assignmentType = type;
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }
   /**
    * Function is used to exapnd information
    * @param studentId
    */
   expand(studentId) {
      this.studentId = studentId;
      if (this.expandInfo !== studentId) {
         this.expandInfo = studentId;
      } else {
         this.expandInfo = -1;
      }
   }

   goToLink(id: string): void {
      // const currentUserRole = JSON.parse(localStorage.getItem('role'))
      const token = JSON.parse(localStorage.getItem('ACCESS_TOKEN'))

      let url = `${environment.externalReport}/submission/${id}/report/v2/${token}`;
      window.open(url, '_blank');
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.page = 1;
      this.subject = '-1';
      this.submissionName = '';
      this.assignment = '-1';
      this.studentName = '';
      this.assignmentName='';
      this.subjectName = '';
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            subject: this.subjectName,
            assignment: this.assignmentName,
            student: this.studentName,
            submission: this.submissionName
            // department: this.departmentId,
            // faculty: this.facultyId,
         })
      );
      this.store.dispatch(
         assignmentSubmissionsActions.loadAssignmentSubmissionsStatistics({
            page: this.page,
            facultyId: this.facultyId == '-1' ? undefined : this.facultyId,
            departmentId:
               this.departmentId == '-1' ? undefined : this.departmentId,
            submissionName: this.submissionName,
            assignmentName: this.assignmentName,
            studentName: this.studentName,
            assignmentType:
               this.assignmentType == '-1' ? undefined : this.assignmentType,
            subjectName: this.subjectName,
         })
      );
   }

   prepareExportData(data) {
      let rows = [
         ["Submissions"],
         [""],
         [
            "Submission title",
            "Full name",
            "Submission Date",
            "Document language similarity",
            "Translated language similarity",
            "AI Index",
         ],
      ]

      data.data.submissions.forEach(submission => {
         let originalLanguage = submission.originalLanguage !== null ? submission.originalLanguage.toUpperCase() : '--';
         let originalPercentage = submission.originalLanguage !== null ? submission.originalPercentage.toFixed(2) + '%' : '---';
         let translatedLanguage = submission.translatedLanguage !== null ? submission.translatedLanguage.toUpperCase() : '--';
         let translatedPercentage = submission.translatedLanguage !== null ? submission.translatedPercentage.toFixed(2) + '%' : '---';
         rows.push([
            `"${submission.title}"` ,
            submission.user.name,
            this.datePipe.transform(submission.createdAt, 'dd/MM/yyyy hh:mm'),
            originalLanguage + ' ' + originalPercentage,
            translatedLanguage + ' ' + translatedPercentage,
            submission.aiDetection ? submission.aiPercentage : 'N/A',
         ]);
      });

      return rows
   }

   exportToCSV() {
      this.spinner.show();
      this.administratorStatisticsService.assignmentsSubmissionsStatistics(
         '-1',
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId == '-1' ? undefined : this.departmentId,
         this.subjectName,
         this.assignmentType == '-1' ? undefined : this.assignmentType,
         this.submissionName,
         this.assignmentName,
         this.studentName,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.csvExportService.exportToCSV(rows, 'Submissions - Student Submissions Report');
         this.spinner.hide();
      })
   }

   exportToExcel() {
      this.spinner.show();
      this.administratorStatisticsService.assignmentsSubmissionsStatistics(
         '-1',
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId == '-1' ? undefined : this.departmentId,
         this.subjectName,
         this.assignmentType == '-1' ? undefined : this.assignmentType,
         this.submissionName,
         this.assignmentName,
         this.studentName,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.excelExportService.exportToExcel(rows, 'Submissions - Student Submissions Report');
         this.spinner.hide();
      })
   }

   exportToPDF() {
      this.spinner.show()
      if (this.contentToCapture) {
         this.pdfExportService.captureAndExportPDF(this.contentToCapture.nativeElement, 'Submissions - Student Submissions Report.pdf');
         this.spinner.hide()
      } else {
         console.error('Content to capture is not available yet.');
         this.spinner.hide()
      }
   }

   toggleSelection(report: any) {
      report.selected = !report.selected;
    }

    anyReportsSelected(): boolean {
      return this.reports.some(report => report.selected);
    }

    retryWithDelay = (delayMs: number, maxRetry: number) => retryWhen(errors =>
      errors.pipe(
        delay(delayMs),
        take(maxRetry)
      )
    );

    downloadReport(submissionId: string, reportType: string) {
      return this.http.get(
        `${environment.apiUrl}/submission/${submissionId}/offlineReport?reportType=${reportType}&bulk=true`,
        { responseType: 'json' }
      ).pipe(
        timeout(60000), // Cancel the request if it takes longer than 1 minute
        this.retryWithDelay(1000, 3),
        catchError((error) => {
          console.error(`Failed to download report for submission ${submissionId}`, error);
          return of(null);
        })
      );
    }

    downloadAiReport(submissionId: string) {
      return this.http.get(
        `${environment.apiUrl}/submission/${submissionId}/aiOfflineReport?reportType='test'`,
        { responseType: 'json' }
      ).pipe(
        timeout(60000), // Cancel the request if it takes longer than 1 minute
        this.retryWithDelay(1000, 3),
        catchError((error) => {
          console.error(`Error fetching AI report for submission ${submissionId}`, error);
          return of(null);
        })
      );
    }

    downloadOriginalDocument(submissionId: string) {
      return this.http.get(`${environment.apiUrl}/document/${submissionId}`, { responseType: 'json' }).pipe(
        timeout(60000), // Cancel the request if it takes longer than 1 minute
        this.retryWithDelay(1000, 3),
        catchError((error) => {
          console.error(`Error fetching original document for submission ${submissionId}`, error);
          return of(null);
        })
      );
    }

    downloadAll() {
      this.spinner.show();
       this.userService.spinnerSubject.next(true)
       this.userService.messageSubject.next('Hang on this may take a while.')
       this.administratorStatisticsService.assignmentsSubmissionsStatistics(
         '-1',
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId == '-1' ? undefined : this.departmentId,
         this.subjectName,
         this.assignmentType == '-1' ? undefined : this.assignmentType,
         this.submissionName,
         this.assignmentName,
         this.studentName,
       )
        .pipe(
          catchError((error) => {
            console.error('Error fetching submission details for offline export', error);
             this.userService.spinnerSubject.next(false)
             this.userService.messageSubject.next('')
            this.spinner.hide();
            return of(null);
          }),
          mergeMap((response) => {
            if (!response || !response.data.submissions) {
              return of(null);
            }

            const submissions = response.data.submissions;
            const zip = new JSZip();
            let fileCount = 0;
            let processedCount = 0;
            let failedCount = 0;
            const failedSubmissions = [];
            const startTime = new Date().getTime(); // Record the start time

            // Create the report types array based on selected reports
            const reportTypes = [];
            if (this.reports.find((report) => report.name === 'Offline Report' && report.selected)) {
               reportTypes.push({ type: 'crossplag', label: 'Original' });
            }

            if (this.reports.find((report) => report.name === 'Submission files' && report.selected)) {
               reportTypes.push({ type: 'document', label: 'Document' });
            }

            // Calculate total reports dynamically
            let totalReports = 0;
            submissions.forEach((submission) => {
               if (
                  submission.processed !== 1
               ) {
                  return false;
               }
               totalReports++;
               return true;
            });
            // Batch processing
            const batchSize = 5; // Adjust batch size as needed
            const submissionBatches = [];
            for (let i = 0; i < submissions.length; i += batchSize) {
              submissionBatches.push(submissions.slice(i, i + batchSize));
            }

            return from(submissionBatches).pipe(
              concatMap(batch =>
                forkJoin(batch.map((submission: any) =>
                  from(reportTypes).pipe(
                    filter(() => {
                      return submission.processed === 1;
                    }),
                    concatMap((reportType: any) => {
                      let downloadObservable;
                      this.userService.messageSubject.next(`Hang on this may take a while. ${totalReports} files are being generated...`)
                      if (reportType.type === 'document') {
                        downloadObservable = this.submissionsService.downloadOriginalDocument(submission.id).pipe(
                          concatMap((data: any) => {
                            if (data && data.urlToDownload) {
                              return this.http.get(data.urlToDownload, { responseType: 'arraybuffer' }).pipe(
                                map((fileData: ArrayBuffer) => ({
                                  blob: new Blob([fileData], { type: 'application/pdf' }),
                                  filename: `${submission.title}-${reportType.label}.pdf`
                                }))
                              );
                            }
                            return of(null);
                          })
                        );
                      } else {
                        // This is for the 'crossplag' type (Offline Report)
                        downloadObservable = this.downloadReport(submission.id, reportType.type).pipe(
                          concatMap((data: any) => {
                            if (data) {
                              return this.http.get(data.presignedS3UrlDownload, { responseType: 'arraybuffer' }).pipe(
                                map((fileData: ArrayBuffer) => ({
                                  blob: new Blob([fileData], { type: 'application/pdf' }),
                                  filename: `${submission.title}-${reportType.label}.pdf`
                                }))
                              );
                            }
                            return of(null);
                          })
                        );
                      }

                      return downloadObservable.pipe(
                        catchError((error) => {
                          failedCount++;
                          failedSubmissions.push(submission.id);
                          return of(null);
                        })
                      );
                    }),
                    toArray() // Collect all observables for this submission
                  )
                ))
              ),
              tap({
                next: (results: any) => {
                  results.flat().forEach(result => {
                    processedCount++;
                    if (result && result.blob) {
                     const uniqueFilename = this.getUniqueFilename(zip, result.filename);
                     zip.file(uniqueFilename, result.blob);
                     fileCount++;
                    }
                  });
                  this.updateProgress(processedCount, totalReports);
                },
                error: (error) => {
                  console.error('An error occurred during report export', error);
                   this.userService.spinnerSubject.next(false)
                   this.userService.messageSubject.next('')
                  this.spinner.hide();
                },
                complete: async () => {
                  const endTime = new Date().getTime(); // Record the end time
                  const duration = (endTime - startTime) / 1000; // Calculate the duration in seconds
                  console.log(`Total duration: ${duration} seconds`);

                  if(failedCount > 0){
                     const failedIds = 'The documents with the following IDs could not be downloaded:\n\n' + failedSubmissions.join('\n') + '\n\nFor more information on this matter, please contact us at support.originality@inspera.com.';
                     zip.file('failed_submissions.txt', failedIds);
                   }
                   if (fileCount > 0) {
                     console.log('Generating ZIP file...');
                     const content = await zip.generateAsync({ type: 'blob' });
                     saveAs(content, 'reports.zip');
                     console.log('All reports have been bundled into a ZIP file');
                     this.toastrService.success('Your reports are generated successfully.');
                  } else {
                    console.error('No reports were downloaded');
                    this.toastrService.warning('There are no reports that match the filter criteria');
                  }

                  console.log(`Failed to download ${failedCount} reports for submissions: ${failedSubmissions.join(', ')}`);
                   this.userService.spinnerSubject.next(false)
                   this.userService.messageSubject.next('')
                  this.spinner.hide();
                }
              })
            );
          })
        )
        .subscribe();
    }

    updateProgress(processedCount: number, totalReports: number) {
      console.log(`Processed ${processedCount} out of ${totalReports} reports`);
    }

    getUniqueFilename(zip: JSZip, filename: string): string {
      // Sanitize the filename by replacing slashes and other problematic characters
      let sanitizedFilename = filename.replace(/[\/\\:*?"<>|]/g, '_');
      let uniqueFilename = sanitizedFilename;
      let counter = 1;
      while (zip.files[uniqueFilename]) {
        uniqueFilename = sanitizedFilename.replace(/(\.\w+)?$/, `(${counter++})$1`);
      }
      return uniqueFilename;
    }

    openCodeReport(submission: any) {
      const assignmentId = submission.assignments.id
      const userName = submission.author
      const fileName = submission.title
      const language = localStorage.getItem('websiteLanguage')

      this.spinner.show();

      this.assignmentService.getCodePresignedUrl(assignmentId, userName, fileName).subscribe((data) => {
        if (data && data.presignedUrl) {
          const reportUrl = environment.externalCodeReport + '?lang=' + language + '&url=' + data.presignedUrl
          window.open(reportUrl, '_blank');
        }
        this.spinner.hide();
      })
    }
}
