import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Language } from '../../../models/language';
import { LanguageService } from '../../../services/language.service';
// import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
// import { SourceActions } from '../../shared/source_actions/source_actions.component';
// import { SubmissionPreviewComponent } from '../../shared/submission-preview/submission-preview.component';
import { ReportService } from "../../../services/report.service";
import { ActivatedRoute } from "@angular/router";
// import { SpinnerService } from '../../../services/spinner.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SourcePreviewComponent } from 'src/app/sharedModule/report/sourcePreview/source-preview/source-preview.component';


@Component({
  selector: 'app-language-bar',
  templateUrl: './language-bar.component.html',
  styleUrls: ['./language-bar.component.scss'],
  providers: [MatProgressBarModule]
})
export class LanguageBar implements OnInit, OnChanges, OnDestroy {
  title = 'Language Bar';
  visibleSourcesCount: number = 4;
  @Input() isOnAnalysis!: boolean;
  @Input() similarityAnalysis: any;
  @Input() canEdit: boolean
  languages: Language[] = [];
  document_languages: any[] = [];
  isMenuOpen: boolean = false;
  isSummaryMenuOpen: boolean = false;
  // ref: DynamicDialogRef | undefined;
  submissionId: string = ''
  selectedLanguageType: string = 'Original'
  selectedFilterText: string = 'Matching sources'
  selectedSources: number[] = []
  percentageWithQuotes = true
  showTopSources: number | null = null
  transLangs = [];
  currentTranslateLanguage: string = '';
  currentLanguage: string = '';
  isDocumentTranslated: boolean = false;
  topTranslatedSources: any[] = [];
  topOriginalSources: any[] = [];
  initialSources: any[] = [];

  filteredTranslatedSources: any[] = [];
  filteredOriginalSources: any[] = [];
  filteredSources: any[] = [];
  selectedLanguageCode;

  private destroy$ = new Subject<void>();
  originalPercentage: number;
  overallTransltedPercentage: number;
  originalPercentageWithoutQuotes:number
  overallTransltedPercentageWithoutQuotes:number;
  constructor(
    private reportService: ReportService,
    private languageService: LanguageService,
    // private dialogService: DialogService,
    // private spinnerService: SpinnerService,
    private acRouter: ActivatedRoute,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.reportService.currentTranslatedLanguageObject.subscribe((data) => {
      this.currentTranslateLanguage = data;
    })

    this.reportService.documentPercentagesObject.subscribe((percentages: { originalPercentage: number, overallTransltedPercentage: number, originalPercentageWithoutQuotes: number, overallTransltedPercentageWithoutQuotes: number })=>{
      this.originalPercentage = percentages.originalPercentage
      this.overallTransltedPercentage = percentages.overallTransltedPercentage

      this.originalPercentageWithoutQuotes = percentages.originalPercentageWithoutQuotes
      this.overallTransltedPercentageWithoutQuotes = percentages.overallTransltedPercentageWithoutQuotes
    })

    this.reportService.selectedTransltaionCodeObject.subscribe((data)=>{
      if(data !== null){
        this.selectedLanguageCode = data
      }
    })

    this.reportService.toggleQuotesObject.subscribe((data: boolean) => {
      this.percentageWithQuotes = data
    })

    this.acRouter.params.subscribe((data: any) => {
      this.submissionId = data.id
    })

    if (this.languages.length == 0) {
      this.languageService.getLanguages().subscribe((languages) => {
        this.languages = languages;
        this.updateDocumentLanguages()
      });
    } else {
      this.updateDocumentLanguages();
    }
    document.addEventListener('click', (event) => this.handleGlobalClick(event));

    this.reportService.selectLanguageObject.subscribe((data) => {

      this.selectedLanguageType = data.type
      this.currentLanguage = data.language
      this.languageService.getLanguageCode(this.currentLanguage).subscribe((data) => {
        if(data !== null && data.length !== 0){
          this.reportService.selectedTransltaionCodeSubject.next(data)
        }
      })
      let language = data

      if (this.document_languages.length > 0) {
        if (this.document_languages.length <= 2) {
          if (language.type == 'Original') {
            this.toggleSources(this.document_languages[0]);
          } else {
            this.toggleSources(this.document_languages[1]);
          }
        } else {
          if (language.language == 'multiple_languages') {
            // Get the  translated  language with the highest percentage
            let  highestLang = this.document_languages[1];
            // Toggle that language
            this.toggleSources(highestLang);
          } else {
            for (let i = 0; i < this.document_languages.length; i++) {
              const lang = this.document_languages[i];
              if (lang.type == data.type && !lang.isOpen && !lang.sourcesClosed) {
                this.toggleSources(lang);
                break;
              }
              else {
                if (language.type == 'Original') {
                  this.toggleSources(this.document_languages[0]);
                }
              }
            }
          }

        }
      }
    })
  }

  handleGlobalClick(event: Event): void {
    // Check if the clicked element is inside the menubar or the button that opens the menubar
    const isMenubarElement = (event.target as HTMLElement).closest('.sources_menu') !== null;
    const isMenuBarButton = (event.target as HTMLElement).closest('.sources_menu_btn') !== null;

    // If the clicked element is outside the menubar, close the menu bar
    if (!isMenubarElement && !isMenuBarButton) {
      this.closeMenu();
    }
  }

  selectSourceToHighlight(sourceNo: number) {
    const index = this.selectedSources.indexOf(sourceNo);
    index === -1 ? this.selectedSources.push(sourceNo) : this.selectedSources.splice(index, 1);
    this.reportService.selectedSourcesSubject.next(this.selectedSources);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes['similarityAnalysis'] &&
      changes['similarityAnalysis'].currentValue
    ) {
      let translatedLangs;
      if (this.similarityAnalysis) {
        if (this.similarityAnalysis.submission?.translatedLanguage && this.similarityAnalysis.submission?.translatedLanguage.split('').includes('[')) {
          translatedLangs = JSON.parse(this.similarityAnalysis.submission.translatedLanguage);
        } else {
          translatedLangs = [this.similarityAnalysis.submission?.translatedLanguage];
        }
      }

      this.isDocumentTranslated = translatedLangs.length > 0;


      this.updateDocumentLanguages();

    // Get top translated sources
    if (this.similarityAnalysis.translatedSources) {
      if(this.initialSources.length == 0){
        this.initialSources = this.similarityAnalysis.translatedSources;
      }

      this.topTranslatedSources = this.getTopTranslatedSources(this.similarityAnalysis.translatedSources);
    }

    if (this.similarityAnalysis.originalSources) {
      this.topOriginalSources = this.getTopOriginalSources(this.similarityAnalysis.originalSources);
    }

    // Get top original sources
      this.filterSources(0)
    }
  }

  getTopTranslatedSources(translatedSources: any[], topN: number = 5): any[] {
    // Flatten all sources from all languages and include the language code
    let allSources: any[] = [];

    translatedSources?.forEach((lang: any) => {
      if (Array.isArray(lang)) {
        (lang as any).sources.forEach((source: any) => {
            allSources.push({
              ...source,
              languageCode: (lang as any).language
            });
        });
      } else {
        if (lang.sources && Array.isArray(lang.sources)) {
          lang.sources.forEach((source: any) => {
              allSources.push({
                ...source,
                languageCode: lang.language
              });
          });
        } else {
            allSources.push(lang);
        }
      }
    });

    // Sort sources by percentage and take the top N
    allSources.sort((a, b) => b.percentage - a.percentage);
    return allSources.slice(0, topN);
  }

  getTopOriginalSources(originalSources: any[], topN: number = 5): any[] {
    let allSources: any[] = [];
    originalSources.forEach((source: any) => {
      allSources.push(source);
    });

    // // Sort sources by percentage and take the top N
    allSources.sort((a, b) => b.percentage - a.percentage);
    return allSources.slice(0, topN);
  }


  startDocumentPreview(id: string, index) {

    if(!this.canEdit) {
        return
    }
    this.spinnerService.show()
    this.reportService
      .submissionPreview(
        id,
        this.submissionId,
        index.languageCode
      )
      .pipe()
      .subscribe(
        (data) => {
          this.spinnerService.hide()

          this.dialog.open(SourcePreviewComponent, {
            data,
            width: '60%',
            height: '80%',
          });
        },
        (error) => {
          this.spinnerService.hide()
        }
      );
  }

  updateDocumentLanguages() {
    // Update document_languages based on similarityAnalysis
    const tempLanguages = [...this.document_languages]
    this.document_languages = [];
    const addLanguage = (type: string, languageCode: string, sourcesClosed: boolean) => {
      const language = languageCode ? this.getLanguageName(languageCode) : null;
      let percentage = this.similarityAnalysis.submission?.[`${type.toLowerCase()}Percentage`];
      let percentageWithoutQuotes = this.similarityAnalysis.submission?.[`${type.toLowerCase()}PercentageWithoutQuotes`];

      if (type == 'Translated' && this.similarityAnalysis.submission.translatedPercentages != null) {
          percentage = this.similarityAnalysis?.submission?.translatedPercentages?.[languageCode]?.percentage || 0;
          percentageWithoutQuotes = this.similarityAnalysis?.submission?.translatedPercentages?.[languageCode]?.percentageWithoutQuotes || 0;
      }

      const totalSources = this.similarityAnalysis?.[`${type.toLowerCase()}Sources`];
      const hideLoadMoreButton = !language || !totalSources || totalSources.length <= this.visibleSourcesCount;

      if (language) {
        this.document_languages.push({
          type,
          language,
          languageCode,
          percentage: percentage || 0,
          percentageWithoutQuotes,
          sourcesClosed: type === 'Translated',
          visibleSourcesCount: this.visibleSourcesCount,
          hideLoadMoreButton,
          totalSources,
          isOpen: false
        });
      }
      console.log('this.document_languages', this.document_languages)
    };

    addLanguage('Original', this.similarityAnalysis?.submission?.originalLanguage, false);
    if (this.similarityAnalysis?.submission?.translatedLanguage) {
      let translatedLanguages;

      if (this.similarityAnalysis?.submission?.translatedLanguage.split('').includes('[')) {
        translatedLanguages  = JSON.parse(this.similarityAnalysis?.submission?.translatedLanguage);
      } else {
        translatedLanguages = [this.similarityAnalysis?.submission?.translatedLanguage];
      }

      translatedLanguages.forEach((lang: string) => {
        const language = this.getLanguageName(lang);
        if (language !== 'Unknown Language' && !this.transLangs.includes(language)) {
          this.transLangs.push(language);
        }

        addLanguage('Translated', lang, true);
      });
    }

    let originalLanguages = this.document_languages.filter(lang => lang.type === 'Original');
    let translatedLanguages = this.document_languages
      .filter(lang => lang.type === 'Translated')
      .sort((a, b) => b.percentage - a.percentage);

    // Retain the isOpen and sourcesClosed values from tempLanguages
    tempLanguages.forEach((tempLang) => {
      let matchingLang;
      if (tempLang.type === 'Original') {
        matchingLang = originalLanguages.find(lang => lang.languageCode === tempLang.languageCode);
      } else if (tempLang.type === 'Translated') {
        matchingLang = translatedLanguages.find(lang => lang.languageCode === tempLang.languageCode);
      }

      if (matchingLang) {
        matchingLang.isOpen = tempLang.isOpen;
        matchingLang.sourcesClosed = tempLang.sourcesClosed;
      }
    });

    // Combine updated original and translated languages into document_languages
    this.document_languages = [...originalLanguages, ...translatedLanguages];

    // Ensure that changes are reflected in the template
    this.cdr.detectChanges();

    console.log('Updated document_languages:', this.document_languages);
  }

  public getLanguageName(languageCode: string): string {
    const language = this.languages.find((lang) => lang.value === languageCode);
    return language ? language.text : 'Unknown Language';
  }

  toggleSources(language: any, event?: Event) {
    if (this.currentLanguage == 'multiple_languages') {
      this.languageService.getLanguageName(language.languageCode).subscribe((data) => {
        this.currentLanguage = data
        language.language = data
       });
    }

    if (event) {
      event.stopPropagation();
    }
    if (language.languageCode){
      this.reportService.selectedTransltaionCodeSubject.next(language.languageCode)
    }

    // Close all other languages' sources
    // this.document_languages.forEach((lang) => {
    //   if (lang.languageCode !== language.languageCode) {
    //     lang.sourcesClosed = true;
    //     lang.isOpen = false;
    //   }
    // });

    language.isOpen = true;
    language.sourcesClosed = false;

    if (language.type == 'Translated') {
      this.reportService.currentTranslatedLanguageSubject.next(language.languageCode);
    }

    this.selectedSources = [];
    this.document_languages.forEach((lang) => {
      if (lang.languageCode !== language.languageCode) {
        lang.sourcesClosed = true;
        lang.isOpen = false;
      }
    });
    try {
      if (this.currentLanguage != language.language && this.selectedLanguageType !== language.type) {
        this.currentLanguage = language.language;
        this.selectedLanguageType = language.type;
        this.reportService.selectedSourcesSubject.next([]);
        this.reportService.selectLanguageSubject.next({
          language: language.language,
          type: language.type
        });
      }
    } catch(e) {
      console.log('error', e);
    }

    this.selectedFilterText = 'Matching sources';
    this.cdr.detectChanges();
  }

  toggleOffAllSources() {
    this.document_languages.forEach((lang) => {
      lang.sourcesClosed = true;
      lang.isOpen = false;
    });
  }

  loadMoreSources(language: any): void {
    const totalSources = this.similarityAnalysis[`${language.type.toLowerCase()}Sources`];
    if (language.visibleSourcesCount > totalSources.length) {
      language.hideLoadMoreButton = true;
      this.shouldShowLoadMoreButton(language);
    } else {
      language.visibleSourcesCount += 4;
    }
  }

  shouldShowLoadMoreButton(language: any): boolean {
    return !language.hideLoadMoreButton;
  }

  closeMenu() {
    this.isMenuOpen = false;
    this.isSummaryMenuOpen = false;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleSummaryMenu() {
    this.isSummaryMenuOpen = !this.isSummaryMenuOpen;
  }

  filterSources(param: number) {
    let sources;

    this.selectedFilterText = param === 0 ? 'Matching sources' : param === 1 ? 'Sources from documents' : param === 2 ? 'Sources from internet' : param === 3 ? 'Removed sources' : 'Sources below 1%';
    this.reportService.filterSourcesSubject.next(param);

    this.reportService.selectAnalysisTypeObject.subscribe((analysisType) => {
      if (analysisType == 'none') {

        if (this.selectedLanguageType === 'Original') {
          sources = this.topOriginalSources;
        } else if (this.selectedLanguageType === 'Translated') {
          sources = this.topTranslatedSources;
        }

        if (param === 0) {
          this.filteredSources = sources.filter((source) => source.percentage > 1 && !source.isExcluded);
          if (this.filteredSources.length === 0) {
            this.filteredSources = sources.filter((source) => source.percentage <= 1 && !source.isExcluded);
          }
        } else if (param === 1 || param === 2) {
          this.filteredSources = sources.filter((source) => (source.type === param && !source.isExcluded));
        } else if (param === 3) {
          this.filteredSources = sources.filter((source) => source.isExcluded);
        } else if (param === 4) {
          this.filteredSources = sources.filter((source) => (source.percentage < 1 && !source.isExcluded));
        }
      }
    });
  }


  sourceAction(source_no: string,
    source_url: string,
    excludeUrlId: string,
    exclude: boolean,
    excludeUrlPercentage: number,
    language?: string) {
    const action = exclude ? 'Remove' : 'Add'
    const originalLanguage = this.selectedLanguageType === 'Original'
    swal.fire({
      title: `${action} Source`,
      text: exclude ? this.translate.instant('report.are_you_sure_you_want_to_exclude_this_source') : this.translate.instant('report.are_you_sure_you_want_to_include_this_source'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${action} Source`
    }).then((result) => {

      if (result.isConfirmed) {
        this.spinnerService.show();
        if (action == 'Remove') {
          this.reportService.excludeSource(excludeUrlId, this.submissionId, originalLanguage, exclude, excludeUrlId, excludeUrlPercentage, language)
            .subscribe((data: any) => {
              this.toastr.success('Successfully removed the source!', 'Source removed', { timeOut: 2000 });
              this.reportService.newS3JsonSubject.next(data[0]);

              // const langToToggle = this.document_languages.find(lang => lang.languageCode === language);
              // if (langToToggle) {
              //   this.toggleSources(langToToggle);
              // }
              // this.cdr.detectChanges();
              this.spinnerService.hide();
            })
        } else {
          this.reportService.includeSource(excludeUrlId, this.submissionId, originalLanguage, excludeUrlPercentage, language)
            .subscribe((data: any) => {
              this.toastr.success('Successfully added the source!', 'Source added', { timeOut: 2000 });
              this.reportService.newS3JsonSubject.next(data[1])

              const langToToggle = this.document_languages.find(lang => lang.languageCode === language);
              if (langToToggle) {
                this.toggleSources(langToToggle);
              }
              this.cdr.detectChanges();
              this.spinnerService.hide();
            })
        }
      } else if (result.isDenied) {

        swal.fire('Changes are not saved', '', 'info')
      }
    });
  }

  toggleTopSources(index, lang) {
    this.showTopSources = this.showTopSources === index ? null : index
    // this.reportService.selectedSourcesSubject.next([])
    // this.reportService.selectLanguageSubject.next({ language: lang.language, type: lang.type })
    // this.selectedFilterText = 'Matching sources';
    this.filterSources(0)
    for (const otherLang of this.document_languages) {
      if (otherLang !== lang.language) {
        otherLang.sourcesClosed = true
        otherLang.isOpen = false
      }
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.handleGlobalClick);

    this.destroy$.next();
    this.destroy$.complete();
  }
}
