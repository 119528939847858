<section class="code-similarity-check">
  <section class="code-similarity-check-header">
    <h1>Code Similarity Check</h1>
    <button (click)="closeDialog()">
      <img src="../../../../assets/report_v2/close_big.svg" alt="close">
    </button>
   </section>
   <section class="code-similarity-check-container">
    You are initiating the code similarity {{ type == 'check' ? 'check' : 'recheck' }} for this assignment. Please note that this process may take a few moments to complete. Click <strong>"Initiate Code Similarity {{ type == 'check' ? 'Check' : 'Recheck' }}"</strong> to proceed.
   </section>
   <section class="code-similarity-check-buttons">
    <button (click)="returnNo()" class="secondary-button">Cancel</button>
    <button (click)="returnYes()" class="button-primary">Initiate Code Similarity {{ type == 'check' ? 'Check' : 'Recheck' }}</button>
   </section>
</section>
