<main class="log-in" role="main" tabindex="-1">
   <div class="left-side" role="complementary">
      <img src="../../assets/login/login-bg-new.png" alt="Login background image" tabindex="0" />
      <div>
         <h1 tabindex="0">
            {{ 'login.pioneering_originality_in_the_wordl_of_ai' | translate }}
         </h1>
         <p tabindex="0" [innerHtml]="'login.crossplag_description' | translate"></p>
      </div>
      <h6 tabindex="0">© {{ 'general.rights_reserved' | translate }} - Inspera AS</h6>
   </div>
   <div class="right-side" role="region">
      <div class="form-container">
        <div class="inspera-logo-container">
         <img src="../../assets/login/inspera-login-logo.png" class="inspera-logo" [attr.alt]="'login.inspera_logo' | translate" tabindex="0" />
         <app-translate></app-translate>
        </div>

         <h3 id="resetPasswordHeader" tabindex="0">{{ 'general.reset_password' | translate }}</h3>
         <div>
            <p tabindex="0">{{ 'login.reset_link' | translate }}</p>
         </div>
         <div [formGroup]="forgotForm" role="form" aria-labelledby="resetPasswordHeader">
            <input
               formControlName="email"
               [placeholder]="'placeholders.email' | translate"
               [attr.aria-label]="'placeholders.email' | translate"
               type="email"
               aria-required="true"
               [attr.aria-invalid]="FormValues.email.invalid && FormValues.email.touched"
            />
         </div>
         <div
            *ngIf="FormValues.email.touched && FormValues.email.invalid"
            class="alert alert-danger"
            role="alert"
            aria-live="polite"
         >
            <div class="invalid" *ngIf="FormValues.email.errors.required" tabindex="0">
               {{ 'validators.enter_email' | translate }}
            </div>
            <div class="invalid" *ngIf="FormValues.email.errors.email" tabindex="0">
               {{ 'validators.valid_email' | translate }}
            </div>
         </div>

         <div>
            <button 
               (click)="submit()" 
               class="submit" 
               type="submit"
            >
               {{ 'general.reset_password' | translate }}
            </button>
         </div>
      </div>
   </div>
</main>
