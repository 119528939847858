import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpComponent } from '../authModule/signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginComponent } from '../authModule/login/login.component';
import { StoreModule } from '@ngrx/store';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { fromLtiActivization } from './from-lti-activization/from-lti-activization.component';
import { authReducer } from './state/authentication.reducer';
import { TermsAndConditionsComponent } from './signup/terms-and-conditions/terms-and-conditions.component';
import { MatDialogModule } from '@angular/material/dialog';
import {EmailConfirmationSendComponent} from './signup/email-confirmation-send/email-confirmation-send.component'

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { LtiLoginComponent } from './lti-login/lti-login.component';
import { AuthCallbackComponent } from '../auth-callback/auth-callback.component';
import { SharedModule } from '../sharedModule/shared.module';
const authRoutes: Routes = [
   { path: '', pathMatch: 'full', redirectTo: 'login' },
   { path: 'login', component: LoginComponent },
   { path: 'login/:institutionName/:role', component: LoginComponent },
   { path: 'auth-callback', component: AuthCallbackComponent },
   // { path: 'sign-up', component: SignUpComponent },
   // { path: 'sign-up/:profile', component: SignUpComponent },
   { path: 'forgot-password', component: ForgotPasswordComponent },
   { path: 'activate-account', component: fromLtiActivization },
   { path: 'account-verify/:token', component: AccountVerificationComponent },
   { path: 'email-confirmation/:email', component: EmailConfirmationSendComponent },
   {
      path: 'account-verify/:token/:provider',
      component: AccountVerificationComponent,
   },
   {
      path: 'lti/:token',
      component: LtiLoginComponent,
   },
];

@NgModule({
   declarations: [
      SignUpComponent,
      LoginComponent,
      ForgotPasswordComponent,
      AccountVerificationComponent,
      TermsAndConditionsComponent,
      fromLtiActivization
      ],
   imports: [
      CommonModule,
      MatCheckboxModule,
      MatInputModule,
      FormsModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatDialogModule,
      TranslateModule,
      RecaptchaModule,
      RecaptchaFormsModule,
      RouterModule.forChild(authRoutes),
      // we are using for Feature now beacause this is a featuer and to setup a feature we use a key and also the reducer file
      StoreModule.forFeature('user', authReducer),
      SharedModule

   ],
   exports: [TranslateModule,],
})
export class AuthmoduleModule {}
