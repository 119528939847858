import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import { UploadfileService } from './service/uploadfile.service';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../services/auth.service';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import * as userActions from '../../../authModule/state/actions/authentication.actions';
import { AssignmentService } from 'src/app/services/assignment.service';
import { TranslateService } from '@ngx-translate/core';
import validator from 'validator';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { S3 } from 'aws-sdk';
import { from } from 'rxjs';
import { CodePreviewComponent } from '../code-preview/code-preview.component';
/**
 * Component used to upload files
 */
@Component({
   selector: 'app-upload-file',
   templateUrl: './upload-file.component.html',
   styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnDestroy {
   separatorKeysCodes: number[] = [ENTER, COMMA];
   includeUrlsArray: string[] = [];
   excludeUrlsArray: string[] = [];
   uploaded = 0;
   /**
    * Used to store uploaded file.
    */
   fileToUpload: File = null;
   /**
    * Array of uploaded files.
    */
   files = [];
   /**
    * Variables used to allow/deny user document upload.
    */
   allowToSubmitDocument: boolean = true;
   /**
    * Used to check if used trial has ended or not.
    */
   trial: boolean = false;
   /**
    * User document preview url.
    */
   previewSrc: any;
   /**
    * Upload from used to get user input from forms.
    */
   uploadForm: any;
   /**
    * Language that user want to check their document similarity in a translated language.
    */
   documentTranslatedLanguage: string = undefined;

   /**
    * Key that used to generate preview url for document preview.
    */
   previewKey: any;
   /**
    * Boolean value used to show/hide advanced options of the form.
    */
   advancedOptionsIndex: boolean = false;
   /**
    * Boolean value used to get user consent to archive the file.
    */
   archive: boolean = false;
   /**
    * Document citation style picked by user.
    */
   citationStyle: string = '';
   /**
    * Language of the document selected by user.
    */
   languageSelected: boolean = false;
   /**
    * List of the available languages.
    */

   submitClicked = false;

   languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
      {
         text: 'Danish',
         value: 'da'
      },
      {
         text: 'Estonian',
         value: 'et'
      }
   ];
   /**
    * List of third party libraries to check for document.
    */
   thirdPartyLib: Array<any> = [
      {
         description: 'ScienceDirect',
         isChecked: false,
         information:
            'Searching ScienceDirect content may involve delays on checking your document.',
      },
      {
         description: 'Scopus',
         isChecked: false,
         information:
            'Searching Scopus metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CORE',
         isChecked: false,
         information:
            'Searching CORE content may involve delays on checking your document. ',
      },
      {
         description: 'IEEE',
         isChecked: false,
         information:
            'Searching IEEE metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'arXiv.org',
         isChecked: false,
         information:
            'Searching arXiv metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Crossref',
         isChecked: false,
         information:
            'Searching Crossref metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'Europeana',
         isChecked: false,
         information:
            'Searching Europeana metadata and abstracts may involve delays on checking your document.',
      },
      {
         description: 'CaseLaw Access Project',
         isChecked: false,
         information:
            'Searching CaseLaw content may involve delays on checking your document.',
      },
   ];

   thirdPartyLibraries: any = this.thirdPartyLib;

   uploadedFile: any;
   /**
    * Used to check if the file is converted from docx to pdf.
    */
   converted: any;

   /**
    * Is the current pdf producer allowed
    */
   allowedProducer;
   /**
    * User from store
    */
   user;
   /**
    * User Subscriber
    */
   user$;
   documentCredits;
   disableField: boolean = false;
   originalLanguage: any;
   translatedLanguage: any;
   assignmentId: any;
   thesisId: any;
   assignmentName: any;
   thesisName: any;
   submission_word_limit:any
   assignmentType: string = 'document';
   isCodeFile: boolean = false;
   codeFileContent: string = '';
   requiredFileType: string = '';
   currentFileContent: string = '';
   codeLanguage: string = '';
   lineCount: number = 0;

   @ViewChild(CodePreviewComponent) codePreview: CodePreviewComponent;

   allFileTypes = [
    { text: 'java', value: ['text/x-java', 'text/java', 'application/java', 'application/x-java', 'application/x-java-source', 'java', 'jav'] },
    { text: 'python', value: ['text/x-python', 'text/python3', 'application/x-python-code', 'application/x-python', 'python', 'py', 'pyc', 'pyo', 'pyd', 'py3', 'python3'] },
    { text: 'c', value: ['text/x-c', 'text/c', 'application/x-c', 'application/x-csrc', 'c', 'h'] },
    { text: 'cpp', value: ['text/x-c++', 'text/cpp', 'application/x-c++', 'application/x-c++src', 'cpp', 'cc', 'cxx', 'hpp', 'hxx', 'hh'] },
    { text: 'csharp', value: ['text/x-csharp', 'text/csharp', 'application/x-csharp', 'application/x-csharp-source', 'csharp', 'cs'] },
    { text: 'javascript', value: ['text/javascript', 'text/js', 'application/javascript', 'application/x-javascript', 'js', 'mjs', 'javascript'] },
    { text: 'typescript', value: ['text/typescript', 'text/ts', 'application/typescript', 'application/x-typescript', 'ts', 'tsx', 'typescript'] },
    { text: 'go', value: ['text/x-go', 'text/golang', 'application/x-go', 'application/x-golang', 'go', 'golang'] },
    { text: 'kotlin', value: ['text/x-kotlin', 'text/kotlin', 'application/x-kotlin', 'application/x-kotlin-source', 'kotlin', 'kt', 'kts', 'gradle.kts'] },
    { text: 'rlang', value: ['text/x-r', 'text/r', 'application/x-r', 'application/x-r-source', 'r', 'R', 'rlang'] },
    { text: 'rust', value: ['text/x-rust', 'text/rust', 'application/x-rust', 'application/x-rust-source', 'rust', 'rs'] },
    { text: 'swift', value: ['text/x-swift', 'text/swift', 'application/x-swift', 'application/x-swift-source', 'swift', 'swiftpm'] },
    { text: 'scala', value: ['text/x-scala', 'text/scala', 'application/x-scala', 'application/x-scala-source', 'scala', 'sbt', 'sc'] },
    { text: 'text', value: ['text/x-text', 'text/text', 'text/plain', 'txt', 'text'] },
   ]
   /**
    * Component constructor
    * @param fileUpload
    * @param fb
    * @param spinner
    * @param authService
    * @param submissionService
    */
   constructor(
      private fileUpload: UploadfileService,
      private fb: FormBuilder,
      private spinner: NgxSpinnerService,
      private authService: AuthService,
      private submissionService: SubmissionsService,
      private toastrService: ToastrService,
      private router: Router,
      private route: ActivatedRoute,
      private store: Store,
      private assignmentService:AssignmentService,
      public translate: TranslateService
   ) {}
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {
      this.uploadForm = this.fb.group({
         assignmentId: [],
         thesisId: [],
         documentTitle: [
            '',
            [
               Validators.minLength(3),
               Validators.maxLength(190),
               Validators.required,
            ],
         ],
         fullName: ['', [Validators.required]],
         dateOfUpload: ['', [Validators.required]],
         documentSize: ['', [Validators.required]],
         documentCredits: ['', [Validators.required]],
         documentLanguage: ['', this.isCodeFile ? [] : [Validators.required]],
         documentWordCount: [''],
         documentTranslatedLanguage: [''],
         aiDetection: [''],
         thirdPartyLibs: this.fb.array(this.thirdPartyLib),
         contextualSimilarity: [false],
         enableThresholds: [false],
         exactMatchThreshold: [0],
         alteredTextThreshold: [0],
         contextualSimilaritiesThreshold: [0],
         sources: this.fb.group({
            includeSources: this.fb.array([]),
            excludeSources: this.fb.array([]),
         }),
      });

      this.assignmentId = this.route.snapshot.queryParams['assignmentId'];
      this.thesisId = this.route.snapshot.queryParams['thesisId'];
      this.thesisName = this.route.snapshot.queryParams['thesisName'];

      this.assignmentService.getAssignment(this.assignmentId).subscribe((data)=>{
        console.log(data, 'data assignment');
        console.log(this.allFileTypes, 'allFileTypes');

        this.requiredFileType = this.allFileTypes.find(fileType => fileType.value.includes(data.fileType))?.text;
      })

      if (this.route.snapshot.queryParams['assignmentName']){
         this.assignmentName = this.route.snapshot.queryParams['assignmentName'];

      }else{
         this.assignmentService.getAssignment(this.assignmentId).subscribe((data)=>{
            if(data.submit){
              // console.log(data, 'assignment data');

               this.assignmentName = data.name;
               this.assignmentType = data.assignmentType;
               this.requiredFileType = this.allFileTypes.find(fileType => fileType.value.includes(data.fileType))?.text;
            }else{
               this.router.navigateByUrl('student/statistics/submissions-statistics/assignments')
            }
         })
      }

      console

      this.store.dispatch(userActions.currentUser());



      if (this.assignmentId) {
         this.uploadForm.controls['fullName'].disable();
         this.form.assignmentId.setValue(this.assignmentId);
         this.fileUpload
            .getThesisAssignmentLanguages(this.assignmentId, 'assignments')
            .pipe(first())
            .subscribe(
               (data) => {
                  this.originalLanguage = data.originalLanguage;
                  this.translatedLanguage = data.translatedLanguage;
               },
               (error) => {
                  this.spinner.hide();
                  console.log('error1', error);
               }
            );
      }
      if (this.thesisId) {
         this.uploadForm.controls['fullName'].disable();
         this.form.thesisId.setValue(this.thesisId);
         this.fileUpload
            .getThesisAssignmentLanguages(this.thesisId, 'thesis')
            .pipe(first())
            .subscribe(
               (data) => {
                  this.originalLanguage = data.originalLanguage;
                  this.translatedLanguage = data.translatedLanguage;
               },
               (error) => {
                  this.spinner.hide();
                  console.log('error1', error);
               }
            );
      }
      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null && data !== undefined) {
            this.user = data;

            if (this.user.allowedLanguages !== null) {
               this.disableField = true;
            }
         }
      });
   }

   addUrlOnEnter(event: KeyboardEvent, type: string): void {
      const inputElement = event.target as HTMLInputElement;
      const value = inputElement.value.trim();
      if (value) {
          if (validator.isURL(value, { require_protocol: false })) {
              const control = this.fb.control(value);

              if (type === 'include') {
                  (this.form.sources.get('includeSources') as FormArray).push(control);
                  this.includeUrlsArray.push(value);
              } else if (type === 'exclude') {
                  (this.form.sources.get('excludeSources') as FormArray).push(control);
                  this.excludeUrlsArray.push(value);
              }
          } else {
              this.toastrService.error(`Invalid URL: ${value}`);
          }
      }

      // Clear the input value after adding the URL
      inputElement.value = '';
   }

   removeUrl(index: number, type: string): void {
   if (type === 'include') {
      (this.form.sources.get('includeSources') as FormArray).removeAt(index);
         this.includeUrlsArray.splice(index, 1);
   } else if (type === 'exclude') {
      (this.form.sources.get('excludeSources') as FormArray).removeAt(index);
         this.excludeUrlsArray.splice(index, 1);
      }
   }

   /**
    * Method used to trigger file upload.
    */
   upload() {
      $('.dropzone').trigger('click');
   }
   /**
    * Method used to upload, update & preview file at upload form.
    */
   onFileSelected(event: any) {
    this.fileToUpload = <File>event.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target?.result;
      this.currentFileContent = fileContent as string;
      this.lineCount = this.getLineCount(this.currentFileContent);
    };
    reader.readAsText(this.fileToUpload);
    const uploadedFileType = this.allFileTypes.find(fileType => fileType.value.includes(this.fileToUpload.name.split('.').pop()))?.text;
    console.log(uploadedFileType, 'uploadedFileType');
    console.log(this.requiredFileType, 'requiredFileType');
    this.codeLanguage = uploadedFileType;
    if (uploadedFileType !== this.requiredFileType) {
      swal.fire({
        title: 'File type mismatch',
        html: `<p>The file type you uploaded is not the same as the required file type. Please upload a file with the following file type: <strong>${this.requiredFileType}</strong></p>`,
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#F3001E',
      })
    } else {
      const firstName = this.user?.name.split(' ')[0];
      const lastName = this.user?.name.split(' ')[1];
      this.form.fullName.setValue(this.user?.name);
      let fileSize = this.formatBytes(this.fileToUpload.size);
      this.form.documentSize.setValue(fileSize);
      if (this.assignmentId || this.thesisId) {
         this.form.documentLanguage.setValue(this.originalLanguage);
         this.form.documentTranslatedLanguage.setValue(this.translatedLanguage);
      }
      this.form.documentTitle.setValue(this.fileToUpload.name.split('.')[0]);
      let date = new Date();
      let dateString =
         date.getDate() +
         '/' +
         (date.getMonth() + 1) +
         '/' +
         date.getFullYear();
      this.form.dateOfUpload.setValue(dateString);
      let type;

      const fileExtension = this.fileToUpload.name.split('.').pop();
      if(!this.allFileTypes.some(fileType => fileType.value.includes(fileExtension))) {
        if(!this.fileToUpload.type) {
          type = this.fileToUpload.name.split('.')[this.fileToUpload.name.split('.').length-1]
       } else {
          type = this.fileToUpload.type
       }
      } else {
        type = fileExtension;
      }

      this.spinner.show();
      this.fileUpload
         .generatePresignedURL(type, firstName, lastName, this.assignmentId, this.form.documentTitle.value)
         .pipe(first())
         .subscribe(
            async(data) => {
              if (data.isCodeFile) {
                this.isCodeFile = true;
                this.uploaded = 1;
                this.spinner.hide();
                const fullName = this.user?.name ? this.user?.name.split(' ').join('_') : (firstName + '_' + lastName);
                const documentTitle = data.documentTitle.split(' ').join('_');
                const key = `code_similarity/${data.assignmentId}/${fullName}__${documentTitle}/${fullName}__${documentTitle}/${fullName}__${documentTitle}/submission/submission.${data.fileType}`
                this.uploadedFile = key;
                // this.fileUpload.uploadfileAWSS3(data.presignedS3Url, this.fileToUpload).pipe(first()).subscribe();
              } else {
                if (data?.showAlert) {
                   this.spinner.hide();
                } else {
                   this.previewKey = data.key;
                   this.fileUpload
                      .uploadfileAWSS3(data.presignedS3Url, this.fileToUpload)
                      .pipe(first())
                      .subscribe(
                         (data1) => {
                            this.fileUpload
                               .generatePresignedURLPreview(
                                  this.previewKey,
                                  type,
                                  firstName,
                                  lastName,
                                  this.assignmentId
                               )
                               .pipe(first())
                               .subscribe((data) => {
                                  this.form.documentWordCount.setValue(data.counts[1])
                                  this.submission_word_limit = data.assignmentDetails.submission_word_limit
                                  this.documentCredits = data.documentCredits;
                                  setTimeout(() => {
                                     this.previewSrc =
                                        data.presignedS3UrlPreview;
                                     this.uploadedFile = data.Key;
                                     this.converted = data.converted;
                                     // this.allowToSubmitDocument =
                                     //    data.allowSubmission.allowToSubmitDocument;
                                     this.trial = data.allowSubmission.trail;
                                     // this.allowedProducer = data.allowedProducer;
                                     // if (this.allowedProducer) {
                                     //    this.showNotAllowedProducerMessage();
                                     // }
                                     this.form.documentCredits.setValue(
                                        this.documentCredits
                                     );
                                     if (
                                        this.user.Institution !== null &&
                                        this.user.roleId === 2
                                     ) {
                                        this.form.documentTranslatedLanguage.setValue(
                                           this.user.allowedLanguages?.targetLanguage
                                        );
                                        this.form.documentLanguage.setValue(
                                           this.user.allowedLanguages?.documentLanguage
                                        );
                                     } else {
                                        // this.form.documentTranslatedLanguage.setValue(
                                        //    -1
                                        // );
                                        // this.form.documentLanguage.setValue('');
                                     }
                                     if (this.assignmentId || this.thesisId) {
                                        this.form.documentLanguage.setValue(
                                           this.originalLanguage
                                        );
                                        this.form.documentTranslatedLanguage.setValue(
                                           this.translatedLanguage
                                        );
                                        this.form.documentLanguage.disable();
                                        this.form.documentTranslatedLanguage.disable();
                                     }
                                  }, 500);
                                  this.allowToSubmitDocument = this.submission_word_limit ? this.submission_word_limit >= data.counts[1] : true
                                  this.uploaded = 1;
                                  this.spinner.hide();
                               }),
                               (error) => {
                                  this.spinner.hide();
                                  console.log('error3', error);
                               };
                         },
                         (error) => {
                            this.spinner.hide();
                            console.log('error2', error);
                         }
                      );
                }
              }
            },
            (error) => {
               this.spinner.hide();
               console.log('error1', error);
            }
         );
    }
   }

   private getLineCount(content: string) {
    const lineCount = content.split('\n').length;
    return lineCount;
   }
   /**
    * Method used to get controls from formGroup
    */
   get form() {
      return this.uploadForm.controls;
   }
   /**
    * Method used to get libraries from array from upload from.
    */
   get libraries() {
      return <FormArray>this.uploadForm.get('thirdPartyLibs');
   }

   /**
    * Method used to show advanced options at file upload.
    */
   advancedOptions() {
      if (this.advancedOptionsIndex == false) {
         this.advancedOptionsIndex = true;
      } else {
         this.advancedOptionsIndex = false;
      }
   }
   /**
    * Method used to format bytes for the file upload.
    * @param bytes
    */
   formatBytes(bytes) {
      var marker = 1024; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB

      if (bytes < kiloBytes) return bytes + ' Bytes';
      else if (bytes < megaBytes)
         return (bytes / kiloBytes).toFixed(decimal) + ' KB';
      else if (bytes < gigaBytes)
         return (bytes / megaBytes).toFixed(decimal) + ' MB';
   }
   /**
    * Method used to create submission when uploading a document.
    */
   async createSubmission() {
      this.submitClicked = true;

      if(this.uploadForm.invalid && !this.isCodeFile) {
         return
      }

      let withCredits = true;
      if (this.user.institutionId !== null && this.user.roleId == 2) {
         if (this.user.documentsToUse > 0 && this.user.wordsToUse > 0) {
            const result = await swal.fire({
               title: 'Choose how to proceed',
               text: 'Would you like to use credits or  number of documents?',
               icon: 'question',
               cancelButtonText: 'Close',
               confirmButtonText: 'With credits',
               denyButtonText: 'With documents',
               denyButtonColor: '#2455C2',
               confirmButtonColor: '#F3001E',
               cancelButtonColor: '#8F00F1',
               showDenyButton: true,
               showCancelButton: true,
               allowOutsideClick: false,
            });
            if (result.isConfirmed) {
               withCredits = true;
            }
            if (result.isDenied) {
               withCredits = false;
            }
            if (result.isDismissed) {
               return;
            }
         } else {
            if (this.user.documentsToUse > 0 && this.user.wordsToUse === 0) {
               withCredits = false;
            } else if (
               this.user.documentsToUse === 0 &&
               this.user.wordsToUse > 0
            ) {
               withCredits = true;
            } else {
               await swal.fire({
                  title: `Something is wrong!`,
                  text: `You don't have credits or documents to submit the document`,
                  icon: 'error',
                  confirmButtonText: this.translate.instant('app.i_understand'),
                  confirmButtonColor: '#F3001E',
                  allowOutsideClick: false,
               });
               return;
            }
         }
      }

      if(this.user.roleId == 5 || this.user.roleId == 9) {
         this.archive = true;
      }

      this.spinner.show();
      if (this.form.documentTranslatedLanguage.value === -1) {
         this.form.documentTranslatedLanguage.setValue(null);
      }

      if (this.isCodeFile) {
        try {
          await this.fileUpload.uploadCodeFile(this.fileToUpload, this.uploadedFile).toPromise();
        } catch (error) {
          console.log(error, 'error');
        }
      }

      this.submissionService
         .createSubmission(
            this.form.documentTitle.value,
            this.uploadedFile,
            this.form.fullName.value,
            this.form.dateOfUpload.value,
            this.form.documentSize.value,
            this.form.documentLanguage.value,
            this.form.documentTranslatedLanguage.value,
            this.archive,
            this.citationStyle,
            this.thirdPartyLibraries,
            this.converted,
            withCredits,
            this.form.assignmentId.value,
            this.form.thesisId.value,
            this.form.aiDetection.value,
            this.form.sources.value,
            this.form.contextualSimilarity.value,
            this.form.enableThresholds.value,
            this.form.exactMatchThreshold.value,
            this.form.alteredTextThreshold.value,
            this.form.contextualSimilaritiesThreshold.value,
            this.isCodeFile
         )
         .subscribe(
            async (data: any) => {
               this.submitClicked = false;
               console.log(data, 'data');

               if (data.showAlert == true) {
                  this.trial = data.allowSubmission.trial;
                  this.showAlertMessageTrial();
                  setTimeout(() => {
                     this.router.navigateByUrl('/individual/dashboard');
                  }, 1500);
                  this.toastrService.success(
                     this.translate.instant('report.document_is_uploaded_successfully')
                  );
                  this.spinner.hide();
               } else {
                if (this.isCodeFile) {
                  this.form.documentLanguage.clearValidators();
                  this.form.documentLanguage.updateValueAndValidity();
                }
                if (this.user.roleId !== 4 && this.user.roleId !== 5 && this.user.roleId !== 9 && this.user.roleId !== 10) {
                  setTimeout(() => {
                        this.router.navigateByUrl('/individual/dashboard');
                     }, 1500);
                  } else if (this.user.roleId == 4 || this.user.roleId == 10) {
                     setTimeout(() => {
                        this.router.navigateByUrl('/professor/submissions');
                     }, 1500);
                  } else if (this.user.roleId == 5) {
                     setTimeout(() => {
                        this.router.navigateByUrl('/student/register/upload');
                     }, 1500);
                  } else if (this.user.roleId == 9) {
                     setTimeout(() => {
                        this.router.navigateByUrl('/student/statistics/submissions-statistics/assignments');
                     }, 1500);
                  }
                  this.toastrService.success(
                     this.translate.instant('report.document_is_uploaded_successfully')
                  );
                  this.spinner.hide();
               }
            },
            (error) => {
               console.log('error Submit', error);
               if (withCredits) {
                  if (
                     error !== 'Document title must be at least 5 characters' &&
                     error !==
                        `Document title can't be more than 190 characters`
                  ) {
                     this.showAlertMessage();
                  }
               }
               this.spinner.hide();
            }
         );
   }
   /**
    * Method used to select document original language.
    * @param event
    */
   selectLanguage(event) {
      if (event == '-1') {
         this.showWarningMessage('Please select a language!');
         this.form.documentLanguage.setErrors({
            required: true,
            invalid: true,
            touched: true,
         });
         this.languageSelected = true;
      } else {
         this.languageSelected = false;
         this.form.documentLanguage.setValue(event);
      }
   }
   /**
    * Method used to select document translated language
    * @param event
    */
   selectTranslatedLanguage(event) {
      this.form.documentTranslatedLanguage.setValue(event);
   }
   /**
    * Method used to change value of @archive. We archive document based on the value of the variable.
    * @param event
    */
   archiveDocument() {
      this.archive = !this.archive;
   }
   /**
    * Method used to select citation style.
    * @param event
    */
   selectCitationStyle(event) {
      this.citationStyle = event.target.value;
   }
   /**
    * Method used to check third party libraries.
    * @param e
    */
   onCheckboxChange(e) {
      if (e.target.checked) {
         this.thirdPartyLib.forEach((item) => {
            if (e.target.value == item.description) {
               item.isChecked = true;
            }
         });
      } else {
         this.thirdPartyLib.forEach((item) => {
            if (e.target.value == item.description) {
               item.isChecked = false;
            }
         });
      }
   }
   /**
    * A lifecycle hook that is called when a directive, pipe, or service is destroyed.
    * Use for any custom cleanup that needs to occur when the instance is destroyed.
    */
   ngOnDestroy() {
      this.user$.unsubscribe();
   }

   /**
    * Method to display modal that the producer is not allowed
    */
   async showNotAllowedProducerMessage() {
      const result = await swal.fire({
         title: this.translate.instant('app.this_file_has_some_issues'),
         text: this.translate.instant('app.try_converting_your_file_to_docx_and_then_convert_it_to_pdf'),
         icon: 'warning',
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.i_understand'),
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         if (this.user.roleId !== 4 && this.user.roleId !== 5 && this.user.roleId !== 9 && this.user.roleId !== 10) {
            this.router.navigateByUrl('/individual/dashboard');
         } else if (this.user.roleId == 4 || this.user.roleId == 10) {
            this.router.navigateByUrl('/professor/submissions');
         } else if (this.user.roleId == 5 || this.user.roleId == 9) {
            this.router.navigateByUrl(`/student/upload/upload-file?assignmentId=${this.assignmentId}&assignmentName=${this.assignmentName}`);
            this.uploaded = 0;
         }
      }
   }
   /**
    * Method used to show user different messages based on @uploadFrom control state.
    */
   showFormMessages() {
      if (this.uploadForm.invalid) {
         if (this.form.documentLanguage.invalid) {
            this.showWarningMessage('Please select a language!');
            this.form.documentLanguage.setErrors({
               required: true,
               invalid: true,
               touched: true,
            });
         }
         if (this.form.documentTitle.invalid) {
            this.showWarningMessage('Document should have a title!');
            this.form.documentTitle.setErrors({
               required: true,
               invalid: true,
               touched: true,
            });
         }

         if (this.form.fullName.invalid) {
            this.showWarningMessage('Author should have a name!');
            this.form.fullName.setErrors({
               required: true,
               invalid: true,
               touched: true,
            });
         }
      }
   }
   /**
    * Method used to show warning toaster.
    * @param message
    */
   showWarningMessage(message) {
      this.toastrService.warning(message);
   }
   /**
    * Method used to inform user that he can't upload document.
    */
   async showAlertMessage() {
      if (this.user.roleId !== 4 && this.user.roleId !== 5 && this.user.roleId !== 10 && this.user.roleId !== 9) {
         let title = `Not enough credits!`;
         let message = `To submit your document you need ${Math.abs(
            this.user.wordsToUse - this.documentCredits
         )} more credits.`;
         const result = await swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#b5adad',
            confirmButtonText: 'Buy more credits',
            allowOutsideClick: false,
         });
         if (result.isConfirmed) {
            this.router.navigateByUrl('/individual/add-credits');
         } else if (result.isDismissed) {
            this.router.navigateByUrl('/individual/dashboard');
         }
      }
   }

   async showAlertMessageTrial() {
      let title = 'Document suspended from processing!';
      let message = `Your document has been submitted but will be suspended due to low amount of credits. Total credits required: ${this.documentCredits}`;

      const result = await swal.fire({
         title: title,
         text: message,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Buy more credits',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.router.navigateByUrl('/individual/add-credits');
      } else if (result.isDismissed) {
         this.router.navigateByUrl('/individual/dashboard');
      }
   }

   isValid(field){
      return (field.invalid && field.touched)|| (field.invalid && this.submitClicked);
   }
}
