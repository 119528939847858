import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiLoggerService, ApiLog } from '../services/api-logger.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-api-debug',
  template: `
    <ng-container >
      <h2>API Logs</h2>
      <button (click)="refreshLogs()">Refresh Logs</button>
      <div *ngIf="apiLogs.length === 0">No logs to display</div>
      <div *ngFor="let log of apiLogs">
        <h3>{{ log.method }} {{ log.url }}</h3>
        <p>Timestamp: {{ log.timestamp | date:'medium' }}</p>
        <h4>Request Body:</h4>
        <pre>{{ log.requestBody | json }}</pre>
        <h4>Response:</h4>
        <pre>{{ log.response | json }}</pre>
        <hr>
      </div>
    </ng-container>
 
  `,
  styles: [`
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  `]
})
export class ApiDebugComponent implements OnInit, OnDestroy {
  apiLogs: ApiLog[] = [];
  private subscription: Subscription;
  isAuthorized: boolean = false;

  constructor(
    private apiLogger: ApiLoggerService,
  ) {}

  ngOnInit() {
      this.refreshLogs();
      this.subscription = this.apiLogger.apiLogs$.subscribe(logs => {
        this.apiLogs = logs;
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  refreshLogs() {
    this.apiLogs = this.apiLogger.getLatestLogs();
  }
}