import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, switchMap } from 'rxjs/operators';
import * as studentActions from '../../student/action/student.actions';
import { UserService } from '../../../../services/user.service';

@Injectable()
export class StudentEffects {
   constructor(private actions$: Actions, private userService: UserService) {}

   /**
    * Effect listening for registerStudent Action, when registerStudent is dispatched the effect with call create() from userService.
    * In case of success response the effect it will trigger registerStudentSuccess.
    * In case of Error it will trigger registerStudentFailure with error property.
    */
   registerStudent$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(studentActions.studentRegister),
         concatMap((action) => {
            return this.userService
               .registerStudent(
                  action.studentName,
                  action.studentFaculty,
                  action.studentDepartment,
                  action.studentIndexID,
                  action.studentEmail,
               )
               .pipe(
                  switchMap((data) => [
                     studentActions.studentRegisterSuccess(data),
                  ]),
                  catchError((error) =>
                     of(studentActions.studentRegisterFailure({ error }))
                  )
               );
         })
      );
   });
   /**
    * Effect listening for loadStudentsDetails Action, when loadStudentsDetails is dispatched the effect with call courses() from courseService.
    * In case of success response the effect it will trigger loadStudentsDetailsSuccess.
    * In case of Error it will trigger loadStudentsDetailsFailure with error property.
    */
   getStudentsDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(studentActions.loadStudentsDetails),
         concatMap((action) => {
            return this.userService
               .institutionStudents(
                  action.page,
                  action.faculty,
                  action.department,
                  action.name
               )
               .pipe(
                  map((data) => {
                     return studentActions.loadStudentsDetailsSuccess({
                        students: data.students,
                        studentsNumber: data.studentsNumber,
                     });
                  }),
                  catchError((error) =>
                     of(studentActions.loadStudentsDetailsFailure({ error }))
                  )
               );
         })
      );
   });

   /**
    * Effect listening for editStudentDetails Action, when editStudentDetails is dispatched the effect with call editCourse() from courseService.
    * In case of success response the effect it will trigger loadProfessorsDetails that will return all students of institution.
    * In case of Error it will trigger editStudentDetailsFailure with error property.
    */
   editStudentDetails$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(studentActions.editStudentDetails),
         concatMap((action) => {
            return this.userService
               .editUser(
                  action.studentId,
                  action.studentName,
                  action.studentEmail,
                  action.studentFaculty,
                  action.studentDepartment,
                  action.studentStatus,
                  false,
                  0,
                  action.studentIndexID,
                  undefined,
                  action.role
               )
               .pipe(
                  switchMap((data) => [
                     studentActions.editStudentDetailsSuccess({
                        data: data,
                     }),
                     studentActions.loadStudentsDetails({
                        page: action.page,
                        faculty: action.faculty,
                        department: action.department,
                        name: action.name,
                     }),
                  ]),
                  catchError((error) =>
                     of(
                        studentActions.editStudentDetailsFailure({
                           error,
                        })
                     )
                  )
               );
         })
      );
   });

   /**
    * Effect listening for removeStudent Action, when removeStudent is dispatched the effect with call remove() from courseService.
    * In case of success response the effect it will trigger  will return all students of institution.
    * In case of Error it will trigger removeStudentFailure with error property.
    */
   removeStudent$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(studentActions.removeStudent),
         concatMap((action) => {
            return this.userService.removeUser(action.studentId).pipe(
               switchMap((data) => [
                  studentActions.removeStudentSuccess({
                     data: data,
                  }),
                  studentActions.loadStudentsDetails({
                     page: action.page,
                     faculty: action.faculty,
                     department: action.department,
                     name: action.name,
                  }),
               ]),
               catchError((error) =>
                  of(studentActions.removeStudentFailure({ error }))
               )
            );
         })
      );
   });
}
