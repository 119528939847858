import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ReportService } from "./services/report.service";
import { Subject, Subscription } from "rxjs";
import { LanguageService } from './services/language.service';
import { takeUntil } from 'rxjs/operators';
import { Language } from './models/language';
import { StudentStatisticsService } from 'src/app/services/student-statistics.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentData } from './services/document-data.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { User } from './models/user';
// import {SpinnerService} from "../../services/spinner.service";
// import { DocumentComponent } from '../panel-components/document/document.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-report-v2',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent {
  summaryDetails: any[] = [];
  submissionDetails: any;
  isOnAnalysis: boolean = false;
  isOnAi: boolean = false;
  isOnManipulation: boolean = false;
  sources: {
    sources?: any[] | undefined,
    excludedSources?: any[] | undefined
  } = {
      sources: [],
      excludedSources: []
    };

  initialSources: {
    originalSources: [],
    excludedOriginalSources: [],
    translatedSources: [],
    excludedSourcesMlPlag: []
  } =
    { originalSources: [], excludedOriginalSources: [], translatedSources: [], excludedSourcesMlPlag: [] }

  navigationDetails: any
  fileUrl: any
  similarityAnalysis: any;
  bannerDetails: {
    title: string,
    original_language: string,
    translated_language: string,
    ai_authorship_analysis: boolean,
    file_size: string,
    word_count: string,
    page_count: string
  } = {
      title: '',
      original_language: '',
      translated_language: '',
      ai_authorship_analysis: false,
      file_size: '',
      word_count: '',
      page_count: ''
    };
  aiAnalysis: { aiPercentage: number, checkAI: boolean, originalSources: [] } = {
    aiPercentage: 0,
    checkAI: false,
    originalSources: []
  };
  docStatus: any;
  current_language: any;
  current_language_type: any;
  selectedSourcesFilter = 0
  selectedDocumentLanguage: { language: string, type: string } = { language: '', type: 'Original' }
  filteredSources: any
  isOriginalLanguage = true;
  initialNavigationData: any
  selectedSourcesToHighlight: number[] = []
  currentPageSubscription: Subscription[] = []
  analysisType = 'analysis';
  sentencesWithAI!: [];
  submissionAISentences: any[] = []
  navigationArrayAI: any[] = []
  selectedManipulation: string = '';
  pdfImagesSrc = '';
  tempPdfSrc = '';
  sentenceDetails!: any[];
  imagesDetectionData: any;
  allManipulations: any;
  submissionId: any;
  studentDeletionPermissions: any;
  isDocumentInArchive: any;
  sourcesAreDeleted: any;
  sentenceInformation = false
  submission: any;
  quotesToggle: any;
  selectedTranslatedLanguage: any;
  languages: Language[] = [];
  private destroy$ = new Subject<void>();
  isOnCohort: boolean = false
  cohortValues: any[];
  submissionValues: any[];
  exactMatchThreshold: any;
  currentLanguage: string = localStorage.getItem('websiteLanguage');
  contextSimilarity: any;
  hasTranslatedLanguages: boolean;
  canEdit: boolean
  currentUser$: any;
  currentUserDetailsSubscriber$: any;
  translationSources: any;

  // @ViewChild(DocumentComponent, { static: false }) documentComponent!: DocumentComponent;

  constructor(
    private route: ActivatedRoute, private reportService: ReportService, private cdr: ChangeDetectorRef, private languageService: LanguageService,
    private studentStatisticsService: StudentStatisticsService, private translate: TranslateService, private documentDataService: DocumentData, private store: Store,
    private http: HttpClient
    //  private spinnerService: SpinnerService
  ) {
  }

  ngOnInit(): void {
    this.currentPageSubscription.push(this.route.params.subscribe(
      (data: any) => {
        this.reportService.submissionDetails(data.id).subscribe(
          (submissionDetails: any) => {
            this.studentDeletionPermissions = submissionDetails.studentDeletionPermission;
            this.isDocumentInArchive = submissionDetails.isDocumentInArchive;
            this.sourcesAreDeleted = submissionDetails?.sourcesAreDeleted;
            this.submissionId = data.id;
            this.sentencesWithAI = submissionDetails?.sentencesWithAI;
            this.docStatus = submissionDetails?.originality
            this.submissionDetails = submissionDetails;
            this.fileUrl = this.submissionDetails.imagesDetectionUrl
            this.tempPdfSrc = this.submissionDetails.fileUrl

            this.checkIfFileExists(submissionDetails.imagesDetectionUrl).then(exists => {
              if (exists) {
                this.pdfImagesSrc = submissionDetails.imagesDetectionUrl;
                this.fileUrl = submissionDetails.imagesDetectionUrl;
              } else {
                this.pdfImagesSrc = this.submissionDetails.fileUrl;
                this.fileUrl = this.submissionDetails.fileUrl;
              }
            });

            this.pdfImagesSrc = submissionDetails.imagesDetectionUrl;
            this.submissionAISentences = submissionDetails.sentencesWithAI
            this.quotesToggle = submissionDetails.submission.quotesToggle
            this.reportService.toggleQuotesSubject.next(this.quotesToggle);
            this.exactMatchThreshold = this.submissionDetails.submission?.sentence_thresholds?.exactMatchThreshold || 80
            this.contextSimilarity = this.submissionDetails.submission?.sentence_thresholds?.contextualSimilaritiesThreshold || 80
            const userRole = JSON.parse(localStorage.getItem('role'))
            this.currentUser$ = this.store.select(getCurrentUser);
            this.currentUserDetailsSubscriber$ = this.store
              .select(getCurrentUser)
              .subscribe((user: User) => {
                this.canEdit = true;
                if (user?.roleId === 2) {
                  this.canEdit = !!(
                    user?.institutionId === null
                  );
                }
                if (user?.roleId === 5 || user?.roleId === 3 || user?.roleId === 9) {
                  this.canEdit = false;
                }
                if(user?.roleId === 9 && !this.submissionDetails?.submission?.assignmentsId){
                  this.canEdit = true
                }
              });
            if (this.submissionDetails?.submission?.assignmentsId && (userRole === 9 || userRole === 10 || userRole === 11)) {
              this.studentStatisticsService.studentAssignments(-1, '', '', this.submissionDetails?.submission?.assignmentsId, this.submissionId).subscribe((data) => {
                if(data.studentAssignments.length !== 0){
                  this.cohortValues = [
                    data?.studentAssignments[0]?.averageMLPlag?._avg?.originalPercentage.toFixed(2),
                    data?.studentAssignments[0]?.averageMLPlag?._avg?.translatedPercentage.toFixed(2),
                    data?.studentAssignments[0]?.averageAIAuthorship ? data.studentAssignments[0]?.averageAIAuthorship.toFixed(2) : 0,
                    data?.studentAssignments[0]?.averageManipulations.toFixed(2),
                    data?.studentAssignments[0]?.averagewordCount?.toFixed(2),
                  ]

                  this.submissionValues = [
                    this.submissionDetails.submission.originalPercentage,
                    this.submissionDetails.submission.translatedLanguage ? this.submissionDetails.submission.translatedPercentage.toFixed(2) : 0,
                    this.submissionDetails.submission.aiDetection ? this.submissionDetails.submission.aiPercentage.toFixed(2) : 0,
                    this.submissionDetails.submission.characterReplacements + this.submissionDetails.submission.hiddenText + this.submissionDetails.submission.imageAsText,
                    this.submissionDetails.submission.wordCount,
                  ]
                } else {
                  this.cohortValues = [
                    0,0,0,0,0
                  ]
                  this.submissionValues = [
                    0,0,0,0,0
                  ]
                }
              })
            }
            this.submissionAISentences?.forEach((sentence: any) => {
              if (sentence.aiText) {
                const existingPageIndex = this.navigationArrayAI.findIndex(item => item.page === sentence.page);
                if (existingPageIndex !== -1) {
                  // Increment numberOfSentences for the existing page
                  this.navigationArrayAI[existingPageIndex].numberOfSentences++;
                } else {
                  // If the page doesn't exist in the array, add it with numberOfSentences initialized to 1
                  this.navigationArrayAI.push({ page: sentence.page, numberOfSentences: 1 });
                }
              } else {
                const existingPageIndex = this.navigationArrayAI.findIndex(item => item.page === sentence.page);

                if (existingPageIndex === -1) {
                  // If the page doesn't exist in the array and aiText is false, add it with numberOfSentences initialized to 0
                  this.navigationArrayAI.push({ page: sentence.page, numberOfSentences: 0 });
                }
              }
            });

            this.getS3Json(submissionDetails.presignedUrlJson)
          },
          (err: any) => {
            console.log(err);
          }
        );
      },
      (err: any) => {
        console.log(err);
      }
    ),
      this.reportService.newS3JsonObject.subscribe((data: any) => {
        if (data) {
          this.getS3Json(data)
        }
      }),
      this.reportService.filterSourcesObject.subscribe((data: number) => {
          this.selectedSourcesFilter = data;
          this.filterSources(data)
      }),
      this.reportService.selectLanguageObject.subscribe((data: { language: string, type: string }) => {
        this.current_language = data.language;
        this.current_language_type = data.type;
        this.isOriginalLanguage = data.type === 'Original';
        if (data.type === 'Original' && this.selectedDocumentLanguage.type !== 'Original') {
          this.sources = {
            sources: this.initialSources.originalSources,
            excludedSources: this.initialSources.excludedSourcesMlPlag
          }
          this.navigationDetails = this.initialNavigationData.originalNavigationArray
          this.filterSources(0)
        }
        if (data.type === 'Translated' && this.selectedDocumentLanguage.type !== 'Translated') {
          this.reportService.currentTranslatedLanguageObject.subscribe((lang: string) => {
            if (lang !== '') {
              this.selectedTranslatedLanguage = lang
              // const filteredSources = this.initialSources.translatedSources.find((source: any) => source.language === lang) as any;
              // if (filteredSources) {
              //   this.sources = {
              //     sources: filteredSources.sources,
              //     excludedSources: this.initialSources.excludedSourcesMlPlag
              //   }
              this.filterSources(0)
              // }
            }
            this.navigationDetails = this.initialNavigationData.translatedNavigationArray[lang]
          })
        }
        this.updateNavigationArray()
        this.selectedDocumentLanguage = data;
      }),
      this.reportService.selectedSourcesObject.subscribe((data: any) => {
        this.selectedSourcesToHighlight = data
        this.updateNavigationArray()
      })
    )

    this.reportService.sentenceInformationObject.subscribe(data => {
      this.sentenceInformation = data !== null;
      // if(data !== null && data?.translated && data?.translated.length > 0){
      //   this.reportService.selectedTransltaionCodeSubject.next(data?.translated[0].lang)
      // }
    });

    this.reportService.selectAnalysisTypeObject.subscribe((data: string) => {
      this.analysisType = data;
    })

    this.reportService.selectManipulationTypeObject.subscribe((type: string) => {
      this.selectedManipulation = type;
    });

    this.languageService.getLanguages().subscribe((data: any) => {
      this.languages = data;
    });

    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.currentLanguage = language;
      this.translate.use(language);
    });
    this.currentUser$ = this.store.select(getCurrentUser);
    this.currentUserDetailsSubscriber$ = this.store
      .select(getCurrentUser)
      .subscribe((user: User) => {
        this.canEdit = true;
        if (user?.roleId === 2) {
          this.canEdit = !!(
            user?.institutionId === null
          );
        }
        if (user?.roleId === 5 || user?.roleId === 3 || user?.roleId === 9) {
          this.canEdit = false;
        }
      });

  }
  // console.log("test");


  // filterSources(type: number) {
  //   let sources;
  //   let excludedSources;
  //   let excludedInsideOriginalSources;
  //   let excludedInsideTranslatedSources;

  //   if (!this.isOriginalLanguage) {
  //     if (this.analysisType == 'none') {
  //       this.initialSources.translatedSources.forEach((language: any) => {
  //         sources = language.sources.filter((source: any) => !source.isExcluded);
  //       })
  //     } else {
  //       sources = this.initialSources.translatedSources.find((source: any) => source.language === this.selectedTranslatedLanguage)['sources'] as any;
  //       this.translationSources = sources;
  //     }
  //   }

  //   if (!this.isOriginalLanguage) {
  //     if (this.analysisType == 'none') {
  //       Object.keys(this.initialSources.excludedSourcesMlPlag).forEach((language: any) => {
  //         excludedSources = this.initialSources.excludedSourcesMlPlag[language];
  //       })
  //     } else {
  //       excludedSources = this.initialSources.excludedSourcesMlPlag[this.selectedTranslatedLanguage] || [];
  //     }
  //   }


  //   if (this.analysisType == 'none') {
  //     if (this.current_language_type === 'Original') {
  //       excludedInsideOriginalSources = this.initialSources.originalSources.filter((source: any) => source.isExcluded && source.hide);
  //     } else if (this.current_language_type === 'Translated') {
  //       this.initialSources.translatedSources.forEach((language: any) => {
  //         excludedInsideTranslatedSources = language.sources.filter((source: any) => source.isExcluded && source.hide);
  //       })
  //     }
  //   } else {
  //     if (this.isOriginalLanguage) {
  //       excludedInsideOriginalSources = this.initialSources.originalSources.filter((source: any) => source.isExcluded && source.hide);
  //     } else {
  //       const tempArr = (this.initialSources.translatedSources.find((source: any) => {
  //         return source.language === this.selectedTranslatedLanguage
  //       }) as any)
  //       excludedInsideTranslatedSources = (tempArr['sources'] as any).filter((source: any) => source.hide && source.isExcluded )
  //     }
  //   }

  //   if (this.analysisType == 'none') {
  //     sources = this.current_language_type === 'Original' ? this.initialSources.originalSources : sources;
  //     excludedSources = this.current_language_type === 'Original' ? this.initialSources.excludedSourcesCrossPlag : excludedSources
  //   } else {
  //     sources = this.isOriginalLanguage ? this.initialSources.originalSources : sources;
  //     excludedSources = this.isOriginalLanguage ? this.initialSources.excludedSourcesCrossPlag : excludedSources;
  //   }
  //   if (type === 0) {
  //     this.filteredSources = sources.filter((source: any) => {
  //       return (source.percentage > 1 && !source.isExcluded)
  //     });
  //     if (this.filteredSources.length === 0) {
  //       this.filteredSources = sources.filter((source: any) => (source.percentage <= 1 && !source.isExcluded));
  //     }
  //     console.log(this.filteredSources, 'filteredSources in 0');

  //   } else if (type === 1 || type === 2) {
  //     this.filteredSources = sources.filter((source: any) => source.type === type);
  //   } else if (type === 3) {
  //     let excludedSourcesInside = [];
  //     if (this.analysisType == 'none') {
  //       excludedSourcesInside = this.current_language_type === 'Original' ? excludedInsideOriginalSources : excludedInsideTranslatedSources;
  //     } else {
  //       excludedSourcesInside = this.isOriginalLanguage ? excludedInsideOriginalSources : excludedInsideTranslatedSources;
  //     }
  //     this.filteredSources = [...excludedSources, ...excludedSourcesInside];
  //   } else if (type === 4) {
  //     this.filteredSources = sources.filter((source: any) => source.percentage <= 1);
  //   }

  //   // // Remove duplicates by source.id
  //   // const uniqueSourcesMap = new Map();
  //   // this.filteredSources.forEach((source: any) => {
  //   //   uniqueSourcesMap.set(source.id, source);
  //   // });
  //   // this.filteredSources = Array.from(uniqueSourcesMap.values());

  //   if (type === 0) {
  //     this.sources = { sources };
  //   } else {
  //     this.sources = { sources: this.filteredSources };
  //   }

  //   if (this.analysisType == 'none') {
  //     (this.similarityAnalysis ?? (this.similarityAnalysis = {}))[this.current_language_type == 'Original' ? 'originalSources' : 'translatedSources'] = this.filteredSources;
  //   } else {
  //     (this.similarityAnalysis ?? (this.similarityAnalysis = {}))[this.isOriginalLanguage ? 'originalSources' : 'translatedSources'] = this.filteredSources;
  //   }
  //   this.updateNavigationArray();
  // }

  filterSources(type: number) {
    let sources;
    let excludedSources;
    let excludedInsideOriginalSources;
    let excludedInsideTranslatedSources;

    if (this.analysisType !== 'none') {
      if (!this.isOriginalLanguage) {
        sources = this.initialSources.translatedSources.find((source: any) => source.language === this.selectedTranslatedLanguage)['sources'] as any;
        this.translationSources = sources;
      }

      if (!this.isOriginalLanguage) {
        excludedSources = this.initialSources.excludedSourcesMlPlag[this.selectedTranslatedLanguage] || [];
      }

      if (this.isOriginalLanguage) {
        excludedInsideOriginalSources = this.initialSources.originalSources.filter((source: any) => source.isExcluded && source.hide);
      } else {
        const tempArr = (this.initialSources.translatedSources.find((source: any) => {
          return source.language === this.selectedTranslatedLanguage
        }) as any)
        excludedInsideTranslatedSources = (tempArr['sources'] as any).filter((source: any) => source.hide && source.isExcluded )
      }

    sources = this.isOriginalLanguage ? this.initialSources.originalSources : sources;
    excludedSources = this.isOriginalLanguage ? this.initialSources.excludedOriginalSources : excludedSources;

      if (type === 0) {
        this.filteredSources = sources.filter((source: any) => {
          return (source?.percentage > 1 && !source.isExcluded)
        });
        if (this.filteredSources.length === 0) {
          this.filteredSources = sources.filter((source: any) => (source?.percentage <= 1 && !source.isExcluded));
        }
      } else if (type === 1 || type === 2) {
        this.filteredSources = sources.filter((source: any) => source.type === type);
      } else if (type === 3) {
        let excludedSourcesInside = [];
          excludedSourcesInside = this.isOriginalLanguage ? [] : excludedInsideTranslatedSources;
        this.filteredSources = [...excludedSources, ...excludedSourcesInside];
      } else if (type === 4) {
        this.filteredSources = sources.filter((source: any) => source?.percentage <= 1);
      }
      // // Remove duplicates by source.id
      // const uniqueSourcesMap = new Map();
      // this.filteredSources.forEach((source: any) => {
      //   uniqueSourcesMap.set(source.id, source);
      // });
      // this.filteredSources = Array.from(uniqueSourcesMap.values());

      if (type === 0) {
        this.sources = { sources };
      } else {
        this.sources = { sources: this.filteredSources };
      }

      (this.similarityAnalysis ?? (this.similarityAnalysis = {}))[this.isOriginalLanguage ? 'originalSources' : 'translatedSources'] = this.filteredSources;
    }
    this.updateNavigationArray();

  }


  updateNavigationArray() {
    if (!this.navigationDetails || !this.sources?.sources) {
      return;
    }

    this.navigationDetails = [];
    if (!this?.sources?.sources?.[0]?.sourceId) {
      Object.keys(this.sources.sources).forEach((lang: any) => {
        if ((this.sources.sources[lang] as any).sources && (this.sources.sources[lang] as any).sources.length > 0) {
          (this.sources.sources[lang] as any).sources.forEach((source: any) => {
            if (this.selectedSourcesToHighlight.length === 0 || this.selectedSourcesToHighlight.includes(source.no)) {
              source.sentences.forEach((sentence: any) => {
                if (sentence.page) {
                  const existingPage = this.navigationDetails.find((item: any) => item.page === sentence.page);

                  if (existingPage) {
                    existingPage.numberOfSentences++;
                  } else {
                    this.navigationDetails.push({
                      page: sentence.page,
                      numberOfSentences: 1
                    });
                  }
                }
              });
            }
          });
        }
      })
    } else {
      (this.sources.sources as any).forEach((source: any) => {

        if (this.selectedSourcesToHighlight.length === 0 || this.selectedSourcesToHighlight.includes(source.no)) {
          source.sentences.forEach((sentence: any) => {
            if (sentence.page) {
              const existingPage = this.navigationDetails.find((item: any) => item.page === sentence.page);

              if (existingPage) {
                existingPage.numberOfSentences++;
              } else {
                this.navigationDetails.push({
                  page: sentence.page,
                  numberOfSentences: 1
                });
              }
            }
          });
        }
      });
    }
    this.processAdditionalNavigationData();

    this.reportService.selectLanguageObject.subscribe((data: any) => {
      if (data.type === 'Translated') {
        let tempSources = this.sources.sources;
        tempSources.forEach((allSources: any) => {
          if (allSources.sources) {
            this.sources.sources = allSources.sources;
          }
        });
      }
    });
  }

  processAdditionalNavigationData() {
    this.navigationArrayAI.forEach((page: any) => {
      this.incrementPageSentenceCount(page.page, page.numberOfSentences);
    });

    this.sentenceDetails.forEach((sentence: any) => {
      const replacedCharacters = sentence?.replacedCharacters || [];
      const includedReplacedCharacters = this.allManipulations?.replacedCharacters.filter((item: any) =>
        replacedCharacters.includes(item.character)
      );

      if (includedReplacedCharacters.length > 0) {
        this.incrementPageSentenceCount(sentence.page, includedReplacedCharacters.length);
      }

      if (!sentence.whitecharactersExcluded) {
        this.incrementPageSentenceCount(sentence.page, sentence.characters.length);
      }
    });

    if (this.imagesDetectionData && this.imagesDetectionData.length > 0) {
      this.imagesDetectionData.forEach((image: any) => {
        if (image.label === 'red') {
          this.incrementPageSentenceCount(image.page + 1, 1);
        }
      });
    }
  }

  incrementPageSentenceCount(page: number, count: number) {
    const existingPage = this.navigationDetails.find((item: any) => item.page === page);
    if (existingPage) {
      existingPage.numberOfSentences += count;
    } else {
      this.navigationDetails.push({
        page: page,
        numberOfSentences: count
      });
    }
  }

  public getS3Json(presignedUrlJson: string) {
    // this.spinnerService.show()
    this.reportService.getS3Json(presignedUrlJson).subscribe(
      (data: any) => {
        // this.docStatus = data.originality
        this.submission = data.submission
        let translatedLangs;
        if (this.submission) {
          if (this.submission?.translatedLanguage && this.submission?.translatedLanguage.split('').includes('[')) {
            translatedLangs = JSON.parse(this.submission?.translatedLanguage)
          } else {
            translatedLangs = [this.submission?.translatedLanguage]
          }
        }
        this.hasTranslatedLanguages = translatedLangs.length > 0;

        this.imagesDetectionData = data?.imagesDetection;
        this.sentenceDetails = data.sentenceDetails
        this.allManipulations = data.manipulations
        this.initialSources = {
          originalSources: data.originalSources,
          excludedOriginalSources: data.excludedSourcesMlPlag,
          translatedSources: data.translatedSources,
          excludedSourcesMlPlag: data.exludedSourcesInTranslation || {}
        }
        this.initialNavigationData = {
          originalNavigationArray: data.originalNavigationArray,
          translatedNavigationArray: data.translatedNavigationArray
        }
        this.assignValues(data)
        this.filterSources(this.selectedSourcesFilter)
        // this.spinnerService.hide()
        this.transformData(data)
        this.reportService.documentPercentagesSubject.next({
          originalPercentage: data.submission.originalPercentage.toFixed(2), originalPercentageWithoutQuotes: data.submission.originalPercentageWithoutQuotes.toFixed(2),
          overallTransltedPercentage: data.submission.translatedPercentage.toFixed(2), overallTransltedPercentageWithoutQuotes: data.submission.translatedPercentageWithoutQuotes.toFixed(2)
        })
      },
      (err: any) => {
        console.log(err)
      })
  }

  assignValues(data: any) {
    let translatedNav = [];
    Object.keys(data.translatedNavigationArray).forEach((lang: any) => {
      if (Array.isArray(data.translatedNavigationArray[lang])) {
        data.translatedNavigationArray[lang].forEach((page: any) => {
          const existingPage = translatedNav.find((item: any) => item.page === page.page);

          if (existingPage) {
            existingPage.numberOfSentences += page.numberOfSentences;
          } else {
            translatedNav.push({
              page: page.page,
              numberOfSentences: page.numberOfSentences
            })
          }
        })
      } else {
        translatedNav = data.translatedNavigationArray;
      }
    })

    this.navigationDetails = this.isOriginalLanguage ? data.originalNavigationArray : translatedNav
    this.sources = {
      sources: this.isOriginalLanguage ? data.originalSources : data.translatedSources,
      excludedSources: this.isOriginalLanguage ? data.excludedOriginalSources : data.excludedSourcesMlPlag,
    }

    this.similarityAnalysis = {
      originalSources: data.originalSources,
      translatedSources: data.translatedSources,
      submission: {
        originalPercentage: data.submission.originalPercentage,
        translatedPercentage: data.submission.translatedPercentage,
        originalLanguage: data.submission.originalLanguage,
        translatedLanguage: data.submission.translatedLanguage,
        originalPercentageWithoutQuotes: data.submission.originalPercentageWithoutQuotes,
        translatedPercentageWithoutQuotes: data.submission.translatedPercentageWithoutQuotes,
        translatedPercentages: data.submission.translatedPercentages
      }
    }

    this.aiAnalysis = {
      aiPercentage: data.submission.aiPercentage,
      checkAI: data.submission.aiDetection,
      originalSources: data.originalSources,
    }

    this.bannerDetails = {
      title: data.submission.title,
      original_language: data.submission.originalLanguage,
      translated_language: data.submission.translatedLanguage,
      ai_authorship_analysis: data.submission.aiDetection,
      file_size: data.submission.size,
      word_count: data.submission.wordCount,
      page_count: data.originalNavigationArray.length
    }
  }

  toggleIsOnAnalysis(): void {
    this.isOnAnalysis = !this.isOnAnalysis;
    this.fileUrl = this.tempPdfSrc;
  }

  isOnAnalysisChange(analysis: boolean) {
    this.isOnAnalysis = analysis;
    this.isOnAi = false;
    this.isOnManipulation = false;
    this.isOnCohort = false;
    if (!this.isOnAnalysis) {
      this.fileUrl = this.pdfImagesSrc;
    } else {
      this.fileUrl = this.tempPdfSrc;
    }
  }

  isOnAiChange(ai: boolean) {
    this.isOnAi = ai;
    this.isOnAnalysis = false;
    this.isOnManipulation = false;
    ai ? this.navigationDetails = this.navigationArrayAI : this.navigationDetails = this.initialNavigationData.originalNavigationArray
    if (!this.isOnAi) {
      this.fileUrl = this.pdfImagesSrc;
    } else {
      this.fileUrl = this.tempPdfSrc;
    }
  }

  isOnManipulationChange(manipulation: boolean) {
    this.isOnManipulation = manipulation;
    this.isOnAi = false;
    this.isOnAnalysis = false;

    if (manipulation) {
      this.fileUrl = this.tempPdfSrc;
    } else {
      this.fileUrl = this.pdfImagesSrc;
      this.navigationDetails = this.initialNavigationData.originalNavigationArray
    }
  }

  onManipulationsCalculated(manipulations: any) {
    if (this.isOnManipulation) {
      this.navigationDetails = manipulations;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.currentPageSubscription.forEach((sub) => {
      sub.unsubscribe()
    })

    this.destroy$.next();
    this.destroy$.complete();
  }


  changeView(view: string, languageType?: string) {

    this.reportService.sentenceInformationSubject.next(null)
    if (view == 'dashboard') {
      this.fileUrl = this.pdfImagesSrc
      this.reportService.selectAnalysisTypeSubject.next('none');
      this.isOnAnalysis = false;
      this.isOnAi = false;
      this.isOnManipulation = false;
      this.isOnCohort = false;
      this.reportService.selectLanguageSubject.next({
        language: this.similarityAnalysis.submission.originalLanguage,
        type: 'Original'
      })
      this.documentDataService.setCurrentPage(1)
    } else if (view == 'original_lang' || view == 'translated_lang') {
      this.reportService.selectAnalysisTypeSubject.next('analysis');
      this.isOnAnalysis = true;
      this.isOnAi = false;
      this.isOnManipulation = false;
      this.isOnCohort = false;
      this.fileUrl = this.tempPdfSrc
      this.updateNavigationArray()
      this.documentDataService.setCurrentPage(1)
      if (languageType) {
        let translatedLang = '';
        if (this.similarityAnalysis.submission.translatedLanguage.split('').includes('[')) {
          // translatedLang = JSON.parse(this.similarityAnalysis.submission.translatedLanguage)[0]
          this.reportService.selectLanguageSubject.next({
            language: languageType === 'Original' ? this.getLanguageName(this.similarityAnalysis.submission.originalLanguage) : 'multiple_languages',
            type: languageType
          })
        } else {
          translatedLang = this.similarityAnalysis.submission.translatedLanguage
          this.reportService.selectLanguageSubject.next({
            language: languageType === 'Original' ? this.getLanguageName(this.similarityAnalysis.submission.originalLanguage) : this.getLanguageName(translatedLang),
            type: languageType
          })
        }

      }

    } else if (view == 'ai') {
      this.reportService.selectAnalysisTypeSubject.next('ai')
      this.isOnAnalysis = false;
      this.isOnAi = true;
      this.isOnManipulation = false;
      this.isOnCohort = false;
      this.fileUrl = this.tempPdfSrc
      this.isOnAiChange(true)
      this.documentDataService.setCurrentPage(1)
    } else if (view == 'manipulation') {
      this.reportService.selectAnalysisTypeSubject.next('manipulation')
      this.isOnAnalysis = false;
      this.isOnAi = false;
      this.isOnCohort = false;
      this.isOnManipulation = true;
      this.fileUrl = this.tempPdfSrc
      this.documentDataService.setCurrentPage(1)
    } else if (view == 'isOnCohort') {
      this.isOnCohort = true
      this.isOnAnalysis = false;
      this.isOnAi = false;
      this.isOnManipulation = false;
      this.documentDataService.setCurrentPage(1)
    }
  }

  transformData(data: any) {
    const { sentenceDetails, manipulations, submission, imagesDetection } = data;
    this.summaryDetails = sentenceDetails
      .filter((sentence: any) => {

        // Check if sentence has primary source, primary translated source, AI text, or any manipulations
        const hasPrimarySource = !!sentence?.primarySource;

        let hasPrimaryTranslatedSources;
        if (sentence?.translatedFindings) {
          hasPrimaryTranslatedSources = Object.keys(sentence?.translatedFindings || {}).some((lang: any) => {
            return !!sentence?.translatedFindings[lang]?.primarySource;
          });
        }
        else {
          hasPrimaryTranslatedSources = !!sentence?.primaryTranslatedSource;
        }
        const hasAiText = !!sentence?.aiText?.aiText;
        const hasManipulations = !!manipulations && (
          manipulations?.replacedCharacters?.some((item: any) => sentence?.replacedCharacters?.includes(item.character)) ||
          manipulations?.excludedReplacedCharacters?.some((item: any) => sentence?.replacedCharacters?.includes(item.character)) ||
          !!sentence?.characters?.length
        );

        return hasPrimarySource || hasPrimaryTranslatedSources || hasAiText || hasManipulations;
      })
      .map((sentence: any) => {
        const replacedCharacters = sentence?.replacedCharacters || [];
        const includedCharacters = manipulations?.replacedCharacters.filter((item: any) => {
          return replacedCharacters.includes(item.character);
        });

        const excludedReplacedCharacters = manipulations?.excludedReplacedCharacters.filter((item: any) => {
          return replacedCharacters.includes(item.character);
        });
        // console.log(excludedReplacedCharacters, 'excludedReplacedCharacters');



        const characters = sentence?.characters || [];
        const manipulationsData = [
          {
            includedCharacters,
            excludedReplacedCharacters
          },
          {
            characters,
            isExcluded: sentence?.whitecharactersExcluded || false
          }
        ];

        let translated;
        let isTranslated;

        if (sentence?.translatedFindings) {
          translated = Object.keys(sentence?.translatedFindings).map((lang) => {
            const finding = sentence?.translatedFindings[lang];
            const allTranslatedPercentages = submission.translatedPercentages;
            return {
              lang,
              percentage: allTranslatedPercentages !== undefined ? (allTranslatedPercentages[lang]?.percentageWithoutQuotes ? allTranslatedPercentages[lang]?.percentageWithoutQuotes : allTranslatedPercentages[lang]?.percentage) : finding?.percentage,
              sentence: finding?.primarySource?.sourceSentence,
              source: finding?.primarySource,
              isExcluded: finding?.isTranslationExcluded,
              comment: finding?.translateComment,
              secondarySources: finding?.secondarySources,
              text: sentence?.translatedFindings[lang].translatedText
            };
          });
          isTranslated = translated.some((item: any) => item.source);
        } else {
          const primaryTranslatedSource = sentence?.primaryTranslatedSource;
          translated = sentence?.translatedText
            ?
            [{
              lang: submission?.translatedLanguage,
              percentage: primaryTranslatedSource?.percentage,
              sentence: primaryTranslatedSource?.sourceSentence,
              source: primaryTranslatedSource,
              isExcluded: sentence?.isTranslationExcluded,
              comment: sentence?.translateComment,
              secondarySources: sentence?.secondarySources,
              text: sentence?.translatedText
            }]
            :
            [];
          isTranslated = !!sentence?.translatedText;
        }

        const isManipulated = {
          isManipulated: (includedCharacters?.length > 0) || (characters?.length > 0 && !sentence?.whitecharactersExcluded),
          isCharReplacement: includedCharacters?.length > 0,
          isHiddenText: characters?.length > 0 && !sentence?.whitecharactersExcluded
        };
        return {
          submissionId: submission.id,
          text: sentence?.text,
          sentence: sentence?.sourceSentence,
          source: sentence?.primarySource,
          comment: sentence?.comment,
          id: sentence?.id,
          isOriginal: !!(sentence?.sourceSentence || sentence?.primarySource),
          isTranslated: isTranslated,
          isManipulated,
          isExcluded: sentence?.isExcluded,
          isAi: sentence?.aiText?.aiText,
          isCitation: sentence.isCitation,
          similarity: sentence?.sourceSentencePercentage,
          page: sentence?.page,
          secondarySources: sentence?.secondarySources,
          manipulations: manipulationsData,
          translated
        };
      });

    this.cdr.detectChanges();
  }

  // Create a function without parameters that calls transformData and call it getTransformData
  getTransformData() {
    this.getS3Json(this.submissionDetails.presignedUrlJson);
  }

  getLanguageName(languageCode: string): string {
    const language = this.languages.find((lang) => lang.value === languageCode);
    return language ? language.text : 'Unknown Language';
  }

  checkIfFileExists(url: string): Promise<boolean> {
    return this.http.get(url, { responseType: 'blob', observe: 'response' }).toPromise()
      .then(response => {
        return response.status === 200;
      })
      .catch(error => {
        if (error.status === 404 || error.status === 403) {
          return false;
        }
        return false;
      });
  }

}
