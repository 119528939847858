<div class="edit-student-modal">
   <div class="edit-student-title">
      <h4>{{ 'app.edit_student' | translate }}</h4>
   </div>
   <form [formGroup]="studentEdit">
      <div class="fieldContainer">
         <div class="inside-inputs">
            <label for="studentName">{{ 'general.name' | translate }}</label>
            <input id="studentName" type="text" formControlName="studentName" />
            <div *ngIf="this.emailExists" class="invalid">
               Email already exists, please try another one.*
            </div>
            <div
               *ngIf="
                  studentEdit.controls.studentName.touched &&
                  studentEdit.controls.studentName.invalid
               "
               class="invalid"
            >
               {{ 'general.full_name' | translate }} {{ 'aria-labels.is_required' | translate }}
            </div>
            <div
               *ngIf="studentEdit.controls.studentName.errors?.minlength"
               class="invalid"
            >
               {{ 'general.full_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}.
            </div>
            <label for="Email">{{ 'general.email' | translate }}</label>
            <input
               id="Email"
               type="email"
               formControlName="studentEmail"
            />
            <div
               *ngIf="
                  studentEdit.controls.studentEmail.touched &&
                  studentEdit.controls.studentEmail.invalid
               "
               class="invalid"
            >
               {{ 'general.student' | translate }} {{ 'login.email_required' | translate }}*
            </div>
            <div
               *ngIf="studentEdit.controls.studentEmail.errors?.email"
               class="invalid"
            >
            {{ 'app.student_email_is_not_valid' | translate }}!
            </div>
            <!-- <label for="PhoneNumber">{{ 'general.phone' | translate }}</label>
            <input
               id="PhoneNumber"
               type="text"
               formControlName="studentPhoneNumber"
            />
            <div
               *ngIf="
                  studentEdit.controls.studentPhoneNumber.touched &&
                  studentEdit.controls.studentPhoneNumber.invalid
               "
               class="invalid"
            >
               {{ 'general.student' | translate }} {{ 'app.contact_number' | translate }} {{ 'general.end_date' | translate }}*
            </div>
            <div
               *ngIf="studentEdit.controls.studentPhoneNumber.errors?.minlength"
               class="invalid"
            >
               {{ 'general.student' | translate }} {{ 'app.contact_number' | translate }} {{ 'app.should_contain_at_least_6_characters' | translate }}!
            </div> -->

            <label for="IndexId">{{ 'app.student_id' | translate }}</label>
            <input
               id="IndexId"
               type="text"
               formControlName="studentIndexID"
               (ngModelChange)="validateIndex()"
            />
            <div *ngIf="this.indexExists" class="invalid">
               {{ 'app.student_id_already_exists' | translate }}*
            </div>
            <!-- <div
               *ngIf="
                  studentEdit.controls.studentIndexID.touched &&
                  studentEdit.controls.studentIndexID.invalid
               "
               class="invalid"
            >
               {{ 'app.student_id_is_required' | translate }}*
            </div>
            <div
               *ngIf="studentEdit.controls.studentIndexID.errors?.minlength"
               class="invalid"
            >
               {{ 'app.student_id_should_contain_three_characters' | translate }}!
            </div> -->

            <label for="title">{{ 'app.institution' | translate }}</label>
            <select
               formControlName="studentFaculty"
               (change)="facultyChanged()"
            >
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}" selected>
                     {{ faculty.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  studentEdit.controls.studentFaculty.touched &&
                  studentEdit.controls.studentFaculty.invalid
               "
               class="invalid"
            >
               {{ 'general.student' | translate }} {{ 'app.institution' | translate }} {{ 'general.end_date' | translate }}*
            </div>
            <label for="title">{{ 'general.department' | translate }}</label>
            <select formControlName="studentDepartment">
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}" selected>
                     {{ department.name }}
                  </option>
               </ng-container>
            </select>
            <div
               *ngIf="
                  studentEdit.controls.studentDepartment.touched &&
                  studentEdit.controls.studentDepartment.invalid
               "
               class="invalid"
            >
               {{ 'general.student' | translate }} {{ 'general.department' | translate }} {{ 'aria-labels.is_required' | translate }}*
            </div>
            <label for="title">{{ 'general.status' | translate }}</label>
            <select formControlName="studentStatus">
               <option value="1">{{ 'general.active' | translate }}</option>
               <option value="0">{{ 'general.inactive' | translate }}</option>
            </select>
            <label for="title">{{ 'app.role' | translate }}</label>
            <div>
               <select formControlName="role">
                  <option value="9" disabled selected>Student</option>
                  <option value="10">Educator</option>
               </select>
            </div>
         </div>
         <div class="buttons">
            <a class="delete" (click)="delete()">{{ 'app.delete' | translate }}</a>
            <button
               class="save"
               [disabled]="
                  studentEdit.invalid || this.emailExists || this.indexExists
               "
               [ngClass]="{
                  disable:
                     studentEdit.invalid || this.emailExists || this.indexExists
               }"
               type="submit"
               (click)="update()"
            >
               {{ 'app.confirm' | translate }}
            </button>
         </div>
      </div>
   </form>
</div>
<div id="overlay"></div>
