import {
   Component,
   ElementRef,
   OnDestroy,
   OnInit,
   ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import * as assignmentsActions from '../state/action/units-statistics-assignments.actions';
import * as facultyActions from '../../../../state/faculty/action/faculty.actions';
import * as professorActions from '../../../../../administrator-components/state/professor/action/professor.actions';
import * as filtersActions from '../../../../../administrator-components/state/filters/action/filters.actions';

import { getAssignmentsStatisticsDetails } from '../../assignments/state/selector/units-statistics-assignments.selectors';
import { getInstitutionFacultiesStateDetails } from '../../../../state/faculty/selector/faculty.selectors';
import { getFiltersStateDetails } from '../../../../../administrator-components/state/filters/selector/filters.selectors';
import { getInstitutionProfessorsStateDetails } from '../../../../../administrator-components/state/professor/selector/professor.selectors';

import { DepartmentService } from 'src/app/services/department.service';
import { LanguageService } from 'src/app/services/language.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdministratorStatisticsService } from 'src/app/services/administrator-statistics.service';
import { CsvExportService } from 'src/app/services/csv-export.service';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import { PdfExportService } from 'src/app/services/pdf-export.service';
import { DatePipe } from '@angular/common';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { forkJoin, from } from 'rxjs';
import {
   concatMap,
   mergeMap,
   map,
   catchError,
   filter,
   retryWhen,
   delay,
   take,
   tap,
   toArray,
   timeout,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SubmissionsService } from 'src/app/services/submissions.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-assignments-statistics',
   templateUrl: './assignments-statistics.component.html',
   styleUrls: ['./assignments-statistics.component.scss'],
})
export class AssignmentsStatisticsComponent implements OnInit, OnDestroy {
   departments: any;
   facultyId = '-1';
   departmentId = '-1';
   subjectName = '';
   professors;
   professor = '-1';
   getFiltersState = true;
   expandInfo: any;
   assignments;
   assignmentId;
   page: number = 1;
   assignmentName = '';
   timer: any;
   totalAssignments;
   faculties;
   faculties$;
   getInstitutionProfessorsStateDetails$: any;
   getInstitutionFacultiesStateDetails$: any;
   getAssignmentsStatisticsDetails$: any;
   getFiltersStateDetails$: any;
   languageName: string;
   @ViewChild('contentToCapture') contentToCapture: ElementRef;
   reports = [
      { name: 'Offline Report', selected: false },
      { name: this.translate.instant('general.submission_files'), selected: false },
    ]
   selectedAssignmentIds: number[] = [];
   user;
   allSelected: boolean = false;
   constructor(
      private store: Store,
      private departmentService: DepartmentService,
      private router: Router,
      private languageService: LanguageService,
      private csvExportService: CsvExportService,
      private excelExportService: ExcelExportService,
      private pdfExportService: PdfExportService,
      private spinner: NgxSpinnerService,
      private administratorStatisticsService: AdministratorStatisticsService,
      private datePipe: DatePipe,
      private http: HttpClient,
      private submissionsService: SubmissionsService,
      private userService: UserService,
      private toastrService: ToastrService,
      private translate: TranslateService
   ) {}

   ngOnDestroy(): void {
      this.getInstitutionProfessorsStateDetails$.unsubscribe();
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.getAssignmentsStatisticsDetails$.unsubscribe();
      this.getFiltersStateDetails$.unsubscribe();
   }

   ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.translate.use(language);
    });
      this.user = JSON.parse(localStorage.getItem('user'));
      this.getFiltersStateDetails$ = this.store
         .select(getFiltersStateDetails)
         .subscribe((data) => {
            if (data.assignmentFilters.faculty != null) {
               this.facultyId = data.assignmentFilters.faculty;
               this.departmentService
                  .getDepartmentsOfFaculty(this.facultyId)
                  .pipe(first())
                  .subscribe(
                     (departmentsDetails) => {
                        this.departments = departmentsDetails.departments;
                        if (data.assignmentFilters.department != null) {
                           this.departmentId =
                              data.assignmentFilters.department;
                           this.store.dispatch(
                              assignmentsActions.loadUnitsStatisticsAssignments(
                                 {
                                    page: this.page,
                                    title: this.assignmentName,
                                    faculty:
                                       this.facultyId == '-1'
                                          ? undefined
                                          : this.facultyId,
                                    department:
                                       this.departmentId == '-1'
                                          ? undefined
                                          : this.departmentId,
                                    subject: this.subjectName,
                                    professor:
                                       this.professor == '-1'
                                          ? undefined
                                          : this.professor,
                                 }
                              )
                           );
                        }
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            }
            if (data.assignmentFilters.subject != null) {
               this.subjectName = data.assignmentFilters.subject;
            }
            if (data.assignmentFilters.professor != null) {
               this.professor = data.assignmentFilters.professor;
            }
         });

      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );

      this.store.dispatch(professorActions.loadProfessorsDetails({ page: 0 }));

      this.getInstitutionProfessorsStateDetails$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data.professors !== null && this.professors == undefined) {
               this.professors = data.professors;
            }
         });

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data.faculties !== null && this.faculties == undefined) {
               this.faculties = data.faculties;
            }
         });

      this.getAssignmentsStatisticsDetails$ = this.store
         .select(getAssignmentsStatisticsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.assignments = data.assignmentsStatistics;
               this.totalAssignments = data.assignmentsNumber;
               if (this.assignments !== null) {
                  this.restoreSelectionState();
               }
            }
         });
   }

   setLanguageName(lang) {
      if (lang == '-') {
         return '-';
      }
      return this.languageService.findLanguageName(lang);
   }

   filterWithTimeout() {
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      let time;
      time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            assignmentsActions.loadUnitsStatisticsAssignments({
               page: this.page,
               title: this.assignmentName,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               subject: this.subjectName,
               professor: this.professor == '-1' ? undefined : this.professor,
            })
         );
      }, time);
   }

   selectedFaculty() {
      this.page = 1;
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }

   selectedDepartment() {
      this.page = 1;
      this.getFiltersState = false;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   filterByProfessor() {
      this.getFiltersState = false;
      if (this.professor == 'All') {
         this.professor = '-1';
      }
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.page = 1;
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   expand(assignmentId) {
      this.assignmentId = assignmentId;
      if (this.expandInfo !== assignmentId) {
         this.expandInfo = assignmentId;
      } else {
         this.expandInfo = -1;
      }
   }

   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.professor = '-1';
      this.subjectName = '';
      this.assignmentName = '';
      this.page = 1;
      this.store.dispatch(
         filtersActions.assignmentFilters({
            professor: this.professor,
            department: this.departmentId,
            faculty: this.facultyId,
            subject: this.subjectName,
         })
      );
      this.store.dispatch(
         assignmentsActions.loadUnitsStatisticsAssignments({
            page: this.page,
            title: this.assignmentName,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            subject: this.subjectName,
            professor: this.professor == '-1' ? undefined : this.professor,
         })
      );
   }

   filterAssignments(assignmentName) {
      this.store.dispatch(
         filtersActions.assignmentSubmissionsFilters({
            assignment: assignmentName,
         })
      );
      this.router.navigateByUrl(
         '/administrator/statistics/submissions/assignment-submissions'
      );
   }

   prepareExportData(data) {
      let rows = [
         ['Assignments'],
         [''],
         [
            'Assignment name',
            'Subject',
            'Educator',
            'Start date',
            'End Date',
            'Submissions',
            'Avg. of document language',
            'Avg. of translated language',
            'Most used language',
            'Students',
            'High similarity submissions',
            'Medium similarity submissions',
            'Low similarity submissions',
            'Ratios',
            'Student-to-Submissions',
         ],
      ];

      data.assignmentsDetails.forEach((assignment) => {
         rows.push([
            `"${assignment.assignment.name}"`,
            assignment.assignment.Course.title,
            assignment.assignment.user.name,
            this.datePipe.transform(
               assignment.assignment.startDate,
               'dd/MM/yyyy hh:mm'
            ),
            assignment.assignment.endDate.includes('2700')
               ? 'Never'
               : this.datePipe.transform(
                    assignment.assignment.endDate,
                    'dd/MM/yyyy hh:mm'
                 ),
            assignment.submissions._count,
            typeof assignment.submissions._avg.originalPercentage === 'number'
               ? assignment.submissions._avg.originalPercentage.toFixed(2)
               : '-',
            typeof assignment.submissions._avg.translatedPercentage === 'number'
               ? assignment.submissions._avg.translatedPercentage.toFixed(2)
               : '-',
            this.setLanguageName(
               assignment.language ? assignment.language[0] : '-'
            ),
            assignment.students,
            assignment.highSimilaritySubmissions,
            assignment.mediumSimilaritySubmissions,
            assignment.lowSimilaritySubmissions,
            '',
            `${assignment.students}:${assignment.submissions._count}`,
         ]);
      });

      return rows;
   }

   exportToCSV() {
      this.spinner.show();

      this.administratorStatisticsService
         .academicUnitsAssignmentsStatistics(
            -1,
            this.assignmentName,
            this.facultyId == '-1' ? undefined : this.facultyId,
            this.departmentId == '-1' ? undefined : this.departmentId,
            this.subjectName,
            this.professor == '-1' ? undefined : this.professor
         )
         .subscribe((data) => {
            const rows = this.prepareExportData(data);
            this.csvExportService.exportToCSV(rows, 'Academic Units (Total assignments) - Assignments Report');
            this.spinner.hide();
         });
   }

   exportToExcel() {
      this.spinner.show();
      this.administratorStatisticsService
         .academicUnitsAssignmentsStatistics(
            -1,
            this.assignmentName,
            this.facultyId == '-1' ? undefined : this.facultyId,
            this.departmentId == '-1' ? undefined : this.departmentId,
            this.subjectName,
            this.professor == '-1' ? undefined : this.professor
         )
         .subscribe((data) => {
            const rows = this.prepareExportData(data);
            this.excelExportService.exportToExcel(rows, 'Academic Units (Total assignments) - Assignments Report');
            this.spinner.hide();
         });
   }

   exportToPDF() {
      if (this.contentToCapture) {
         this.pdfExportService.captureAndExportPDF(
            this.contentToCapture.nativeElement,
            'Academic Units (Total assignments) - Assignments Report.pdf'
         );
      } else {
         console.error('Content to capture is not available yet.');
      }
   }

   toggleAssignmentSelection(assignmentId: number): void {
      if (this.selectedAssignmentIds.includes(assignmentId)) {
         this.selectedAssignmentIds = this.selectedAssignmentIds.filter(
            (id) => id !== assignmentId
         );
      } else {
         this.selectedAssignmentIds.push(assignmentId);
      }
      this.restoreSelectionState();
   }

   toggleSelectAll(event: any): void {
    this.allSelected = event.target.checked;
    this.assignments.forEach(assignment => {
      assignment.assignment.selected = this.allSelected;
      // Add or remove assignment ID from selectedAssignmentIds array
      if (this.allSelected && !this.selectedAssignmentIds.includes(assignment.assignment.id)) {
        this.selectedAssignmentIds.push(assignment.assignment.id);
      } else if (!this.allSelected) {
        this.selectedAssignmentIds = [];
      }
    });
  }


   restoreSelectionState(): void {
    this.allSelected = this.assignments.every(assignment => this.selectedAssignmentIds.includes(assignment.assignment.id));
      this.assignments.forEach((assignment) => {
         assignment.assignment.selected = this.selectedAssignmentIds.includes(
            assignment.assignment.id
         );
      });
   }

   toggleSelection(report: any) {
      report.selected = !report.selected;
   }

   anyReportsSelected(): boolean {
      return this.reports.some((report) => report.selected);
   }

   anyAssignmentsSelected(): boolean {
      return this.selectedAssignmentIds.length > 0;
   }

   retryWithDelay = (delayMs: number, maxRetry: number) =>
      retryWhen((errors) => errors.pipe(delay(delayMs), take(maxRetry)));

   downloadReport(submissionId: string, reportType: string) {
      return this.http
         .get(
            `${environment.apiUrl}/submission/${submissionId}/offlineReport?reportType=${reportType}&bulk=true`,
            { responseType: 'json' }
         )
         .pipe(
            timeout(60000), // Cancel the request if it takes longer than 1 minute
            this.retryWithDelay(1000, 3),
            catchError((error) => {
               console.error(
                  `Failed to download report for submission ${submissionId}`,
                  error
               );
               return of(null);
            })
         );
   }

   downloadAiReport(submissionId: string) {
      return this.http
         .get(
            `${environment.apiUrl}/submission/${submissionId}/aiOfflineReport?reportType='test'`,
            { responseType: 'json' }
         )
         .pipe(
            timeout(60000), // Cancel the request if it takes longer than 1 minute
            this.retryWithDelay(1000, 3),
            catchError((error) => {
               console.error(
                  `Error fetching AI report for submission ${submissionId}`,
                  error
               );
               return of(null);
            })
         );
   }

   downloadOriginalDocument(submissionId: string) {
      return this.http
         .get(`${environment.apiUrl}/document/${submissionId}`, {
            responseType: 'json',
         })
         .pipe(
            timeout(60000), // Cancel the request if it takes longer than 1 minute
            this.retryWithDelay(1000, 3),
            catchError((error) => {
               console.error(
                  `Error fetching original document for submission ${submissionId}`,
                  error
               );
               return of(null);
            })
         );
   }

   downloadAll() {
      this.spinner.show();
      this.userService.spinnerSubject.next(true);
      this.userService.messageSubject.next('Hang on this may take a while.');

      this.submissionsService
         .submissionDetailsForOfflineExport(this.selectedAssignmentIds)
         .pipe(
            catchError((error) => {
               console.error(
                  'Error fetching submission details for offline export',
                  error
               );
               this.userService.spinnerSubject.next(false);
               this.userService.messageSubject.next('');
               this.spinner.hide();
               return of(null);
            }),
            mergeMap((response) => {
               if (!response || !response.submissions) {
                  return of(null);
               }

               const submissions = response.submissions;
               const zip = new JSZip();
               let fileCount = 0;
               let processedCount = 0;
               let failedCount = 0;
               const failedSubmissions = [];
               const startTime = new Date().getTime(); // Record the start time

               // Create the report types array based on selected reports
               const reportTypes = [];
               if (this.reports.find((report) => report.name === 'Offline Report' && report.selected)) {
                  reportTypes.push({ type: 'crossplag', label: 'Original' });
               }

               if (this.reports.find((report) => report.name === 'Submission files' && report.selected)) {
                  reportTypes.push({ type: 'document', label: 'Document' });
               }
               // Calculate total reports dynamically
               let totalReports = 0;
               submissions.forEach((submission) => {
                  if (
                     submission.processed !== 1
                  ) {
                     return false;
                  }
                  totalReports++;
                  return true;
               });
               // Batch processing
               const batchSize = 5; // Adjust batch size as needed
               const submissionBatches = [];
               for (let i = 0; i < submissions.length; i += batchSize) {
                  submissionBatches.push(submissions.slice(i, i + batchSize));
               }

               return from(submissionBatches).pipe(
                  concatMap((batch) =>
                     forkJoin(
                        batch.map((submission: any) =>
                           from(reportTypes).pipe(
                              filter(() => {
                                 return submission.processed === 1;
                               }),
                              concatMap((reportType: any) => {
                                 let downloadObservable;
                                 this.userService.messageSubject.next(`Hang on this may take a while. ${totalReports} files are being generated...`);
                                 if (reportType.type === 'ai') {
                                    downloadObservable = this.submissionsService
                                       .downloadAiReport(submission.id)
                                       .pipe(
                                          concatMap((data: any) => {
                                             if (
                                                data &&
                                                data.presignedS3UrlDownload
                                             ) {
                                                return this.http
                                                   .get(
                                                      data.presignedS3UrlDownload,
                                                      {
                                                         responseType:
                                                            'arraybuffer',
                                                      }
                                                   )
                                                   .pipe(
                                                      map(
                                                         (
                                                            fileData: ArrayBuffer
                                                         ) => ({
                                                            blob: new Blob(
                                                               [fileData],
                                                               {
                                                                  type: 'application/pdf',
                                                               }
                                                            ),
                                                            filename: `${submission.title}-${reportType.label}.pdf`,
                                                         })
                                                      )
                                                   );
                                             }
                                             return of(null);
                                          })
                                       );
                                 } else if (reportType.type === 'document') {
                                    downloadObservable = this.submissionsService
                                       .downloadOriginalDocument(submission.id)
                                       .pipe(
                                          concatMap((data: any) => {
                                             if (data && data.urlToDownload) {
                                                return this.http
                                                   .get(data.urlToDownload, {
                                                      responseType:
                                                         'arraybuffer',
                                                   })
                                                   .pipe(
                                                      map(
                                                         (
                                                            fileData: ArrayBuffer
                                                         ) => ({
                                                            blob: new Blob(
                                                               [fileData],
                                                               {
                                                                  type: 'application/pdf',
                                                               }
                                                            ),
                                                            filename: `${submission.title}-${reportType.label}.pdf`,
                                                         })
                                                      )
                                                   );
                                             }
                                             return of(null);
                                          })
                                       );
                                 } else {
                                    downloadObservable = this.submissionsService
                                       .downloadReport(
                                          submission.id,
                                          reportType.type
                                       )
                                       .pipe(
                                          concatMap((data: any) => {
                                             if (data) {
                                                return this.http
                                                   .get(
                                                      data.presignedS3UrlDownload,
                                                      {
                                                         responseType:
                                                            'arraybuffer',
                                                      }
                                                   )
                                                   .pipe(
                                                      map(
                                                         (
                                                            fileData: ArrayBuffer
                                                         ) => ({
                                                            blob: new Blob(
                                                               [fileData],
                                                               {
                                                                  type: 'application/pdf',
                                                               }
                                                            ),
                                                            filename: `${submission.title}-${reportType.label}.pdf`,
                                                         })
                                                      )
                                                   );
                                             }
                                             return of(null);
                                          })
                                       );
                                 }

                                 return downloadObservable.pipe(
                                    catchError((error) => {
                                       console.error(
                                          `Error fetching report for submission ${submission.id} and report type ${reportType.type}`,
                                          error
                                       );
                                       failedCount++;
                                       failedSubmissions.push(submission.id);
                                       return of(null);
                                    })
                                 );
                              }),
                              toArray() // Collect all observables for this submission
                           )
                        )
                     )
                  ),
                  tap({
                     next: (results: any) => {
                        results.flat().forEach((result) => {
                           processedCount++;
                           if (result && result.blob) {
                              const uniqueFilename = this.getUniqueFilename(
                                 zip,
                                 result.filename
                              );
                              zip.file(uniqueFilename, result.blob);
                              fileCount++;
                           }
                        });
                        this.updateProgress(processedCount, totalReports);
                     },
                     error: (error) => {
                        console.error(
                           'An error occurred during report export',
                           error
                        );
                        this.userService.spinnerSubject.next(false);
                        this.userService.messageSubject.next('');
                        this.spinner.hide();
                     },
                     complete: async () => {
                        const endTime = new Date().getTime(); // Record the end time
                        const duration = (endTime - startTime) / 1000; // Calculate the duration in seconds
                        console.log(`Total duration: ${duration} seconds`);

                        if(failedCount > 0){
                           const failedIds = 'The documents with the following IDs could not be downloaded:\n\n' + failedSubmissions.join('\n') + '\n\nFor more information on this matter, please contact us at support.originality@inspera.com.';
                           zip.file('failed_submissions.txt', failedIds);
                         }
                        if (fileCount > 0) {
                           console.log('Generating ZIP file...');
                           const content = await zip.generateAsync({
                              type: 'blob',
                           });
                           saveAs(content, 'reports.zip');
                           console.log(
                              'All reports have been bundled into a ZIP file'
                           );
                           this.toastrService.success(
                              'Your reports are generated successfully.'
                           );
                        } else {
                           console.error('No reports were downloaded');
                           this.toastrService.warning(
                              'There are no reports that match the filter criteria'
                           );
                        }

                        console.log(
                           `Failed to download ${failedCount} reports for submissions: ${failedSubmissions.join(
                              ', '
                           )}`
                        );
                        this.userService.spinnerSubject.next(false);
                        this.userService.messageSubject.next('');
                        this.spinner.hide();
                     },
                  })
               );
            })
         )
         .subscribe();
   }

   updateProgress(processedCount: number, totalReports: number) {
      console.log(`Processed ${processedCount} out of ${totalReports} reports`);
   }

   getUniqueFilename(zip: JSZip, filename: string): string {
      // Sanitize the filename by replacing slashes and other problematic characters
      let sanitizedFilename = filename.replace(/[\/\\:*?"<>|]/g, '_');
      let uniqueFilename = sanitizedFilename;
      let counter = 1;
      while (zip.files[uniqueFilename]) {
        uniqueFilename = sanitizedFilename.replace(/(\.\w+)?$/, `(${counter++})$1`);
      }
      return uniqueFilename;
    }
}
