<div class="container">
  <h1>Terms of Use</h1>
  <p><strong>IMPORTANT NOTICE:</strong><br>
  These Terms of Use apply to You if you are using our Platform and you are 18 years of age or over and have contractual capacity to accept it.</p>

  <p>PLEASE READ TERMS OF USE CAREFULLY before clicking "I accept” and continuing using the Platform.</p>

  <p>The Platform is hosted by Inspera AS, a company registered in Norway with company number 998 156 963 (“Us”, “We”, “Our”) as Our central online exam and proctoring solution. The Platform is owned by Us or by a fully owned-subsidiary company and includes without limitation the APIs, code, software, and technology (including upgrades and advancements) that are accessed remotely.</p>

  <p>For the purposes of this Terms of Use, “You” means any individual using Our Platform.</p>

  <p>By clicking “I accept” below You:</p>
  <ul>
     <li>Agree to the Terms of Use;</li>
     <li>Acknowledge our Privacy Notice which You must read carefully as this informs You how and what personal data we collect, share, and store and your rights;</li>
     <li>Provide your Consent to the use of remote proctoring, face detection, and face matching/recognition for the purposes of the Platform; and</li>
     <li>Provide your Consent for Us to train Our artificial intelligence with reference to Your content data for the purposes of business intelligence and improvement.</li>
  </ul>

  <p>These Terms of Use may also direct You to other links and additional policies which You should read in full.</p>

  <p>You are subject to these Terms of Use as well as additional policies which You may have been asked to sign up to by the Organisation.</p>

  <p>The Terms of Use and Privacy Notice may be subject to change without prior notification, each version, however, will be dated accordingly. However, We urge You to read these regularly for any updates or changes. By continuing to access or use the Platform after We update it, You agree to be bound by the revised terms. If You do not agree to the new terms, You must cease your use of the Platform.</p>

  <h2>Scope of Services and use of Platform</h2>
  <p>Your educational institution is an Inspera’s customer (“Organisation”) and it has authorised You, for the purposes of Your educational assessments, to use the Platform as one of their Users via their subscription to Us (on a Software as a Service basis). The Organisation controls their own content and information collected from You on the Platform. We have an agreement with the Organisation to support how they collect this information and We only use information in accordance with the Organisation’s instructions.</p>

  <p>The Organisation may also define the wider community use and sharing of information on Our Platform, and for details on this, You should make reference directly to the Organisation.</p>

  <h2>Use of Our Platform</h2>
  <p><strong>OPERATING SYSTEM REQUIREMENTS:</strong> In order to use the Platform, You will need to refer to Our operating system requirements (which may be updated from time to time) available at: <a href="https://support.inspera.com/hc/en-us/articles/360018510132-System-requirements">https://support.inspera.com/hc/en-us/articles/360018510132-System-requirements</a>.</p>

  <h2>License</h2>
  <p>Following this Terms of Use, Inspera AS licenses You a non-transferable, non-exclusive right to use the Platform without the right of personal or commercial benefit from it.</p>

  <p>To avoid doubt, the license granted to You does not allow you to:</p>
  <ol>
     <li>Modify, copy, distribute, transmit, display, perform, reproduce, publish, license, transfer, damage, collect or sell any information from the Platform without the permission of Inspera AS;</li>
     <li>Rent, lease, sell, loan, translate, merge, adapt, vary, alter or modify the data in whole or any part of the Platform, nor permit the Platform or any part of it to be combined with or become incorporated in any other programs; and</li>
     <li>Disassemble, de-compile, reverse engineer, or create derivative works based on the whole or any part of the Platform, nor attempt to do any such things except to the extent conferred as a right under applicable law.</li>
  </ol>

  <h2>Account and Password</h2>
  <p>As a User, You guarantee that:</p>
  <ul>
     <li>You are registered in the Platform as required by the Organisation; and</li>
     <li>The password with which you log in to the Platform is yours, personal, and given to you by Inspera AS or the Organisation.</li>
  </ul>

  <p>You are solely and personally responsible for any and all uses of your account. If You are notified of any privacy breach or unauthorized use of your account or password, please refer to Contact Us below. However, You agree to maintain your account's confidentiality, password, or any other information that connects you to the Platform. Under no circumstances should You transmit or make your password known to anyone or in any form including, but not limited to, web pages, blogs, complaints, advertisements, or any posting on the internet, public newsletter, or any file that is or can be accessed from any public network.</p>

  <p>You agree that You will not attempt to log in with a password that has not been expressly provided to you by Inspera AS or the Organisation. You also agree to report any suspicions You may have about unauthorized access and use of your account by a third party.</p>

  <h2>Obligations of the User</h2>
  <p>The following are obligations of the User of the Platform:</p>
  <ul>
     <li>To be responsible for all data You place on the Platform, which will be stored and used in accordance with Our Privacy Notice;</li>
     <li>To Comply with the applicable laws and regulations;</li>
     <li>To Comply with these Terms of Use, Privacy Notice, and any third-party requirements We notify You about;</li>
     <li>To be honest and accurate with the information You provide, including Your identity;</li>
     <li>To use the Platform only for the purposes authorized by Your Organisation;</li>
     <li>To acknowledge that You have no right to have access to the Platform in source code form.</li>
  </ul>

  <p>Also, the User must refrain from:</p>
  <ul>
     <li>Acting unlawfully, dishonestly, abusively, in a threatening or harassing way, with discrimination, or to cause offense;</li>
     <li>Interfering or harming anyone’s rights, personal or property rights, including without exclusion infringing any intellectual property rights;</li>
     <li>Posting any content that is untrue, inaccurate, confidential, intended to incite or cause harm, loss, injury, death, or distress, promoting inequality, an invasion of privacy, defamatory, aggressive, discriminatory, intended to hack, unlawful, illegal, or offensive;</li>
     <li>Introducing any automated system such as any trojan horses, “bots”, spam attack, virus, worm, malware, or code or equivalent to Our Platform;</li>
     <li>Disrupting the Platform or advertising or soliciting on the Platform;</li>
     <li>Exceeding any usage (including uploaded content) that has been agreed with your Organisation.</li>
  </ul>

  <h2>Intellectual Property Rights</h2>
  <p>Excluding Your content and the content of Your organization, You acknowledge that all intellectual property rights in the Platform (including without limitation the Platform, images, logos, text, photos, patents, trademarks, videos, and audio) throughout the world belong to Us and Our licensors. Your use of the platform does not provide or transfer to You any intellectual property rights in or to the Platform other than the right to use the Platform in accordance with the Terms of Use.</p>

  <p>We are free to use all and any anonymized feedback, innovations, suggestions, ideas, concepts, techniques, knowledge, and information about Our Platform or its use (“Feedback”) created by You as a User, including developing and using products and services based on this Feedback. We will own and have the exclusive right to use as We elect, including without limitation the rights to copy, reproduce, disclose, distribute, modify, and exploit the findings.</p>

  <h2>Inspera Originality – Specific Terms</h2>
  <p>When You submit a document in Our Platform subject to the Inspera Originality Service, You retain full ownership of the submitted document.</p>

  <p>By submitting a document or other content in Our Platform subject to the Inspera Originality Service, You grant Us a non-exclusive, perpetual, non-refundable, and non-compensatory right to use the documents and content for the sole purpose of providing services and enhancing the quality of Inspera Originality Service. This includes training the artificial intelligence functionality of the Platform. We reserve the right to store and utilize the submitted documents exclusively for the purpose of assessing the similarity between submitted documents or for calculating and specifying the level of similarity along with other services offered by the Inspera Originality Service. This use is strictly limited to these purposes.</p>

  <p>All submitted documents will be retained during this EULA. You may withdraw Your consent/authorization for Inspera Use of the work at any time under the Opt-out option on the Platform, from which moment they will be deleted.</p>

  <h2>Indemnity</h2>
  <p>You agree to indemnify and hold Us and Our parents, subsidiaries, affiliates, officers, employees, agents, partners, and licensors (if any) harmless from any claim, loss, damages, debt, fine, cost, expense, or demand, including reasonable attorneys’ fees, due to or arising out of (a) Your violation of the Terms of Use for the Platform; (b) any applicable law or regulation; (c) violation of any right of a third party; (d) unauthorized use of the Platform where You permitted or failed to protect your identity.</p>

  <h2>Liability and Warranty</h2>
  <p><strong>WE WILL NOT BE LIABLE FOR ANY UPLOADED USER CONTENT WHATSOEVER.</strong></p>

  <p>We do not police the Platform or Customer or User specific utilization, and We are under no obligation to do so. Any information available uploaded by a User or the Organisation is not warranted by Us and may be potentially harmful, offensive, or unreliable, and We are not responsible or liable for such information. We are not responsible for information in the public domain. If We do discover that information is unsuitable or inappropriate, We will at Our sole discretion remove it.</p>

  <p><strong>ALL WARRANTIES, REPRESENTATIONS, CONDITIONS, AND ALL OTHER TERMS OF ANY KIND WHATSOEVER, EXPRESS OR IMPLIED BY STATUTE OR COMMON LAW, ARE TO THE FULLEST EXTENT PERMITTED BY YOUR APPLICABLE LAW EXCLUDED FROM THE PLATFORM USE, AND THE PLATFORM IS PROVIDED TO YOU ON “AS IS” BASIS WITHOUT FITNESS FOR PURPOSE WARRANTY.</strong></p>

  <p><strong>WE ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THE PLATFORM, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</strong></p>

  <p><strong>WE ARE NOT LIABLE FOR DATA EXPORTED AND PUBLISHED DATA THROUGH OUR APIs.</strong></p>

  <p>We do not warrant or undertake that Your use of the Platform will:</p>
  <ul>
     <li>Be accurate, uninterrupted, or error-free; or</li>
     <li>Meet Your requirements; or</li>
     <li>Be free from Vulnerabilities, which shall mean a weakness in the computational logic; or</li>
     <li>Be free from Malware, which shall mean any malicious program or code designed to steal, disrupt, damage, destroy, or gain unauthorized access to computers, computer systems, and/or data components that when exploited results in a negative impact to the confidentiality, integrity, or availability; or</li>
     <li>Be compliant with any specific non-standard Cybersecurity requirements outside of Our own policies.</li>
  </ul>

  <p>Your use of the Platform may be subject to delays and other problems outside of Our control.</p>

  <p>These Terms of Use do not exclude or limit liability for any damage which it would be unlawful to exclude or limit, including without limitation fraud, fraudulent misrepresentation, death, or personal injury caused by Our negligence.</p>

  <p>In the event that You notice a defect or fault in the Platform, please contact the Organisation for any details or maintenance or support.</p>

  <p>We do not endorse or warrant the accuracy or endorse any third-party link (or service), content, or website, and Your use is entirely at Your own risk when using the Platform, and You will be subject to any applicable third-party terms and conditions where directed.</p>

  <h2>Termination</h2>
  <p>We may terminate or suspend your access to the Platform immediately by written notice to You if We believe you committed a breach of these Terms of Use without liability on Our side.</p>

  <p>Upon termination for any reason:</p>
  <ul>
     <li>All rights granted to You under these Terms of Use shall cease; and</li>
     <li>You must cease all activities unauthorized by these Terms of Use.</li>
  </ul>

  <h2>Contact Us</h2>
  <p>If You wish to contact Us in writing, or if any condition in these Terms of Use requires You to give Us notice in writing, You can contact Us via our Help Centre; details can be found at: <a href="https://support.inspera.com/hc/en-us">https://support.inspera.com/hc/en-us</a>. We will confirm receipt of this by contacting You in writing, normally by email.</p>

  <p>If We must contact You or give You notice in writing regarding the use of Our platform/our service, We will do so by email to the email address You provided or confirmed to Us.</p>

  <h2>Other Important Terms</h2>
  <p>We may transfer Our Rights and obligations under these terms to another Organisation. We will always tell You in writing if this happens, and We will ensure that the transfer will not affect Your rights under the contract.</p>

  <p>You may not transfer Your Rights or Your obligations under these Terms of Use to any other person.</p>

  <p>This agreement does not give rise to any third-party rights to enforce any term of this agreement, except that Inspera and its affiliates are able to enforce these Terms of Use.</p>

  <p>Each of these terms operates in isolation. If any court or relevant authority decides that any of them are unlawful, the remaining conditions in these Terms of Use will remain in full force and effect. Any waiver of the Terms of Use shall not be legally binding unless We have provided written notice to You.</p>

  <h2>Applicable Law and Jurisdiction</h2>
  <p>These terms are governed by Norwegian law, and You can bring legal proceedings in respect of the Platform in the courts of Oslo, Norway. You may have additional consumer rights under the laws where You live, for which You should make your own reference.</p>

  <p><a href="https://www.inspera.com/legal/privacy">I have read and acknowledged the Privacy Notice available here.</a></p>

</div>
