import { SubmissionsService } from './../../../../../services/submissions.service';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from '../../../services/language.service';
import { TranslateComponent } from 'src/app/sharedModule/translate/translate.component';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
  selector: 'privacy-settings-modal',
  templateUrl: './privacy-settings-modal.component.html',
  styleUrls: ['./privacy-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacySettingsModalComponent {
  userInfo: any;
  permissions: any;
  isDocumentInArchive: any;
  sourcesAreDeleted: any;
  submissionUserId: any;
  modalOpen;
  loggedInUser: any;
  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data, public translate: TranslateService, private submissionsService: SubmissionsService,private spinner: NgxSpinnerService, private languageService: LanguageService) {
    this.userInfo = data.userInfo;
    this.loggedInUser = JSON.parse(localStorage.getItem('user'))
    this.permissions = data.studentDeletionPermissions;
    this.isDocumentInArchive = data.isDocumentInArchive;
    this.sourcesAreDeleted = data.sourcesAreDeleted;
    this.submissionUserId = data.submission.userId;
  }

  ngOnInit(): void {
    this.modalOpen = true;
    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.translate.use(language);
    });
  }

  deleteEverything() {
    this.permissions.deleteInArchive = !this.permissions.deleteInArchive
  }


  cancel() {
    this.dialog.closeAll();
  }

  submit() {
    if (this.permissions.deleteEverything) {
      this.permissions.deleteInArchive = true
      // this.permissions.deleteSources = true
    }
    let deleteInArchiveText;
    let deleteEverythingText;
    let deleteSourcesText;
    let title;
    if (this.permissions.deleteInArchive && !(this.data.isDocumentInArchive == undefined)) {
      title = this.translate.instant('report.delete_document_from_archive');
      deleteInArchiveText = `</br>`
    } else {
      deleteInArchiveText = ''
    }
    if (this.permissions.deleteEverything) {
      title = this.translate.instant('app.are_you_sure_you_want_to_give_student_permission_to');
      deleteEverythingText = `</br><span style="color:red; display: block; font-weight: bold;font-size: 0.8rem;margin-left:20px"></span>`
    } else {
      deleteEverythingText = ''
    }
    if (this.permissions.deleteSources) {
      deleteSourcesText = `<li style="text-align: left;margin-bottom: 20px;"><b>${this.translate.instant('delete.delete_the_sources')}</b></br>${this.translate.instant('delete.removes_any_source_that_we_found_that_has_similarities_with_this_document')}<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">${this.translate.instant('delete.if_student_deletes_it')}:</span><span style="color:red; display: block; font-weight: bold;font-size: 0.8rem;margin-left:20px">${this.translate.instant('delete.you_will_still_be_able_to_see_the_percentage_of_similarity')}</span></li>`
    } else {
      deleteSourcesText = ''
    }
    if (!deleteInArchiveText && !deleteSourcesText && !deleteEverythingText) {
      title = this.translate.instant('report.student_wont_be_able_to_delete_anything')
    }
    swal.fire({
      title: title,
      html:
        deleteInArchiveText +
        deleteSourcesText +
        deleteEverythingText,
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      denyButtonText: this.translate.instant('general.no'),
    })
      .then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          this.submissionsService.deletionPermissions(this.data.submission.id, this.permissions)
            .pipe()
            .subscribe(
              (data: any) => {
                this.spinner.hide();
                swal.fire('', this.translate.instant('delete.permissions_changed_successfully'), 'info');
              },
              (error) => {
                console.log('error', error);
                this.spinner.hide();
              }
            );
        }
      });
  }

  deleteDocument(type){

    let title;
    let paragraph;

    let deleteText;
    if(type == 0 ){
       title = this.translate.instant('delete.all_the_sources_we_found_similarity_will_be_removed')

       deleteText = `<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">${this.translate.instant('delete.this_is_permanent')}</span>`,
       paragraph = this.translate.instant('delete.sources_deleted_successfully')
       if(this.data.sourcesAreDeleted){
          swal.fire(this.translate.instant('app.sources_of_this_document_are_already_deleted'), '', 'info');
          return true;
       }
    } else if (type == 1){
       title = this.translate.instant('delete.your_document_will_be_removed_from_our_archive');
       deleteText = `${this.translate.instant('delete.after_you_delete_this_document_in_our_archives_we_wont_be_able_to_detect_it')} <span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">This is permanent - you will not be able to revert this</span>`
       paragraph = this.translate.instant('delete.document_deleted_successfully_in_our_archive')
       if(this.data.isDocumentInArchive == false){
         let info: string ="This document is already deleted from our archive"
          swal.fire(info, '', 'info');
          return true;
       }

    } else if(type == 2) {
       title = this.translate.instant('delete.the_document_will_be_deleted_permanently');
       deleteText = `<span style="color:red; display: block; font-weight: bold;font-size: 1rem;margin-left:20px">${this.translate.instant('delete.this_is_permanent')}</span>`
       paragraph = this.translate.instant('delete.document_has_been_deleted')
    }
        swal.fire({
                      title: title,
                      showDenyButton: true,
                      confirmButtonText: this.translate.instant('general.yes'),
                      denyButtonText: this.translate.instant('general.no'),
                      icon: 'warning',
                      html: deleteText
                   })
                   .then((result) => {
                      if (result.isConfirmed) {
                         this.spinner.show();
                         this.submissionsService.deleteSubmissionInElastic(this.data.submission.id,type)
                         .pipe()
                         .subscribe(
                            (data: any) => {
                               this.spinner.hide();
                               swal.close()
                               swal.fire('', `${paragraph}`, 'info').then((result)=>{
                                  if(result.isConfirmed){
                                    if(this.loggedInUser.roleId === 10){
                                      window.location.replace(`${environment.currentUrl}professor/submissions`)
                                    }

                                    if(this.loggedInUser.roleId === 9){
                                      window.location.replace(`${environment.currentUrl}student/submissions`)
                                    }
                                  }
                               })
                            },
                            (error) => {
                               console.log('error', error);
                               this.spinner.hide();
                            }
                         );
                      }
                   });

  }

}


export class DialogContentExampleDialog { }
