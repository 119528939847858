import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakAuthService } from '../services/keycloak-auth.service';
  
@Component({
  template: '<p>Processing authentication...</p>'
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private keycloakService: KeycloakAuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    try {
      const success = await this.keycloakService.handleAuthCallback();
      if (success) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/login']);
      }
    } catch (error) {
      console.error('Error in AuthCallbackComponent', error);
      this.router.navigate(['/login']);
    }
  }
}
