<div class="d-flex justify-flex-end">
   <div class="dropdown">
      <button class="dropbtn">
         <i class="fa fa-download"></i> {{ 'general.export_data' | translate }}
      </button>
      <div class="dropdown-content">
         <a (click)="exportToExcel()">{{ 'general.export_as_excel' | translate }} (.xlsx)</a>
         <a (click)="exportToPDF()">{{ 'general.export_as_pdf' | translate }}</a>
         <a (click)="exportToCSV()">{{ 'general.export_as_csv' | translate }}</a>
      </div>
   </div>
</div>
<br>
<main>
   <h3 *ngIf="
         !(
            this.studentsNumber == 0 &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            name == undefined
         )
      " class="filtersContainer">
      <h4>{{ 'general.students' | translate }}</h4>
      <div class="selectors">
         <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_institution' | translate }}
               </option>
               <ng-container *ngFor="let faculty of this.faculties">
                  <option value="{{ faculty.id }}">
                     {{ faculty.name }}
                  </option>
               </ng-container>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div>
         <div>
            <select disabled *ngIf="facultyId == '-1' || facultyId == ''" name="" id="">
               <option value="">{{ 'general.select_institution_first' | translate }}</option>
            </select>
            <select *ngIf="facultyId !== '-1' && facultyId !== ''" [(ngModel)]="departmentId"
               (change)="departmentFilter()">
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_department' | translate }}
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div>
         <div>
            <input [placeholder]="'placeholders.search_by_students_name' | translate" [(ngModel)]="name"
               (ngModelChange)="titleFilter()" type="text" name="searchByName" />
         </div>
         <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
      </div>
   </h3>

   <div *ngIf="this.studentsNumber != 0" class="scroll white-bg" #contentToCapture>
      <table>
         <tr>
            <th class="bold left">{{ 'general.name' | translate }}</th>
            <th class="bold left">{{ 'general.email' | translate }}</th>
            <th class="bold left">{{ 'general.unique_identifier' | translate }}</th>
            <!-- <th class="bold">{{ 'general.phone' | translate }}</th> -->
            <!-- <th class="bold">{{ 'app.student_id' | translate }}</th> -->
            <th class="bold left">{{ 'app.institution' | translate }}</th>
            <th class="bold left">{{ 'general.department' | translate }}</th>
            <th class="bold">{{ 'general.status' | translate }}</th>
            <th class="bold">{{ 'general.edit' | translate }}</th>
         </tr>
         <ng-container *ngFor="
               let student of this.students
                  | paginate
                     : {
                          itemsPerPage: 10,
                          currentPage: page,
                          totalItems: this.studentsNumber
                       }
            ">
            <tr class="shadow">
               <td class="left">
                  {{ student.name }}
               </td>
               <td class="left">
                  {{ student.email }}
               </td>
               <td class="left">
                  {{ student.indexId }}
               </td>
               <!-- <td>
                  {{ student.phoneNumber }}
               </td> -->
               <!-- <td>
                  {{ student.indexId }}
               </td> -->
               <td class="left">
                  {{ student.Faculty.name }}
               </td>
               <td class="left">
                  {{ student.Department.name }}
               </td>
               <td>
                  <ng-container *ngIf="student.status === 1">
                     {{ 'general.active' | translate }}
                  </ng-container>
                  <ng-container *ngIf="student.status === 0">
                     {{ 'general.inactive' | translate }}
                  </ng-container>
               </td>
               <td>
                  <i (click)="this.openModal(student.id)" class="far fa-edit editButton"></i>
               </td>
            </tr>
         </ng-container>
      </table>
   </div>
   <div class="zero-state" *ngIf="
         this.studentsNumber == 0 &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         name == undefined
      ">
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold">{{ 'app.there_are_no_students_registered_in_your_instutition' | translate }}.<br /><span
            class="clickable" routerLink="/administrator/register-module/student-register">{{ 'general.click_here' |
            translate }}</span> {{ 'app.to_begin_registering_students' | translate }}.</h5>
   </div>
   <div class="zeroState" *ngIf="
         this.studentsNumber == 0 &&
         (name != undefined || facultyId != '-1' || departmentId != '-1')
      ">
      {{ 'new_entries.no_student_found' | translate }}
   </div>
   <div class="paginationContainer">
      <div>

      </div>
      <pagination-controls *ngIf="this.studentsNumber != 0" class="num" (pageChange)="changePage($event)"
         autoHide="false" responsive="true" previousLabel="" nextLabel=""></pagination-controls>
      <div>
         <button class="reportBtn" (click)="registerStudentModal()">
            <i class="fas fa-plus"></i> {{ 'app.register_students' | translate }}
         </button>
      </div>
   </div>
</main>
