import { SubmissionsService } from './services/submissions.service';
import { RouterModule } from '@angular/router';
import {
   HttpClientModule,
   HttpClient,
   HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AuthService } from './services/auth.service';

import { UserService } from './services/user.service';
import { AuthenticationEffects } from './authModule/state/authentication.effects';
import { IndividualComponentsModule } from './individual-components/individual-components.module';
import { AdministratorComponentsModule } from './administrator-components/administrator-components.module';
import { IndividualAdministratorComponentsModule } from './individual-administrator-components/individual-administrator-components.module';
import { AuthmoduleModule } from './authModule/authmodule.module';
import { SharedModule } from './sharedModule/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileComponent } from './sharedModule/profile/profile.component';
import { PersonalInformationComponent } from './sharedModule/profile/personal-information/personal-information.component';
import { SettingsComponent } from './sharedModule/profile/settings/settings.component';
import { BillingInformationComponent } from './sharedModule/profile/billing-information/billing-information.component';
import { TransactionComponent } from './sharedModule/profile/transaction/transaction.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule, DatePipe } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { SpinnerEffects } from './store/effects/spinner.effects';
import { RouteEffects } from './store/effects/route.effects';
import { AlertEffects } from './store/effects/alert.effects';
import { NgxSpinnerModule } from 'ngx-spinner';
import { metaReducer, storageMetaReducer } from './store/storage.metareducer';
import { NgxPaginationModule } from 'ngx-pagination';
// import {
//    SocialLoginModule,
//    SocialAuthServiceConfig,
// } from 'angularx-social-login';
// import { GoogleLoginProvider } from 'angularx-social-login';
import { ErrorInterceptorService } from './error-interceptor.service';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { MatDialogModule } from '@angular/material/dialog';
// import { TawkService } from './services/tawk.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LanguageService } from './services/language.service';

import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";

import { BrowserModule } from "@angular/platform-browser";
import { EmailConfirmationSendComponent } from './authModule/signup/email-confirmation-send/email-confirmation-send.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FormBuilder, FormControl } from '@angular/forms';
import { FloatLabelType, MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { SubjectIdComponent } from './professor-components/professor-administration/subject-id/subject-id.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './sharedModule/report-v2/components/shared/progress-bar/progress-bar.component';
import { MatChipsModule } from '@angular/material/chips';
import { AssignmentConfigurationComponent } from './assignment-configuration/assignment-configuration.component';
import { KeycloakAuthService } from './services/keycloak-auth.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { ApiLoggingInterceptor } from './services/api-logging.interceptor';
import { ApiDebugComponent } from './api-debug/api-debug.component';


export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http);
}
 
@NgModule({
   declarations: [
      AppComponent,
      ProfileComponent,
      PersonalInformationComponent,
      SettingsComponent,
      BillingInformationComponent,
      TransactionComponent,
      EmailConfirmationSendComponent,
      SubjectIdComponent,
      ProgressBarComponent,
      AssignmentConfigurationComponent,
      AuthCallbackComponent,
      ApiDebugComponent
   ],
   imports: [
      BrowserModule,
      RecaptchaModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      AppRoutingModule,
      FormsModule,
      MatFormFieldModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      MatCheckboxModule,
      RecaptchaFormsModule,
      MatInputModule,
      MatSelectModule,
      SharedModule,
      AuthmoduleModule,
      IndividualComponentsModule,
      AdministratorComponentsModule,
      IndividualAdministratorComponentsModule,
      HttpClientModule,
      CommonModule,
      RouterModule,
      MatDialogModule,
      NgxSpinnerModule,
      NgxPaginationModule,
      NgxFileDropModule,
      MatProgressSpinnerModule,
      MatChipsModule,
      MatProgressBarModule,
      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
         },
      }),
      ToastrModule.forRoot({
         timeOut: 10000,
         positionClass: 'toast-top-right',
         preventDuplicates: true,
      }),
      EffectsModule.forRoot([
         AuthenticationEffects,
         SpinnerEffects,
         RouteEffects,
         AlertEffects,
      ]),
      /**
      You can think of meta-reducers as hooks into the action->reducer pipeline. Meta-reducers allow developers to pre-process actions before normal reducers are invoked.
       */
      StoreModule.forRoot(metaReducer, {
         metaReducers: [storageMetaReducer],
         runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false,
         },
      }),
      StoreDevtoolsModule.instrument({
         name: 'Crossplag',
         maxAge: 25,
         logOnly: environment.production,
      }),
      // SocialLoginModule,

   ],
   providers: [
      LanguageService,
      // TawkService,
      AuthService,
      UserService,
      SubmissionsService,
      DatePipe,
      { provide: HTTP_INTERCEPTORS, useClass: ApiLoggingInterceptor, multi: true },
      // {
      //    provide: 'SocialAuthServiceConfig',
      //    useValue: {
      //       autoLogin: false,
      //       providers: [
      //          {
      //             id: GoogleLoginProvider.PROVIDER_ID,
      //             provider: new GoogleLoginProvider(
      //                '941887313100-70s9pk0dd5ksoi2780jebbjtsovoh3be.apps.googleusercontent.com'
      //             ),
      //          },
      //       ],
      //    } as SocialAuthServiceConfig,
      // },
      {
         // Http error intereptor, to be able to intercept erros we need to add it to the providers in angular module.
         provide: HTTP_INTERCEPTORS,
         useClass: ErrorInterceptorService,
         multi: true,
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: JwtInterceptorService,
         multi: true,
      },
      KeycloakAuthService
   ],
   bootstrap: [AppComponent],
   schemas: [
      CUSTOM_ELEMENTS_SCHEMA
   ],
})
export class AppModule { }
