<section class="upload">
    <main>
        <form [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
            <div>
                <!-- Enable Translations -->
                <h4>Enable Translations</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="enableTranslations" />
                    <span class="slider round"></span>
                </label>

                <div *ngIf="settingsForm.get('enableTranslations').value" class="language-selection">
                    <mat-form-field>
                        <mat-select placeholder="Select Languages" formControlName="selectedTranslatedLanguages"
                            multiple>
                            <mat-option *ngFor="let language of languages" [value]="language.value"
                                [disabled]="settingsForm.get('selectedTranslatedLanguages').value.length >= 3 && !isSelected(language.value)">
                                {{ language.text }}
                            </mat-option>
                        </mat-select>
                        <mat-hint *ngIf="settingsForm.get('selectedTranslatedLanguages').value.length > 0">
                            {{ settingsForm.get('selectedTranslatedLanguages').value.length }}/3 {{ 'new_entries.selected' | translate }}
                        </mat-hint>
                    </mat-form-field>
                </div>

                <!-- AI Text Prediction -->
                <h4>AI Text Prediction</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="aiTextPrediction" />
                    <span class="slider round"></span>
                </label>

                <!-- Archive -->
                <h4>Archive</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="archive" />
                    <span class="slider round"></span>
                </label>

                <!-- Exclude Sources Below X Percentage -->
                <h4>Exclude Sources Below X Percentage</h4>
                <input type="number" formControlName="excludeSourcesPercentage" min="0" max="100"
                    class="form-control" />

                <!-- Anonymous Submission -->
                <h4>Anonymous Submission</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="anonymousSubmission" />
                    <span class="slider round"></span>
                </label>

                <!-- Exclude Citations -->
                <h4>Exclude Citations</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="excludeCitations" />
                    <span class="slider round"></span>
                </label>

                <!-- Submission Pre-check -->
                <h4>Submission Pre-check</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="submissionPreCheck" />
                    <span class="slider round"></span>
                </label>

                <!-- Source Thresholds -->
                <h4>Source Thresholds</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="enableThresholds" />
                    <span class="slider round"></span>
                </label>

                <div *ngIf="settingsForm.get('enableThresholds').value">
                    <div style="display: flex; gap: 5px">
                        <div class="threshold-input">
                            <label>{{ 'new_entries.exact_match' | translate }}</label>
                            <input formControlName="exactMatchThreshold" type="number" min="0" max="100"
                                class="form-control" />
                        </div>
                        <div class="threshold-input">
                            <label>{{ 'new_entries.altered_text' | translate }}</label>
                            <input formControlName="alteredTextThreshold" type="number" min="0" max="100"
                                class="form-control" />
                        </div>
                        <div class="threshold-input" *ngIf="settingsForm.get('contextualSimilarity').value">
                            <label>{{ 'new_entries.contextual_similarity' | translate }}</label>
                            <input formControlName="contextualSimilaritiesThreshold" type="number" min="0" max="100"
                                class="form-control" />
                        </div>
                    </div>
                </div>

                <!-- Context Similarity -->
                <h4>Context Similarity</h4>
                <label class="switch">
                    <input type="checkbox" formControlName="contextualSimilarity" />
                    <span class="slider round"></span>
                </label>
            </div>

            <!-- Submit Button -->
            <div class="center">
                <button type="submit" class="submit" [disabled]="settingsForm.invalid">
                    Save Settings
                </button>
            </div>
        </form>
    </main>
</section>
