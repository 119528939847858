import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { from, Observable } from 'rxjs';

@Injectable({
   providedIn: 'root',
})
export class UploadfileService {
   constructor(protected http: HttpClient) {}
   /**
    * Method used to upload file
    * @param base64
    * @param type
    * @param fileName
    * @param size
    */
   uploadFile(base64: any, type: string, fileName: string, size: number) {
      return this.http
         .post<any>(`${environment.apiUrl}/fileUpload`, {
            base64,
            type,
            fileName,
            size,
         })
         .pipe();
   }

   uploadCodeFile(file: File, key: string): Observable<any> {
      return from(new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => {
            const base64 = reader.result as string;
            this.http.post<any>(`${environment.apiUrl}/uploadCodeFile`, {
               file: base64,
               key: key,
               contentType: file.type || 'text/plain'
            }).subscribe(resolve, reject);
         };
         reader.onerror = error => reject(error);
      }));
   }

   generatePresignedURL(type: string, firstName: string, lastName: string, assignmentId?: string, documentTitle?: string) {
      return this.http
         .post<any>(`${environment.apiUrl}/getPutPresignedURL`, {
            type,
            firstName,
            lastName,
            assignmentId,
            documentTitle
         })
         .pipe();
   }

   uploadfileAWSS3(fileUploadUrl, file) {
      return this.http.put<any>(fileUploadUrl, file).pipe();
   }

   generatePresignedURLPreview(
      key: string,
      type: string,
      firstName: string,
      lastName: string,
      assignmentId?
   ) {
      return this.http
         .post<any>(`${environment.apiUrl}/getDisplayPresignedURLPreview`, {
            key,
            type,
            firstName,
            lastName,
            assignmentId
         })
         .pipe();
   }

   getThesisAssignmentLanguages(id, type) {
      return this.http
         .get<any>(
            `${environment.apiUrl}/thesis/assignment/languages/?id=${id}` +
               (type ? `&type=${type}` : '')
         )
         .pipe();
   }

   readFileFromPresignedURL(presignedURL: string) {
      return this.http.get<any>(presignedURL).pipe();
   }
}
