import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Observable, of } from 'rxjs';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakAuthService } from './keycloak-auth.service';

/**
 * This service is all about user authentication and to get logged in user information
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
   /**
    * User model
    */
   user;

   /**
    * Auth-service constructor
    * @param http
    * @param router
    * @param store
    */
   constructor(
      private http: HttpClient,
      private router: Router,
      private store: Store,
      private languageService: LanguageService,
      public translate: TranslateService,
      private keycloakAuthService: KeycloakAuthService
   ) {}

   /**
    * Method is used to set user token on local storage
    * @param userData
    */
   public signIn(userData: User): void {
      localStorage.setItem('ACCESS_TOKEN', JSON.parse(userData.token));
   }

   /**
    * Method is used to check if user is logged in or not
    */
   public isLoggedIn(): boolean {
      if (localStorage.getItem('ACCESS_TOKEN') !== null) {
         return true;
      }
      return false;
   }

   /**
    * Method is used to get current user token
    */
   public getUserToken() {
      return <any>JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
   }

   /**
    * Method is used to get current user role
    */
   public isLoggedInRole() {
      return localStorage.getItem('role');
   }

   /**
    * Method is used to logOut the user and delete items from local storage
    */
   public logout(): void {
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('role');
      localStorage.removeItem('user');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('__app_storage__');
      localStorage.removeItem('modal');
      localStorage.removeItem('websiteLanguage');
      localStorage.removeItem('aId');

      this.translate.use('en');

      const keycloak = this.keycloakAuthService.getKeycloakInstance();
      const idToken = keycloak.idToken;
      if (idToken) {
         const postLogoutRedirectUri = `${environment.currentUrl}login`
         const encodedRedirectUri = postLogoutRedirectUri
         const logoutUrl = `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodedRedirectUri}`;
         console.log('logoutUrl',logoutUrl);
         window.location.href = logoutUrl;
      } else {
         console.error('Keycloak instance not available');
         this.router.navigate(['/login']);
      }
   }

   /**
    * Method is used to get current user information
    */
   public getCurrentUserObservable() {
      return this.http.get<any>(`${environment.apiUrl}/user`).pipe();
   }
   /**
    * Method used to get current user from local storage
    */
   public getCurrentUser() {
      return <any>JSON.parse(localStorage.getItem('user'));
   }

   /**
    * Method is used to logIn the user, calls the login api and handles user login.
    * @param email
    * @param password
    */
   login(email: string, password: string, isIdpLogin: boolean = false, institutionName?: string, role?: string, fullName?: string) {
      return this.http
         .post<any>(`${environment.apiUrl}/login`, { email, password, isIdpLogin, institutionName, role, fullName })
         .pipe(
            map((data) => {
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem('ACCESS_TOKEN', JSON.stringify(data.token));
               localStorage.setItem('role', JSON.stringify(data.roleId));
               localStorage.setItem('websiteLanguage', data.language);
               localStorage.setItem('user', JSON.stringify(data));
               localStorage.setItem('modal', 'false');
               this.languageService.setSelectedLanguage(data.language);
               return data;
            })
         );
   }
   /**
    * Method used to call googleAuth api to login or sign up (if the user isn't registered) with google
    * @param name
    * @param email
    */
   googleAuth(name: string, email: string) {
      return this.http
         .post<any>(`${environment.apiUrl}/googleAuth`, {
            name,
            email,
         })
         .pipe(
            map((data) => {
               // store user details and jwt token in local storage to keep user logged in between page refreshes
               localStorage.setItem(
                  'ACCESS_TOKEN',
                  JSON.stringify(data.jwtToken)
               );
               localStorage.setItem('role', JSON.stringify(data.roleId));
               localStorage.setItem('user', JSON.stringify(data));
               localStorage.setItem('modal', 'false');
               return data;
            })
         );
   }
   /**
    *  Method used to call linkedInAuth api to login or sign up (if the user isn't registered) with linkedIn
    * @param authCode
    */
   linkedInLogin(authCode: string): any {
      return this.http
         .post<any>(`${environment.apiUrl}/linkedInAuth`, {
            authCode,
         })
         .pipe(
            map((data) => {
               localStorage.setItem(
                  'ACCESS_TOKEN',
                  JSON.stringify(data.jwtToken)
               );
               localStorage.setItem('role', JSON.stringify(data.roleId));
               localStorage.setItem('user', JSON.stringify(data));
               localStorage.setItem('modal', 'false');
               return data;
            })
         );
   }

   LTIlogin({
      token,
      user,
      assignment,
      assignmentId,
      courseId,
   }: {
      token: string;
      user: User;
      assignment?: any;
      assignmentId?: string;
      courseId?: string;
   }): any {
      localStorage.setItem('ACCESS_TOKEN', JSON.stringify(token));
      localStorage.setItem('role', user.roleId.toString());
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('websiteLanguage', user.language);
      localStorage.setItem('LTIAssignment', JSON.stringify(assignment));
      localStorage.setItem('LTICourseId', courseId);
      localStorage.setItem('LTIAssignmentId', assignmentId);
      this.languageService.setSelectedLanguage(user.language);
      return of(user);
   }
   /**
    * Method used to call forgot password to api
    * @param email
    */
   forgotPassword(email: string) {
      return this.http
         .post<any>(`${environment.apiUrl}/forget-password`, {
            email,
         })
         .pipe();
   }

   activateAccount(email: string) {
      return this.http
         .post<any>(`${environment.apiUrl}/lti/activate`, {
            email,
         })
         .pipe();
   }

   getInstitutionDetails(institutionName: string): Observable<any> {
      return this.http.get(`${environment.apiUrl}/institutionIdP/${institutionName}`);
   }
}
