import { ReportEffects } from './state/effects/report.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportComponent } from './report/report.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AddCreditsComponent } from './add-credits/add-credits.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { HowToUseComponent } from './help-center/how-to-use/how-to-use.component';
import { FaqsComponent } from './help-center/faqs/faqs.component';
import { ContactUsComponent } from './help-center/contact-us/contact-us.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotAuthorizedComponent } from './notauthorized/not-authorized.component';
import { Routes, RouterModule } from '@angular/router';
import { SettingsComponent } from './profile/settings/settings.component';
import { BillingInformationComponent } from './profile/billing-information/billing-information.component';
import { PersonalInformationComponent } from './profile/personal-information/personal-information.component';
import { ProfileComponent } from './profile/profile.component';
import { HttpClient } from '@angular/common/http';
import { TransactionComponent } from './profile/transaction/transaction.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SentenceInformationModalComponent } from './report/sentence-information-modal/sentence-information-modal.component';
import { reportReducer } from './state/reducer/report.reducer';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMarkjsModule } from 'ngx-markjs';
import { NgxPaginationModule } from 'ngx-pagination';
import { ContactUsBankComponent } from './add-credits/contact-us-bank/contact-us-bank.component';
import { NavbarAdministratorComponent } from './navbar-administrator/navbar-administrator.component';
import { UploadComponent } from './upload/upload.component';
import { InputTextComponent } from './upload/input-text/input-text.component';
import { UploadFileComponent } from './upload/upload-file/upload-file.component';
import { WebImportComponent } from './upload/web-import/web-import.component';
import { NavbarIndividualAdministratorComponent } from './navbar-individual-administrator/navbar-individual-administrator.component';
import { SourcePreviewComponent } from './report/sourcePreview/source-preview/source-preview.component';
import { NavbarStudentComponent } from './navbar-student/navbar-student.component';
import { DeleteModalComponent } from './report/delete-modal/delete-modal.component';
import { ProfessorDeleteModalComponent } from './report/professor-delete-modal/professor-delete-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FilePreviewComponent } from './upload/file-preview/file-preview.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ExpandViewComponent } from './expand-view/expand-view.component';
import { TranslateComponent } from './translate/translate.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
// import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CharReplacementModal } from './report/char-replacement-modal/char-replacement.component';

// The new Report Components
import { ReportComponent as ReportComponentv2 } from './report-v2/report.component'; // The  new ReportComponent
import { BannerComponent } from './report-v2/components/panel-components/banner/banner.component';
import { ScoreComponent } from './report-v2/components/sidebar-components/score/score.component';
import { MatchingSources } from './report-v2/components/sidebar-components/matching-sources/matching-sources.component';
import { AiBar } from './report-v2/components/sidebar-components/ai-bar/ai-bar.component';
import { LanguageBar } from './report-v2/components/sidebar-components/lang-bar/language-bar.component';
import { Manipulation } from './report-v2/components/sidebar-components/manipulation/manipulation.component';
import { DocumentNavigation } from './report-v2/components/panel-components/navigation/navigation.component';
import { DocumentComponent } from './report-v2/components/panel-components/document/document.component';
import { LegendComponent } from './report-v2/components/panel-components/legend/legend.component';
import { OverviewDisclaimerComponent } from './report-v2/components/shared/overview-disclaimer/overview-disclaimer.component';

import { PrivacySettingsModalComponent } from './report-v2/components/shared/privacy-settings-modal/privacy-settings-modal.component';
import { SentenceInformationComponent } from './report-v2/components/sentence-information/sentence-information.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CohortComponent } from './report-v2/components/cohort/cohort.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';

import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AccessContentAdmin } from '../administrator-components/access-content/access-content.component';
import { ViewLogs } from './view_logs/view_logs.component';
import { AdminSettingsGuard } from '../guards/admin-settings.guard';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';
import { CodePreviewComponent } from './upload/code-preview/code-preview.component';
import { CodeSimilarityCheckComponent } from '../professor-components/professor-administration/code-similarity-check/code-similarity-check.component';
import { ConfirmationDialogComponent } from '../shared/components/confirmation-dialog/confirmation-dialog.component';
import { CustomSliderComponent } from '../shared/components/custom-slider/custom-slider.component';
import { SmallInfoComponent } from '../shared/components/small-info/small-info.component';
import { RegistrationProgressComponent } from '../shared/components/registration-progress/registration-progress.component';

const sharedRoutes: Routes = [
   {
      path: 'add-credits',
      component: AddCreditsComponent,
      children: [{ path: 'contact-us', component: ContactUsBankComponent }],
   },
   {
      path: 'submission/:id/report',
      component: ReportComponent,
   },
   {
      path: 'submission/:id/report/v2',
      component: ReportComponentv2,
   },
   {
      path: 'submission/:id/report/v2/:userId/:token',
      component: ExpandViewComponent,
   },
  //  {
  //     path: 'profile',
  //     component: ProfileComponent,
  //     children: [
  //        { path: '', pathMatch: 'full', redirectTo: 'personal-information' },
  //        {
  //           path: 'personal-information',
  //           component: PersonalInformationComponent,
  //        },
  //        {
  //           path: 'settings',
  //           component: SettingsComponent,
  //        },
  //        {
  //           path: 'billing-information',
  //           component: BillingInformationComponent,
  //        },
  //        {
  //           path: 'transaction',
  //           component: TransactionComponent,
  //        },
  //     ],
  //  },
   {
      path: 'help-center',
      component: HelpCenterComponent,
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'how-to-use' },
         {
            path: 'how-to-use',
            component: HowToUseComponent,
         },
         {
            path: 'settings',
            component: SettingsComponent,
         },
         { path: 'faqs', component: FaqsComponent, data: { roles: '2' } },
         {
            path: 'contact-us',
            component: ContactUsComponent,
         },
      ],
   },

   {
      path: 'upload',
      component: UploadComponent,
      children: [
         { path: '', pathMatch: 'full', redirectTo: 'upload-file' },
         {
            path: 'upload-file',
            component: UploadFileComponent,
         },
         {
            path: 'web-import',
            component: WebImportComponent,
         },
         {
            path: 'file',
            component: InputTextComponent,
         },
      ],
   },
   {
    path: 'admin-settings',
    component: AdminSettingsComponent,
    canDeactivate: [AdminSettingsGuard]
   },
   {
    path: 'access-content',
    component: AccessContentAdmin
   }
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
   declarations: [
      HeaderComponent,
      NavbarComponent,
      NavbarAdministratorComponent,
      AddCreditsComponent,
      HelpCenterComponent,
      HowToUseComponent,
      FaqsComponent,
      ContactUsComponent,
      NotAuthorizedComponent,
      NotFoundComponent,
      ReportComponent,
      SentenceInformationModalComponent,
      ContactUsBankComponent,
      UploadComponent,
      InputTextComponent,
      UploadFileComponent,
      WebImportComponent,
      NavbarIndividualAdministratorComponent,
      SourcePreviewComponent,
      NavbarStudentComponent,
      DeleteModalComponent,
      ProfessorDeleteModalComponent,
      FilePreviewComponent,
      ExpandViewComponent,
      TranslateComponent,
      CharReplacementModal,
      ReportComponentv2,
      BannerComponent,
      ScoreComponent,
      MatchingSources,
      AiBar,
      LanguageBar,
      Manipulation,
      DocumentNavigation,
      DocumentComponent,
      LegendComponent,
      PrivacySettingsModalComponent,
      SentenceInformationComponent,
      CohortComponent,
      AdminSettingsComponent,
      OverviewDisclaimerComponent,
      AccessContentAdmin,
      ViewLogs,
      CodePreviewComponent,
      CodeSimilarityCheckComponent,
      ConfirmationDialogComponent,
      RegistrationProgressComponent,
      CustomSliderComponent,
      SmallInfoComponent,

   ],
   imports: [
      CommonModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        }
      }),
      MatDialogModule,
      NgxPaginationModule,
      RouterModule.forChild(sharedRoutes),
      FormsModule,
      ReactiveFormsModule,
      PdfViewerModule,
      NgxMarkjsModule,
      MatTooltipModule,
      NgxFileDropModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatFormFieldModule,
      MatSelectModule,
      MatInputModule,
      MatCheckboxModule,
      MatSlideToggleModule,
      NgApexchartsModule,
      MatPaginatorModule,
      MatSortModule,
      MatTableModule,
      MatAutocompleteModule,
      MatChipsModule,
      MatMenuModule,
      StoreModule.forFeature('report', reportReducer),
      EffectsModule.forFeature([ReportEffects]),
   ],
   exports: [
      CommonModule,
      TranslateModule,
      TranslateComponent,
      CodePreviewComponent,
      ConfirmationDialogComponent,
      RegistrationProgressComponent,
      CustomSliderComponent,
      SmallInfoComponent,
   ]
})
export class SharedModule {}
