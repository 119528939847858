<div class="d-flex justify-flex-end">
   <div class="dropdown">
      <button class="dropbtn">
         <i class="fa fa-download"></i> {{ 'general.export_data' | translate }}
      </button>
      <div class="dropdown-content">
         <a (click)="exportToExcel()">{{ 'general.export_as_excel' | translate }} (.xlsx)</a>
         <a (click)="exportToPDF()">{{ 'general.export_as_pdf' | translate }}</a>
         <a (click)="exportToCSV()">{{ 'general.export_as_csv' | translate }}</a>
      </div>
   </div>
</div>
<br>
<div class="documents-2 shadow white-bg">
   <div *ngIf="
         !(
            this.totalSubjects == 0 &&
            subjectName == undefined &&
            facultyId == '-1' &&
            departmentId == '-1' &&
            professor == '-1'
         )
      " class="filtersContainer">
      <h4>{{ 'general.overview' | translate }}</h4>
      <div class="selectors">
         <!-- <div>
            <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_institution' | translate }}
               </option>
               <option
                  *ngFor="let faculty of this.faculties"
                  value="{{ faculty.id }}"
               >
                  {{ faculty.name }}
               </option>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div> -->
         <!-- <div>
            <select
               disabled
               *ngIf="facultyId == '-1' || facultyId == ''"
               name=""
               id=""
            >
               <option value="">{{ 'general.select_institution_first' | translate }}</option>
            </select>
            <select
               *ngIf="facultyId !== '-1' && facultyId !== ''"
               [(ngModel)]="departmentId"
               (change)="selectedDepartment()"
            >
               <option value="-1" selected disabled>
                  {{ 'app.filter_by_department' | translate }}
               </option>
               <ng-container *ngFor="let department of this.departments">
                  <option value="{{ department.id }}">
                     {{ department.name }}
                  </option>
               </ng-container>
               <option value="">{{ 'general.all' | translate }}</option>
            </select>
         </div> -->

         <div [ngClass]="{
            'selectedOptionBorder': professor != '-1'
         }">
            <select [(ngModel)]="professor" (change)="filterByProfessor()">
               <option value="-1" disabled>{{ 'app.filter_by_educator' | translate }}</option>
               <!-- <ng-container *ngFor="let professor of this.professors"> -->
               <option *ngFor="let professor of this.professors" value="{{ professor.id }}">
                  {{ professor.name }}
               </option>
               <!-- </ng-container> -->

               <option [disabled]="professor == '-1'">{{ 'general.all' | translate }}</option>
            </select>
         </div>

         <div [ngClass]="{
            'inputBorder': subjectName?.length != 0
         }">
            <input [placeholder]="'placeholders.search_by_name' | translate" [(ngModel)]="subjectName"
               (ngModelChange)="titleFilter()" type="text" name="searchByName" />
         </div>
         <!-- <div [ngClass]="{
            'inputBorder': studentName?.length != 0
         }">
            <input [placeholder]="'placeholders.search_by_student' | translate" [(ngModel)]="studentName"
               (ngModelChange)="titleFilter()" type="text" name="searchByStudentName" />
         </div> -->
         <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
      </div>
   </div>

   <div *ngIf="this.totalSubjects != 0" class="tableWithExpandedInfoDepartments tablewithRatios" #contentToCapture>
      <ng-container *ngFor="
            let subject of this.subjects
               | paginate
                  : {
                       itemsPerPage: 5,
                       currentPage: this.page,
                       totalItems: this.totalSubjects
                    };
            let i = index
         ">
         <div>
            <div>
               <h4 class="blueText bold">{{ subject.name }}</h4>
               <div>
                  <div>
                     <span>{{ 'general.educators' | translate }}</span>
                     <span>{{ 'general.students' | translate }}</span>
                     <span>{{ 'general.assignments' | translate }}</span>
                     <span>{{ 'general.submissions' | translate }}</span>
                     <span>{{ 'general.avg_of_document_language' | translate }}</span>
                     <span>{{ 'general.avg_of_translated_language' | translate }}</span>
                  </div>
                  <div>
                     <span class="bold">{{ subject.professors
                        }}</span>
                     <span class="bold">{{ subject.students }}</span>
                     <span (click)="filterAssignment(subject.name)" class="bold clickable">{{ subject.assignments
                        }}</span>
                     <span (click)="filterSubmissions(subject.id, subject)" class="bold clickable">{{
                        subject.submissions._count }}</span>
                     <span class="bold" [ngClass]="{
                      'red-border': subject.submissions._avg.originalPercentage > 20,
                      'green-border':
                      subject.submissions._avg.originalPercentage > 0 &&
                      subject.submissions._avg.originalPercentage < 5,
                      'orange-border':
                      subject.submissions._avg.originalPercentage >= 5 &&
                      subject.submissions._avg.originalPercentage <= 20,
                      'gray-border': subject.submissions._avg.originalPercentage === null || subject.submissions._avg.originalPercentage === 0
                    }">
                        {{
                        subject.submissions._avg.originalPercentage === null
                        ? '-'
                        : subject.submissions._avg.originalPercentage === 0
                        ? 0
                        : subject.submissions._avg.originalPercentage?.toFixed(2) + '%'
                        }}
                     </span>
                     <span class="bold" [ngClass]="{
                      'red-border': subject.submissions._avg.translatedPercentage > 20,
                      'green-border':
                      subject.submissions._avg.translatedPercentage > 0 &&
                      subject.submissions._avg.translatedPercentage < 5,
                      'orange-border':
                      subject.submissions._avg.translatedPercentage >= 5 &&
                      subject.submissions._avg.translatedPercentage <= 20,
                      'gray-border': subject.submissions._avg.translatedPercentage === null || subject.submissions._avg.translatedPercentage === 0
                    }">
                        {{
                        subject.submissions._avg.translatedPercentage === null
                        ? '-'
                        : subject.submissions._avg.translatedPercentage === 0
                        ? 0
                        : subject.submissions._avg.translatedPercentage?.toFixed(2) + '%'
                        }}
                     </span>
                  </div>
               </div>
               <a *ngIf="expandInfo !== i" class="blueText underline vold" (click)="expand(i)">{{ 'general.expand' |
                  translate }}</a>
               <a *ngIf="expandInfo === i" class="blueText underline vold" (click)="expand(i)">{{ 'general.collapse' |
                  translate }}</a>
            </div>
            <div [ngClass]="{ expandedInfo: expandInfo == i }" class="moreInfo" style="grid-template: none !important">
               <div class="details">
                  <span>
                     {{ 'app.most_used_language' | translate }}:
                     <b>{{ setLanguageName(subject.language?subject.language[0]:'-') }}</b>
                     <!-- <b>{{ subject.language[0] }}</b> -->
                  </span>
                  <span>
                     {{ 'report.high_similarity_submissions' | translate }}:
                     <b>{{ subject.highSimilaritySubmissions }}</b></span>
                  <span>
                     {{ 'report.medium_similarity_submissions' | translate }}:
                     <b>{{ subject.mediumSimilaritySubmissions }}</b></span>
                  <span>
                     {{ 'report.low_similarity_submissions' | translate }}:
                     <b>{{ subject.lowSimilaritySubmissions }}</b></span>
               </div>
               <div class="ratios">
                  {{ 'app.ratios' | translate }}
               </div>
               <div class="details">
                  <span>
                     {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.student' | translate
                     }}:
                     <b>{{ subject.professors }} : {{
                        subject.students
                        }}</b>
                  </span>
                  <span>
                     {{ 'general.educator' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' |
                     translate }}:<b> {{ subject.professors }} : {{
                        subject.submissions._count
                        }}</b>
                  </span>
                  <span>
                     {{ 'general.student' | translate }}-{{ 'other.to' | translate }}-{{ 'general.submissions' |
                     translate }}:
                     <b>{{ subject.students }} : {{
                        subject.submissions._count }}</b></span>
               </div>
            </div>
         </div>
      </ng-container>
   </div>
   <div class="zeroState" *ngIf="
         this.totalSubjects == 0 &&
         (subjectName != undefined ||
            facultyId != '-1' ||
            departmentId != '-1' ||
            professor != '-1')
      ">
      {{ 'new_entries.no_subjects_found' | translate }}
   </div>
   <pagination-controls *ngIf="this.totalSubjects != 0" class="num" (pageChange)="changePage($event)" responsive="true"
      previousLabel="" nextLabel=""></pagination-controls>
   <div class="zero-state" *ngIf="
         this.totalSubjects == 0 &&
         subjectName == undefined &&
         facultyId == '-1' &&
         departmentId == '-1' &&
         professor == '-1'
      ">
      <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
      <h5 class="bold" style="z-index: 1;">{{ 'app.there_are_no_subjects_registered_in_your_instutition' | translate }}.
         <a routerLink="/administrator/register-module/course-register" routerLinkAction="active">{{
            'general.click_here' | translate }}</a>
         to begin registering your first subject.
      </h5>
   </div>
</div>
