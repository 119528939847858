import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUserSubmissionsDetails } from '../state/selectors/submissions.selectors';
import * as submissionsActions from '../state/actions/submissions.actions';
import * as userActions from '../../authModule/state/actions/authentication.actions';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
// import { TawkService } from '../../services/tawk.service';
import { MatDialog } from '@angular/material/dialog';

import { TextDetectorModalComponent } from './../text-detector-modal/text-detector-modal.component';
import { TranslateService } from '@ngx-translate/core';

/**
 * Individual Dashboard Component
 */
@Component({
   selector: 'app-individual-dashboard',
   templateUrl: './individual-dashboard.component.html',
   styleUrls: ['./individual-dashboard.component.scss'],
})
export class IndividualDashboardComponent
   implements OnInit, AfterViewInit, OnDestroy {
   /**
    * Variable used to store state store
    */
   submissionsDetails;

   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Symbolizing the size of the page
    */
   pageSize: number = 5;

   /**
    * Representing tile model
    */
   title: string;
   /**
    * Timer used for search delay
    */
   timer: any;
   /**
    * Observable used to store state slice from state store.
    */
   submissionsDetails$;
   /**
    * Used to store user data from the store
    */
   user;
   /**
    * Subscriber to subscribe to user state in the store.
    */
   user$;
   once = false;
   dialogIsOpen = false;
   /**
    * Component constructor.
    * @param store
    * @param router
    * @param toastr
    */

   constructor(
      private router: Router,
      private store: Store,
      private toastr: ToastrService,
      // private tawkService: TawkService,
      private dialog: MatDialog,
      public translate: TranslateService
   ) { }

   /**
    * A lifecycle hook that is called after Angular has fully initialized a component's view.
    */
   ngAfterViewInit(): void {
      let modalShown = localStorage.getItem('modal');

      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null && data != undefined) {
            this.user = data;
            // this.tawkService.UpdateTawkUser(this.user);
            if (!this.dialogIsOpen && (data?.lastLogin < "2022-12-19") && modalShown == 'false' && data.roleId == 2) {
               this.dialog.open(TextDetectorModalComponent, {
                  width: '40%',
                  height: 'unset',
               });
               this.dialogIsOpen = true;
               localStorage.setItem('modal', "true");
            }
         }
      });
   }
   /**
    * Method used to be called whenever the component is loaded and all the methods in it, will be triggered.
    */
   ngOnInit(): void {

      // this.tawkService.SetChatVisibility(false);

      this.store.dispatch(userActions.currentUser());

      this.user$ = this.store.select(getCurrentUser).subscribe((data) => {
         if (data !== null) {
            this.user = data;
         }
      });

      this.store.dispatch(submissionsActions.loadSubmissionsDetails());
      if (!this.once) {
         this.store.dispatch(
            submissionsActions.loadSubmissions({
               page: 1,
               pageSize: this.pageSize,
            })
         );
         this.once = true;
      }

      this.submissionsDetails$ = this.store
         .select(getCurrentUserSubmissionsDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.submissionsDetails = data;
               this.store.dispatch(
                  userActions.updateUserState({
                     userTotalCredits: this.submissionsDetails.userTotalCredits,
                  })
               );
            }
         });
   }

   startDraftUpload(draftId) {
      this.router.navigate([`/individual/upload-draft/upload/${draftId}`]);
   }

   /**
    * Method used to change pagination page. It dispatches an action with page and title as params.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         submissionsActions.loadSubmissions({
            page: event,
            pageSize: this.pageSize,
            title: this.title,
         })
      );
   }
   /**
    * Method is called when a directive, pipe, or service is destroyed. Use for any custom cleanup that needs to occur when the instance is destroyed. In our case we will use to unsubscribe.
    */
   ngOnDestroy() {
      this.submissionsDetails$.unsubscribe();
      this.user$.unsubscribe();
   }
   /**
    * Method used to open report in a new tab.
    * @param url
    */
   goToLink(id: string): void {
      const token = JSON.parse(localStorage.getItem('ACCESS_TOKEN'))

    let url = `${environment.externalReport}/submission/${id}/report/v2/${token}`;
    window.open(url, '_blank');
   }
   /**
    * Method used filter submissions by title
    */
   titleFilter(): void {
      let time;
      time = 800;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            submissionsActions.loadSubmissions({
               page: this.page,
               title: this.title,
               pageSize: this.pageSize,
            })
         );
      }, time);
   }
   /**
    * Method used to show warning toaster message
    * @param message
    */
   showWarning(message): void {
      this.toastr.warning(message);
   }
   /**

    * Method used to alert user before deleting a submission.
    * @param id submission Id.
    */
   async deleteSubmissionAlert(id, showSubmission): Promise<void> {
      const result = await swal.fire({
         title: 'Delete this document?',
         text: 'You will not be able to recover it',
         icon: 'warning',
         confirmButtonText: 'Yes, delete it',
         confirmButtonColor: '#f34135',
         cancelButtonText: this.translate.instant('report.cancel'),
         showCancelButton: true,
         focusCancel: true,
      });
      if (result.isConfirmed) {
         this.deleteSubmission(id, showSubmission);
      }
   }
   /**
    * Method used to delete a submission
    * @param id submission id
    */
   deleteSubmission(id, showSubmission) {
      this.store.dispatch(
         submissionsActions.deleteSubmission({
            id: id,
            showSubmission: !showSubmission,
         })
      );

      swal.fire('Deleted!', 'Document deleted successfully', 'success');
   }
   /**
    * Method used to alert user the reason why can't he/she open document report or why his document hasn't been proceeded.
    */
   async showAlertMessage() {
      let title = 'Not enough credits!';
      let message =
         "Your document is suspended because you don't have enough credits to process it!";
      const result = await swal.fire({
         title: title,
         text: message,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Buy more credits',
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.router.navigateByUrl('/individual/add-credits');
      } else if (result.isDismissed) {
         this.router.navigateByUrl('/individual/dashboard');
      }
   }
}
