import { SubmissionsService } from './../../services/submissions.service';
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LanguageService } from 'src/app/services/language.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})

export class TranslateComponent  implements OnInit {

  constructor(private submissionsService: SubmissionsService, private languageService: LanguageService, private store: Store, public translate: TranslateService, private cdr: ChangeDetectorRef) {
    // Always set English as default
    translate.setDefaultLang('en');
    translate.use('en');
   }
  currentUser$;
  ngOnInit(): void {
    let storedUser;
    try {
      storedUser = JSON.parse(localStorage.getItem('user'))?.id;
    } catch {
      storedUser = null;
    }

    const storedLanguage = localStorage.getItem('websiteLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
      this.translate.use(storedLanguage);
    }

    if (storedUser) {
      this.currentUser = storedUser;
    }

    this.languageService.selectedLanguage$.subscribe((language: string) => {
      this.selectedLanguage = language;
      this.translate.use(language);
    });

    // Get languages from API for both logged-in and non-logged-in users
    this.getLanguages('website');
  }

  currentUser: string;
  selectedLanguage: string = '0';
  supportedLanguages: any[] = [];
  languages = [
    {
        "code": "bg",
        "title": "Bulgarian",
    },
    {
        "code": "bs",
        "title": "Bosnian",
    },
    {
        "code": "cs",
        "title": "Czech",
    },
    {
        "code": "de",
        "title": "German",
    },
    {
        "code": "el",
        "title": "Greek",
    },
    {
        "code": "en",
        "title": "English",
    },
    {
        "code": "es",
        "title": "Spanish",
    },
    {
        "code": "fi",
        "title": "Finnish",
    },
    {
        "code": "fr",
        "title": "French",
    },
    {
        "code": "hr",
        "title": "Croatian",
    },
    {
        "code": "hu",
        "title": "Hungarian",
    },
    {
        "code": "it",
        "title": "Italian",
    },
    {
        "code": "lt",
        "title": "Lithuanian",
    },
    {
        "code": "lv",
        "title": "Latvian",
    },
    {
        "code": "mk",
        "title": "Macedonian",
    },
    {
        "code": "nl",
        "title": "Dutch",
    },
    {
        "code": "no",
        "title": "Norwegian",
    },
    {
      "code": "no_bokmal",
      "title": "Norwegian Bokmål",
    },
    {
      "code": "no_nynorsk",
      "title": "Norwegian Nynorsk",
    },
    {
        "code": "pl",
        "title": "Polish",
    },
    {
        "code": "pt",
        "title": "Portuguese",
    },
    {
        "code": "ro",
        "title": "Romanian",
    },
    {
        "code": "ru",
        "title": "Russian",
    },
    {
        "code": "sk",
        "title": "Slovak",
    },
    {
        "code": "sl",
        "title": "Slovenian",
    },
    {
        "code": "sq",
        "title": "Albanian",
    },
    {
        "code": "sr",
        "title": "Serbian",
    },
    {
        "code": "sv",
        "title": "Swedish",
    },
    {
        "code": "tr",
        "title": "Turkish",
    }
]

  selectLanguage(language: string) {
    this.selectedLanguage = language;
    this.translate.use(language);
    localStorage.setItem('websiteLanguage', language);
    
    // Only update user preference if logged in
    if (this.currentUser) {
      this.submissionsService.setLanguage(language).subscribe((data) => {});
    }
    
    this.languageService.setSelectedLanguage(language);
  }

  getLanguages(type: string) {
    this.submissionsService.getLanguages(type).subscribe((data) => {
      if (data.userId) {
        this.currentUser = data.userId;
        this.languageService.setUserId(data.userId);
      }
      if (type == 'website') {
        this.supportedLanguages = data.webLanguages || this.languages;
        this.cdr.detectChanges();
      }
    },
    (error) => {
      // Fallback to static languages list if API fails
      this.supportedLanguages = this.languages;
      this.cdr.detectChanges();
    });
  }
}
